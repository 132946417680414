$preloader--size-width: 80px;
$preloader--size-height: 80px;
$preloader--bg-color: $color-black;
$preloader--bg-opacity: 0.6;
$preloader--color: $color-gray;

.preloader {
	min-height: $preloader--size-height * 2;
	min-width: $preloader--size-width * 2;
	z-index: 999999999;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		// @include svg($preloader--size-height, $preloader--size-height, '/images/components/preloader/.variant/base/preloader.svg', $preloader--color, 'stroke');
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzNweCIgIGhlaWdodD0iNzNweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLWJhbGwyIj4gICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtMTUpIj4gICAgICA8Y2lyY2xlIGN4PSI1MCIgcj0iMjIuMzM3OSIgY3k9IjMyIiBmaWxsPSIjNTYzYjk3IiB0cmFuc2Zvcm09InJvdGF0ZSgyMzQgNTAgNTApIj4gICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+ICAgICAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJyIiBjYWxjTW9kZT0ic3BsaW5lIiB2YWx1ZXM9IjA7MzA7MCIga2V5VGltZXM9IjA7MC41OzEiIGR1cj0iMSIga2V5U3BsaW5lcz0iMC4yIDAgMC44IDE7MC4yIDAgMC44IDEiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4gICAgICA8L2NpcmNsZT4gICAgICA8Y2lyY2xlIGN4PSI1MCIgcj0iNy42NjIwOSIgY3k9IjMyIiBmaWxsPSIjZWQxODQ3IiB0cmFuc2Zvcm09InJvdGF0ZSg0MTQgNTAgNTApIj4gICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjE4MCA1MCA1MDs1NDAgNTAgNTAiIGtleVRpbWVzPSIwOzEiIGR1cj0iMXMiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT4gICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9InIiIGNhbGNNb2RlPSJzcGxpbmUiIHZhbHVlcz0iMzA7MDszMCIga2V5VGltZXM9IjA7MC41OzEiIGR1cj0iMSIga2V5U3BsaW5lcz0iMC4yIDAgMC44IDE7MC4yIDAgMC44IDEiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4gICAgICA8L2NpcmNsZT4gICAgPC9nPiAgPC9zdmc+);
		background-size: 80px 80px;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: rgba($preloader--bg-color, $preloader--bg-opacity);
		z-index: 1;
	}
}

.preloader--is-button {
	min-height: inherit;
	min-width: inherit;

	&:after {
		// @include svg(auto, 80%, '/images/components/preloader/.variant/base/preloader.svg', $preloader--color, 'stroke');
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzNweCIgIGhlaWdodD0iNzNweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLWJhbGwyIj4gICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtMTUpIj4gICAgICA8Y2lyY2xlIGN4PSI1MCIgcj0iMjIuMzM3OSIgY3k9IjMyIiBmaWxsPSIjNTYzYjk3IiB0cmFuc2Zvcm09InJvdGF0ZSgyMzQgNTAgNTApIj4gICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+ICAgICAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJyIiBjYWxjTW9kZT0ic3BsaW5lIiB2YWx1ZXM9IjA7MzA7MCIga2V5VGltZXM9IjA7MC41OzEiIGR1cj0iMSIga2V5U3BsaW5lcz0iMC4yIDAgMC44IDE7MC4yIDAgMC44IDEiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4gICAgICA8L2NpcmNsZT4gICAgICA8Y2lyY2xlIGN4PSI1MCIgcj0iNy42NjIwOSIgY3k9IjMyIiBmaWxsPSIjZWQxODQ3IiB0cmFuc2Zvcm09InJvdGF0ZSg0MTQgNTAgNTApIj4gICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjE4MCA1MCA1MDs1NDAgNTAgNTAiIGtleVRpbWVzPSIwOzEiIGR1cj0iMXMiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT4gICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9InIiIGNhbGNNb2RlPSJzcGxpbmUiIHZhbHVlcz0iMzA7MDszMCIga2V5VGltZXM9IjA7MC41OzEiIGR1cj0iMSIga2V5U3BsaW5lcz0iMC4yIDAgMC44IDE7MC4yIDAgMC44IDEiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4gICAgICA8L2NpcmNsZT4gICAgPC9nPiAgPC9zdmc+);
		background-size: auto 80%;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.preloader:not(.preloader--is-flow-item) {
	position: relative;
}

.feature-no-svg .preloader:after {
	background-image: url('https://cdn.zebra-group.ru/images/components/preloader/.variant/base/preloader.gif') !important;
}

$preloader-main--colors: (
1: #FDB913,
2: #6C3CE8,
3: #16BECF,
4: #F04B54
);

// $preloader-wrapper-size: 10vw;
$preloader-wrapper-size: 200px;

.b-preloader-main {
	position: absolute;
	width: calc(100vw - 80px);
	height: 100vh;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 999;

	&__wrapper {
		width: $preloader-wrapper-size;
		height: $preloader-wrapper-size;
		position: relative;
		animation: rotate-move 2s ease-in-out infinite;
		visibility: visible;
		filter: url('#preloader-mask');
		// -webkit-filter: url('#preloader-mask');
	}

	&__circle {
		width: calc(#{$preloader-wrapper-size} * 0.35);
		height: calc(#{$preloader-wrapper-size} * 0.35);
		border-radius: 50%;
		background-color: #000;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;

		@each $name, $color in $preloader-main--colors {
			&--#{$name} {
				background-color: $color;
			}
		}
	}

	&__circle--1 {
		animation: dot-1-move 2s ease infinite;
	}

	&__circle--2 {
		animation: dot-2-move 2s ease infinite;
	}

	&__circle--3 {
		animation: dot-3-move 2s ease infinite;
	}

	&__circle--4 {
		animation: dot-4-move 2s ease infinite;
	}

	transition: background 1s ease;

	&.hide-bg {
		background-color: rgba(0, 0, 0, 0);
	}
}

@keyframes rotate-move {
	55% {transform: rotate(0deg)}

	80% {transform: rotate(360deg)}

	100% {transform: rotate(360deg)}
}

@keyframes dot-4-move {
	20% {
		transform: scale(1);
		opacity: .8;
	}

	45% {transform: translate(16px, -12px) scale(.45)}

	60% {transform: translate(80px, -60px) scale(.45)}

	80% {transform: translate(80px, -60px) scale(.45)}

	100% {
		transform: translateY(0px) scale(1);
		opacity: .8;
	}
}

@keyframes dot-3-move {
	20% {transform: scale(1);
		opacity: .8;

	}

	45% {transform: translate(-16px, -12px) scale(.45)}

	60% {transform: translate(-80px, -60px) scale(.45)}

	80% {transform: translate(-80px, -60px) scale(.45)}

	100% {transform: translateY(0px) scale(1);
		opacity: .8;

	}
}

@keyframes dot-2-move {
	20% {transform: scale(1);
		opacity: .8;

	}

	45% {transform: translate(-16px, 12px) scale(.45)}

	60% {transform: translate(-80px, 60px) scale(.45)}

	80% {transform: translate(-80px, 60px) scale(.45)}

	100% {transform: translateY(0px) scale(1);
		opacity: .8;

	}
}

@keyframes dot-1-move {
	20% {transform: scale(1);
		opacity: .8;

	}

	45% {transform: translate(16px, 12px) scale(.45)}

	60% {transform: translate(80px, 60px) scale(.45)}

	80% {transform: translate(80px, 60px) scale(.45)}

	100% {transform: translateY(0px) scale(1);
		opacity: .8;

	}
}
