$btn-size: 30px;

.button--excel {
	@extend %collapse--top;
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--file-excel);
	color: $base-text-color;
	font-size: 0;

	&:before {
		@include font-size(18px);
	}

	&:hover,
	&:focus {
		color: $color-taupe-gray;
		background-color: transparent;
	}

	&:active {
		color: $carrot-orange;
		background-color: transparent;
	}
}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: 160px;
	width: 70px;
	opacity: 0;
	@include svg(70px, 160px, '/images/components/excel-export/.variant/base/arrow-download.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;

	.browser-yabrowser & {
		top: 4px;
		bottom: auto;
		left: auto;
		right: 49px;
		transform: rotate(180deg) translateX(-50%);
	}

	.browser-ie & {
		bottom: 83px;
		left: 67%;
	}

	.browser-safari & {
		top: 4px;
		right: 70px;
		transform: rotateX(180deg) translateX(-50%);
	}

	.browser-firefox & {
		top: 4px;
		right: 69px;
		transform: rotateX(180deg) translateX(-50%);
	}

	.browser-opera & {
		top: 4px;
		right: -32px;
		transform: rotateX(180deg) translateX(-50%);
	}
}

.download-arrow--chrome {
	bottom: 4px;
	left: 39px;
}
