@mixin pseudo($width, $height, $content: '') {
	content: $content;
	display: block;
	position: absolute;
	width: $width;
	height: $height;
}

@mixin pseudo-rem($width, $height, $content: '') {
	content: $content;
	display: block;
	position: absolute;
	@include rem(width, $width);
	@include rem(height, $height);
}

@mixin svg-background-image($svg, $colorFill: null, $colorStroke: null) {
	background-image: svg-load("#{$imagesFolder}#{$svg}", fill=#{$colorFill}, stroke=#{$colorStroke});
	background-repeat: no-repeat;
	background-position: 50%;
}

@mixin svg($width, $height, $svg, $colorFill: null, $colorStroke: null) {
	@include svg-background-image($svg, $colorFill, $colorStroke);
	background-size: $width $height;
}

@mixin svg-rem($width, $height, $svg, $colorFill: null, $colorStroke: null) {
	@include svg-background-image($svg, $colorFill, $colorStroke);
	@include rem(background-size, $width $height);
}

@mixin pseudo-svg($width, $height, $svg, $colorFill: null, $colorStroke: null) {
	@include pseudo($width, $height);
	@include svg($width, $height, $svg, $colorFill, $colorStroke);
}

@mixin pseudo-svg-rem($width, $height, $svg, $colorFill: null, $colorStroke: null) {
	@include pseudo($width, $height);
	@include svg-rem($width, $height, $svg, $colorFill, $colorStroke);
}
