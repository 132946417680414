.b-slider-wrapper--projects {
	.col-clients-custom {
		position: relative !important;
		transform: none !important;
		transform: none !important;
	}
}

.b-projects#clients {
	margin-top: 80px;

	.b-projects-filter-trigger__link {
		@include font(400);
	}

	.b-projects-wrapper {
		flex-basis: 100%;
	}

	.b-projects__plates-row {
		height: 100% !important;
	}

	.b-projects__plates {
		padding-right: 0;

		.b-project-list-item--plate {
			height: auto !important;
		}

		.b-projects__plates-row {
			align-items: center;
			height: auto !important;
		}

		.b-project-list-item__image-wrapper {
			position: relative;
		}
	}

	.l-slider-item__container {
		.row-middle {
			align-items: center;
		}
	}

	.col-clients-custom {
		flex-basis: 19.66667%;
		max-width: 19.66667%;
		text-align: center;
		margin-bottom: 60px;
	}

	.b-projects-filter-trigger__link {
		font-size: 16px;
		margin-top: 5px;
		margin-left: 0;
	}

	.b-projects__filter-wrapper {
		display: none;
		width: 100%;

		.b-projects__filter-trigger {
			margin-bottom: 5px;

			.b-projects-filter-trigger {
				padding: 4px 15px;
				border-left: none;

				&:hover {
					.b-projects-filter-trigger__link {
						color: #EC1847 !important;
						font-weight: 400 !important;
					}
				}
			}

			.b-projects-filter-trigger.is-active {
				.b-projects-filter-trigger__link {
					color: #EC1847 !important;
					font-weight: 400!important;
				}
			}
		}
	}

	.b-projects__list-wrapper {
		width: 280px;
		margin-left: auto;
		position: relative;
	}

	.b-projects-filter-trigger--agricultural-sector {
		margin-top: 25px;
	}

	.b-projects__filter-header {
		font-size: 16px;
		color: $color-white;
		font-weight: 600;
		padding: 4px 15px;
		margin-bottom: 25px;
	}

	.button-memu {
		cursor: pointer;

		svg {
			transition: all 0.3s ease;

			g {
				fill: #1b1a18;
			}
		}

		&.is-expand {
			svg {
				transition: all 0.3s ease;
				transform: rotate(-180deg);
			}
		}
	}

	.b-projects__filter-header-button {
		display: inline-block;
		position: absolute;
		left: -30px;
	}

	.b-projects-filter-trigger--all {
		border-left: none;
	}

	.b-projects__plates {
		margin-top: 100px;
	}
}

.b-projects__clients-header {
	display: flex;
	overflow: hidden;
	padding-top: 12px;
	padding-bottom: 20px;
	margin-top: -8px;

	.value {
		font-size: 205px;
		font-weight: 700;
		color: #EF4C54;
		line-height: 0.66;
	}

	.line {
		margin-left: 40px;
		margin-top: 12px;
	}

	.unit {
		font-size: 40px;
		color: white;
		position: relative;
		padding-right: 32px;

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 100vw;
			top: 0;
			background: #EC1847;
			left: -14px;
		}
	}

	.text {
		font-size: 24px;
		color: $color-white;
	}
}

@media (max-width: 1298px) {
	.b-projects__clients-header {
		.value {
			font-size: 150px;
		}

		.unit {
			font-size: 25px;
		}

		.text {
			font-size: 16px;
		}
	}
}

@media (max-width: 1700px) {
	.b-projects#clients {
		.col-clients-custom {
			flex-basis: 24.66667%;
			max-width: 24.66667%;
		}
	}
}

@media (max-width: 1390px) {
	.b-projects#clients {
		.col-clients-custom {
			flex-basis: 32.66667%;
			max-width: 32.66667%;
		}
	}
}

@media (max-width: 1180px) {
	.b-projects#clients {
		.col-clients-custom {
			flex-basis: 49.66667%;
			max-width: 49.66667%;
		}
	}
}

@media (max-width: 640px) {
	.b-projects#clients {
		.col-clients-custom {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

@include breakpoint(md) {
	.body--cases #projects .b-projects__plates {
		padding-right: 0;
	}
}

@media (max-width: 1000px) {
	.b-projects#clients {
		.b-projects__list-wrapper {
			position: absolute;
			top: 0;
			left: 45px;
		}

		.b-projects__filter-wrapper {
			background: $color-black;
			width: calc(100vw - 80px);
			position: relative;
			padding-bottom: 10px;
			padding-top: 10px;
			padding-left: 60px;
			position: absolute;
			left: -60px;
		}

		.b-projects-filter-trigger__link {
			color: $color-white !important;
		}
	}
}

@media (max-width: 650px) {
	.b-projects#clients {
		.b-projects__clients-header {
			display: block;

			.value {
				margin-bottom: 30px;
				font-size: 90px;
			}
		}
	}
}
