$buttons-panel--sublist-link-width: 30px;
$buttons-panel--sublist-link-height: 30px;
$buttons-panel--counter-width: 16px;
$buttons-panel--counter-height: 16px;
$buttons-panel--font-size: 13px;
$buttons-panel--compare-font-size: 14px;
$buttons-panel--title-font-size: 17px;
$buttons-panel--trigger-width: 30px;
$buttons-panel--trigger-height: 30px;
$buttons-panel--icon-size: 18px;
$buttons-panel--sublist-icon-size: 15px;
$buttons-panel--trigger-icon-size: 18px;
$buttons-panel--title-color: $base-text-color;
$buttons-panel--list-bg-color: $color-eallery;
$buttons-panel--color: $base-text-color;
$buttons-panel--color-hover: $color-white;
$buttons-panel--color-active: orange;
$buttons-panel--bg-color: $buttons-panel--list-bg-color;
$buttons-panel--bg-color-hover: #444444;
$buttons-panel--bg-color-active: #444444;
$buttons-panel--sublist-bg: $color-ironside-gray;
$buttons-panel--counter-color: $color-white;
$buttons-panel--counter-color-hover: $color-mine-shaft;
$buttons-panel--counter-color-active: $color-mine-shaft;
$buttons-panel--counter-bg-color: #666666;
$buttons-panel--counter-bg-color-hover: #cccccc;
$buttons-panel--counter-bg-color-active: #cccccc;
$buttons-panel--tooltips-bg-color: $color-ironside-gray;
$buttons-panel--tooltips-color: $color-white;
$buttons-panel--trigger-color: $color-ironside-gray;
$buttons-panel--trigger-color-hover: $color-black;
$buttons-panel--trigger-color-active: orange;
$buttons-panel--icon-color: $color-ironside-gray;
$buttons-panel--icon-color-hover: $color-black;
$buttons-panel--icon-color-active: orange;
$buttons-panel--sublist-link-icon-color: $color-white;
$buttons-panel--sublist-link-icon-color-hover: $color-gray;
$buttons-panel--sublist-link-icon-color-active: orange;
$buttons-panel--sublist-bg-color: $color-ironside-gray;
$buttons-panel--sublist-icon-bg-color: transparent;
$buttons-panel--sublist-icon-bg-color-hover: transparent;
$buttons-panel--sublist-icon-bg-color-active: transparent;

@if ($contrast) {
	$buttons-panel--title-color: $base-text-color;
	$buttons-panel--list-bg-color: $color-eallery;
	$buttons-panel--color: $base-text-color;
	$buttons-panel--color-hover: $color-white;
	$buttons-panel--color-active: orange;
	$buttons-panel--bg-color: $buttons-panel--list-bg-color;
	$buttons-panel--bg-color-hover: #444444;
	$buttons-panel--bg-color-active: #444444;
	$buttons-panel--sublist-bg: $color-ironside-gray;
	$buttons-panel--counter-color: $color-white;
	$buttons-panel--counter-color-hover: $color-mine-shaft;
	$buttons-panel--counter-color-active: $color-mine-shaft;
	$buttons-panel--counter-bg-color: #666666;
	$buttons-panel--counter-bg-color-hover: #cccccc;
	$buttons-panel--counter-bg-color-active: #cccccc;
	$buttons-panel--tooltips-bg-color: $color-ironside-gray;
	$buttons-panel--tooltips-color: $color-white;
	$buttons-panel--trigger-color: $color-ironside-gray;
	$buttons-panel--trigger-color-hover: $color-black;
	$buttons-panel--trigger-color-active: orange;
	$buttons-panel--icon-color: $color-ironside-gray;
	$buttons-panel--icon-color-hover: $color-black;
	$buttons-panel--icon-color-active: orange;
	$buttons-panel--sublist-link-icon-color: $color-white;
	$buttons-panel--sublist-link-icon-color-hover: $color-gray;
	$buttons-panel--sublist-link-icon-color-active: orange;
	$buttons-panel--sublist-bg-color: $color-ironside-gray;
	$buttons-panel--sublist-icon-bg-color: transparent;
	$buttons-panel--sublist-icon-bg-color-hover: transparent;
	$buttons-panel--sublist-icon-bg-color-active: transparent;
}

%counter {
	position: absolute;
	display: block;
	@include font-size(10px);
	@include font(600);
	text-align: center;
	@include rem(width, $buttons-panel--counter-width);
	@include rem(height, $buttons-panel--counter-height);
	color: $buttons-panel--counter-color;
	background-color: $buttons-panel--counter-bg-color;
	border-width: 2px;
	border-style: solid;
	border-color: $buttons-panel--bg-color;
	border-radius: 50%;
	@include rem(top, 10px);
	@include rem(left, 20px);
	@extend .user-select--no;
}

.b-buttons-panel__item-inner {
	position: relative;
}

.b-buttons-panel__item-description {
	transition: all 0.2s linear;
}

.b-buttons-panel__item-icon {
	transition: all 0.2s linear;

// 	//Мой отчет
// 	&--myreport {
// 		@include webfont-icon($webfont-icon--my-report);
// 	}

// 	//Добавить в мой отчет
// 	&--add {
// 		@include webfont-icon($webfont-icon--addreport_3);
// 	}

// 	//Удалить из моего отчета
// 	&--del {
// 		@include webfont-icon($webfont-icon--addreport_3);

// 		&:after {
// 			content: '-';
// 		}
// 	}

// 	//Печать страницы
// 	&--print {
// 		@include webfont-icon($webfont-icon--printer_2);
// 	}

// 	//Скачать PDF
// 	&--pdf {
// 		@include webfont-icon($webfont-icon--print);
// 	}

// 	//Поделится в соц. сетях или по e-mail
// 	&--share {
// 		@include webfont-icon($webfont-icon--share);
// 	}

// 	//История просмотренных страниц
// 	&--history {
// 		@include webfont-icon($webfont-icon--history);
// 	}

// 	//Обратня связь
// 	&--feedback {
// 		@include webfont-icon($webfont-icon--feedback);
// 	}

// 	//Центр загрузки
// 	&--download {
// 		@include webfont-icon($webfont-icon--dc);
// 	}

// 	//Интерактивный анализ
// 	&--analysis {
// 		@include webfont-icon($webfont-icon--analysis);
// 	}

// 	//Сравнить с прошлым отчетом
// 	&--compare {
// 		@include webfont-icon($webfont-icon--compare);
// 	}

// 	//Смотреть предыдущие отчеты
// 	&--see {
// 		@include webfont-icon($webfont-icon--eye);
// 	}

// 	//предыдущие отчеты
// 	&--previous-reports {
// 		@include webfont-icon($webfont-icon--books);
// 	}

// 	//Оффлайн версия
// 	&--offline {
// 		@include webfont-icon($webfont-icon--connection);
// 	}

// 	//анкета
// 	&--questionary {
// 		@include webfont-icon($webfont-icon--clipboard);
// 	}

// 	//приложения (документы)
// 	&--applications {
// 		@include webfont-icon($webfont-icon--file-word);
// 	}

// 	//приложения ios
// 	&--ios {
// 		@include webfont-icon($webfont-icon--appleinc);
// 	}

// 	//приложения android
// 	&--android {
// 		@include webfont-icon($webfont-icon--android);
// 	}

// 	//приложения ios/android
// 	&--mobile-app {
// 		@include webfont-icon($webfont-icon--mobile);
// 	}

// 	//Компания на карте
// 	&--on-map {
// 		@include webfont-icon($webfont-icon--location2);
// 	}

// 	//Карта сайта
// 	&--site-map {
// 		@include webfont-icon($webfont-icon--tree);
// 	}

// 	//Контакты
// 	&--contacts {
// 		@include webfont-icon($webfont-icon--phone);
// 	}

// 	//zip
// 	&--zip {
// 		@include webfont-icon($webfont-icon--file-zip);
// 	}

// 	//xls
// 	&--xls {
// 		@include webfont-icon($webfont-icon--file-excel);
// 	}

// 	//глосарий
// 	&--glossary {
// 		@include webfont-icon($webfont-icon--library);
// 	}

// 	//архив отчетов
// 	&--archive-reports {
// 		@include webfont-icon($webfont-icon--box-add);
// 	}

// 	//disclaimer
// 	&--disclaimer {
// 		@include webfont-icon($webfont-icon--file-text2);
// 	}

// 	// Компания в социальных сетях
// 	&--social {
// 		@include webfont-icon($webfont-icon--users);
// 	}

// 	//for materials
// 	//annual report
// 	&--ar {
// 		@include webfont-icon($webfont-icon--book);
// 	}

// 	//sustainability report
// 	&--sustainability {
// 		@include webfont-icon($webfont-icon--sustainability);
// 	}

// 	//financial report
// 	&--fr {
// 		@include webfont-icon($webfont-icon--myreport_2);
// 	}

// 	//applications
// 	&--app {
// 		@include webfont-icon($webfont-icon--app);
// 	}

// 	//corp journal
// 	&--journal {
// 		@include webfont-icon($webfont-icon--file-text2);
// 	}
}

.b-buttons-panel__link {
	position: relative;
	display: block;
	text-decoration: none;
	line-height: 1.2;
	background-color: $buttons-panel--bg-color;
	@include font-size($buttons-panel--font-size);
}

//Стили для sublist (share, social, compare, etc.)
.b-buttons-panel__sublist {
	display: none;
	background-color: $buttons-panel--sublist-bg-color;
}

.b-buttons-panel__sublist-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $buttons-panel--sublist-link-icon-color;
	background-color: $buttons-panel--sublist-icon-bg-color;

	&:active {
		color: $buttons-panel--sublist-link-icon-color;
	}

	&:hover,
	&:focus,
	&:visited:focus,
	&:visited:hover {
		color: $buttons-panel--sublist-link-icon-color-hover;
		background-color: $buttons-panel--sublist-icon-bg-color-hover;
	}

	&:active,
	&:visited:active {
		color: $buttons-panel--sublist-link-icon-color-active;
		background-color: $buttons-panel--sublist-icon-bg-color-active;
	}
}

.b-buttons-panel__sublist-link {
	@include rem(width, $buttons-panel--sublist-link-width);
	@include rem(height, $buttons-panel--sublist-link-height);
	overflow: hidden;
	float: left;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--sublist-icon-size);
	}

	&--vkontakte {
		// @include webfont-icon($webfont-icon--vk_2);

		&:before {
			@include font-size(11px);
		}
	}

	// &--linkedin {
	// 	@include webfont-icon($webfont-icon--linkedin2);
	// }

	// &--twitter {
	// 	@include webfont-icon($webfont-icon--twitter);
	// }

	// &--facebook {
	// 	@include webfont-icon($webfont-icon--facebook);
	// }

	// &--instagram {
	// 	@include webfont-icon($webfont-icon--instagram);
	// }

	// &--youtube {
	// 	@include webfont-icon($webfont-icon--youtube);
	// }

	// &--googleplus {
	// 	@include webfont-icon($webfont-icon--google-plus);

	// 	&:before {
	// 		@include font-size(20px);
	// 	}
	// }

	// &--email {
	// 	@include webfont-icon($webfont-icon--mail_4);

	// 	&:before {
	// 		@include font-size(20px);
	// 	}
	// }
}

.b-buttons-panel__sublist-link--compare {
	white-space: nowrap;
	float: none;
	width: auto;
	height: auto;
	@include rem(padding, 5px 10px);
	@include font-size($buttons-panel--compare-font-size);
}

.tooltipster-sidetip.tooltipster-buttons-panel {
	.tooltipster-box {
		border: none;
		background: $buttons-panel--tooltips-bg-color;
		color: $buttons-panel--tooltips-color;
		@include font-size(15px);
		@include font(400);
		@include rem(border-radius, 5px);
	}

	.tooltipster-content {
		color: $buttons-panel--tooltips-color;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $buttons-panel--tooltips-bg-color;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
