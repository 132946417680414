$menu_vertical-accordeon--border: #e2e8ec;
$menu_vertical-accordeon--separator: #ffffff66;
$menu_vertical-accordeon--color: $base-text-color;
$menu_vertical-accordeon--color-hover: #009fe3;
$menu_vertical-accordeon--color-current: #faa61a;

$menu_vertical-accordeon--button-width: 25px;               // The width of the button area
$menu_vertical-accordeon--button-height: 25px;              // The height of the button area
$menu_vertical-accordeon--bar-thickness: 1px;               // The thickness of the button bars
$menu_vertical-accordeon--bar-space: 10px;                  // The spacing between button bars
$menu_vertical-accordeon--transistion-duration: 0.3s;       // The transition duration
$menu_vertical-accordeon--bar-width: 25px;

$menu_vertical-accordeon--button-bg: transparent;
$menu_vertical-accordeon--button-bg-hover: transparent;
$menu_vertical-accordeon--button-bg-active: transparent;
$menu_vertical-accordeon--button-color: #fff;
$menu_vertical-accordeon--button-color-hover: #fff;
$menu_vertical-accordeon--button-color-active: #fff;

.l-main-menu-wrapper {
	// &__tigger {}

	&__panel {
		position: absolute;
		top: 0;
		left: 80px;
		width: calc(100vw - 80px);
		height: 100vh;
		display: none;
		cursor: default;
		background-color: #000;

		.b-nav--vertical-accordeon {
			width: 91vw;
			margin: 0 auto;
		}

		.l-main-menu-cols__separator {
			display: none;
			position: absolute;
			width: calc(100% + 4rem);
			height: 1px;
			left: -2rem;
			bottom: 0;

			background-color: $menu_vertical-accordeon--separator;
		}
	}

	&__panel-inner {
		height: calc(100% - 20px);
		padding: 60px 0 50px;
		margin-top: 20px;
	}
}

.l-main-menu-cols {
	padding: 0px 3.7vw;

	&__col {
		padding-top: 4vh;
		padding-bottom: 4vh;
	}
}

// div class="l-main-menu-cols__col l-main-menu-line col-4 col-md-5 col-xs-12">
.l-main-menu-line {
	position: relative;
	display: flex;
	justify-content: center;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1px;
		background-color: rgba(#ffffff, 0.4);

		@include breakpoint (xs) {
			content: none;
		}
	}
}

.b-nav--vertical-accordeon {
	.b-nav__list {
		text-align: left;
	}

	.b-nav__list--lvl1 {
		padding: 0;
	}

	.b-nav__list-item {
		margin-bottom: 3.3vh;
	}
}

.b-main-menu-item {
	&__link {
		position: relative;
		@include font(700);
		font-size: 42px;
		padding-right: 0;
		color: rgba(#ffffff, 0.6) !important;
		transition: color 0.4s ease;
		line-height: 1.18;
		text-decoration: none;

		&.current {
			color: #FFFFFF !important;
		}

		&:hover {
			color: rgba(#ffffff, 1) !important;
		}
	}

	&__gradient {
		display: none;
	}

	.feature-backgroundcliptext & {
		&__gradient {
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			// background-image: linear-gradient(to right, #16BECF 3.13%, #6C3CE8 63.6%, #F04B54 128.47%);
			background-image: linear-gradient(to right, #16BECF 0%, #6C3CE8 25%, #F04B54 50%, #6C3CE8 75%, #16BECF 100%);
			background-size: 200% auto;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			animation: gradMove 5s infinite linear;
			opacity: 0;
			transition: opacity 0.4s ease;
		}

		&__link {
			display: inline-block;
			transition-duration: 0.3s;
		}

		&__link:hover {
			color: rgba(#ffffff, 0) !important;
			transition-duration: 0.4s;

			.b-main-menu-item__gradient {
				transition-duration: 0.3s;
				opacity: 1;
			}
		}
	}
}

// @keyframes gradMove {
// 	to {
// 		background-position: 200% center
// 	}
// }

// @keyframes hue {
// 	from {
// 		-webkit-filter: hue-rotate(0deg);
// 	}

// 	to {
// 		-webkit-filter: hue-rotate(-360deg);
// 	}
// }

.b-burger-button {
	display: inline-block;
	z-index: 10;
	position: relative;
	background-color: $menu_vertical-accordeon--button-bg;
	@include rem(width, $menu_vertical-accordeon--button-width);
	@include rem(height, $menu_vertical-accordeon--button-height);

	&:hover,
	&:focus {
		background-color: $menu_vertical-accordeon--button-bg-hover;

		.b-burger-button__ingredients-middle,
		.b-burger-button__ingredients:before,
		.b-burger-button__ingredients:after {
			background-color: $menu_vertical-accordeon--button-color-hover;
			width: 100%;
		}
	}

	&:active {
		background-color: $menu_vertical-accordeon--button-bg-active;

		.b-burger-button__ingredients-middle,
		.b-burger-button__ingredients:before,
		.b-burger-button__ingredients:after {
			background-color: $menu_vertical-accordeon--button-color-active;
		}
	}

	&__ingredients {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: $menu_vertical-accordeon--bar-thickness;
		width: $menu_vertical-accordeon--bar-width;
		margin: auto;
		transition: all 0.2s linear;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: 0;
			background-color: $menu_vertical-accordeon--button-color;
			transition: all 0.2s linear;
			border: $menu_vertical-accordeon--bar-thickness solid transparent;
			border-radius: 5px;
		}

		&:before {
			top: -$menu_vertical-accordeon--bar-thickness - $menu_vertical-accordeon--bar-space;
		}

		&:after {
			bottom: -$menu_vertical-accordeon--bar-thickness - $menu_vertical-accordeon--bar-space;
		}
	}

	&__ingredients-middle {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $menu_vertical-accordeon--button-color;
		transition: all 0.2s linear;
		border: $menu_vertical-accordeon--bar-thickness solid transparent;
		border-radius: 5px;
	}

	&.is-expand {
		z-index: 500;
		background: none;

		.b-burger-button__ingredients-middle {
			opacity: 0;
			width: 100%;
		}

		.b-burger-button__ingredients {
			&:before,
			&:after {
				transition-delay: 0s, $menu_vertical-accordeon--transistion-duration;
				background-color: #fff;
				width: 100%;
			}

			&:before {
				top: 0;
				transform: rotate(45deg);
			}

			&:after {
				bottom: -1px;
				transform: rotate(-45deg);
			}
		}
	}
}

$preloader-main--colors: (
1: #16BECF,
2: #FDB913,
3: #F04B54,
4: #6C3CE8,
5: #4DCCAD,
6: #FF9929
);

.b-menu-preview {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	align-content: space-around;

	&__item {
		display: flex;
		flex-direction: column;
		flex: 0 1 33%;
		max-width: 33%;

		// Для первых трех
		&:nth-of-type(-n+3) {
			// margin-bottom: 113.75px;
			margin-bottom: 11.75vh;
		}

		// Для послежних двух
		// &:nth-of-type(n+3) {
		// 	margin-bottom: 0px;
		// 	align-self: flex-end;
		// }

		@each $name, $color in $preloader-main--colors {
			&--#{$name} {
				.b-menu-preview__icon {
					background-color: $color;

					&:before {
						background-image: url('https://cdn.zebra-group.ru/images/icons/slide-0_icon-#{$name}.svg');
					}
				}
			}
		}
	}

	&__link {
		display: block;
		// color: $color-white;
		@include font-size(32px);
		text-decoration: none;
		text-align: center;
		color: rgba(255, 255, 255, 0.7);
		transition: color .4s ease;

		&:hover,
		&:focus {
			.b-menu-preview__icon {
				transform: scale3d(1.2, 1.2, 1);
			}
		}

		&:visited {
			color: rgba(255, 255, 255, 0.7);
			text-decoration: none;
		}

		&:hover,
		&:visited:hover {
			color: rgba(255, 255, 255, 1);
			text-decoration: none;
		}
	}

	&__title {
		@include rem(margin-bottom, 34px);
		// единообразие ссылок
		// @extend .link--animated;
		// @extend .link-mainPAge-type;
		color: currentColor;
		// white-space: nowrap;
		height: 60px;
		overflow: visible;

		@include breakpoint(md) {
			height: 40px;
		}

		@include breakpoint (xs) {
			height: auto;
			text-align: left;

			&:before {
				position: static !important;
			}
		}
	}

	&__icon {
		width: 4.286em;
		height: 4.286em;
		border-radius: 100%;
		margin: auto;
		transition: transform .4s ease;
		transform: scale3d(1, 1, 1);

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: auto 45%;
			background-position: center center;

			.browser-ie & {
				background-position-x: 51%;
			}
		}
	}
}

@media screen and (max-height: 590px) {
	.l-layout-wrapper--1 {
		.b-main-menu-item {
			&.b-nav__list-item {
				margin-bottom: 10px;
			}

			&__title {
				@include font-size(35px);
			}
		}

		.b-menu-preview {
			&:nth-of-type(-n+3) {
				margin-bottom: 0px;
			}

			&__title {
				@include font-size(24px);
				font-size: 0.8em;
			}

			&__icon {
				width: 3.2em;
				height: 3.2em;
			}
		}
	}

	@media screen and (max-width: 1400px) {
		.b-menu-item__title {
			@include font-size(35px);
		}
	}
}

// @media (max-width: 1670px) {
// 	.l-main-menu-wrapper__panel .b-nav--vertical-accordeon {
// 		width: 100%;
// 	}
// }

@media screen and (max-width: 1400px) {
	.b-menu-preview__link {
		@include font-size(32px);
	}
}

@media (max-width: 1280px) {
	.l-main-menu-wrapper__panel .b-nav--vertical-accordeon {
		width: 100%;
	}
}

@media screen and (max-width: 1440px) {
	.b-menu-preview__link {
		@include font-size(32px);
	}

	.l-layout-wrapper--1 {
		.b-menu-preview {
			&__item {
				&:nth-of-type(-n+3) {
					margin-bottom: 5vh;
				}
			}
		}
	}
}

@include breakpoint(lg) {
	.l-layout-wrapper--1 {
		.b-main-menu-item {
			&__title {
				@include font-size(35px);
			}
		}
	}
}

@include breakpoint(md) {
	.b-main-menu-item-project {
		height: 290px;
	}

	.b-menu-preview__item {
		flex-basis: 45%;
		max-width: 45%;

		&:nth-of-type(-n+4) {
			margin-bottom: 5vh;
		}
	}

	.b-menu-preview__link {
		font-size: 2.7vw;
	}
}

@include breakpoint(sm) {
	.l-main-menu-wrapper__panel {
		left: 0px;
		width: 100%;
	}

	.l-main-menu-wrapper__panel-inner {
		height: 100%;
		margin-top: 0;
		padding-top: 85px;
	}

	.l-main-menu-cols {
		padding: 0 20px;
	}

	.b-menu-preview__link {
		font-size: 30px;
	}

	.l-layout-wrapper--1 {
		.b-menu-preview {
			&__title {
				@include font-size(24px);
			}

			&__icon {
				width: 3.8em;
				height: 3.8em;
			}
		}

		.b-main-menu-item {
			&__title {
				@include font-size(24px);
			}
		}
	}
}

@media (max-width: 750px) {
	.l-layout-wrapper--1 .b-menu-preview__title {
		font-size: 3vw;
	}

	.b-menu-preview__link {
		font-size: 4vw;
	}
}

@include breakpoint(xs) {
	.l-layout-wrapper--1 {
		.l-main-menu-wrapper__panel {
			.l-main-menu-cols__separator {
				display: block;
			}
		}

		.b-menu-preview {
			align-items: flex-start;
			flex-direction: column;
			align-content: flex-start;
			margin-top: 21px;
			flex-wrap: nowrap;
			height: auto;

			&__item {
				max-width: 100%;
				align-self: auto;

				&:nth-of-type(-n+4) {
					margin-bottom: 0px;
				}

				& + .b-menu-preview__item {
					margin-top: 0.9em;
				}

				@each $name, $color in $preloader-main--colors {
					&--#{$name} {
						.b-menu-preview__title {
							display: flex;
							align-items: center;

							@include font-size(18px);
							font-weight: normal;
							line-height: 141.6%;

							&:before {
								content: '';
								display: inline-block;
								@include rem(width, 12px);
								@include rem(height, 12px);
								@include rem(margin-right, 14.5px);
								background-color: $color;
								border-radius: 100%;
							}
						}
					}
				}
			}

			&__title {
				margin-bottom: 0px;
			}

			&__icon {
				display: none;
			}
		}

		.b-main-menu-item {
			&__title {
				@include font-size(18px);
				line-height: 99.6%;
			}

			&:last-of-type {
				padding-bottom: 10.5px
			}
		}
	}
}
