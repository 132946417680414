.content-area,
%list {
	li {
		position: relative;
		margin: 0.5em 0;
		padding-left: 1.7em;
	}

	//Маркерованный список
	ul {
		margin: 1em 0;

		li {
			&:before {
				@include pseudo($list-marker-size, $list-marker-size, "");
				border-radius: 100%;
				top: 0.563em;
				left: 0.188em;
				background-color: #ec1847;
			}

			ul li {
				&:before {
					background-color: transparent;
					border: 1px solid #ec1847;
				}
			}
		}

		ol {
			counter-reset: list-numbers;

			li {
				&:before {
					background-color: transparent;
				}
			}
		}

		ul {
			margin: 0;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 0 !important;

			&:before {
				display: none !important;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;

		&>li {
			&:before {
				content: counters(list-numbers, ".");
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				color: $base-text-color;
				top: 0;
				left: 0;
				text-align: right;
				font-weight: bold;
			}
		}

		ul {
			li {
				&:before {
					content: "";
					width: $list-marker-size;
					top: 0.563em;
					left: 0.188em;
				}
			}
		}
	}

	th,
	td {
		li {
			&:before {
				top: 0.393em;
			}
		}
	}

	.list--collapse-top {
		margin-top: 0;

		&>li:first-child {
			margin-top: 0;
		}
	}
}

#colorbox {
	@extend %list;

	ul {
		li {
			&:before {
				top: 0.375em;
			}
		}
	}
}

.news-page__text {
	ul {
		margin-top: -10px;
	}

	// ol {
	// 	li:before {
	// 		content: counters(list-numbers,".");
	// 		counter-increment: list-numbers;
	// 		display: block;
	// 		font-size: 13px;
	// 		width: 13px;
	// 		position: absolute;
	// 		color: #EC1847;
	// 		top: 0;
	// 		left: 0;
	// 		text-align: right;
	// 	}
	// }
}

.social {
	margin-top: 10px;

	&__list {
		display: flex;
		justify-content: flex-start;
	}

	&__item {
		margin-right: 24px;
	}

	&__icons {
		display: block;
		width: 30px;
		position: relative;
	}
}

.b-slide-contacts {
	.social__icons {
		opacity: 0.65;
		transition: opacity 0.25s ease;
	}

	.social__icons:hover {
		opacity: 1;
	}
}