.b-noty--old-browser {
	.b-noty__body {
		text-align: center;
		@include rem(padding, 30px);
	}

	.b-noty__text {
		a {
			color: $color-white;

			&:hover,
			&:active,
			&:focus {
			}
		}
	}
}
