/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $link-default-color;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: none;
	}

	&:visited {
		color: $link-visited-color;

		&:hover,
		&:focus {
			color: $link-hover-color;
			text-decoration: none;
		}
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&.btn {
		&:hover {
			color: $color-white;
		}

		&:visited {
			color: $color-mine-shaft;

			&:hover,
			&:focus {
				color: $color-white;
			}
		}
	}
}

[data-anchor-link] {
	cursor: pointer;
}

.link--animated {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
	}

	&-1 {
		padding-right: 2rem;

		&:before {
			width: 0;
			left: 0;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) -1.61%, #ffffff 77.87%);
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover:before {
			left: 15%;
			width: 100%;
			// animation-name: link-hover-animation-1;
			animation-name: link-hover-animation-1_1;
			animation-duration: 1.2s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}
	}

	&-1--menu {
		padding-right: 2rem;

		.b-menu-preview__title:before {
			width: 0;
			left: 0;
			height: 100%;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) -1.61%, #ffffff 77.87%);
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover .b-menu-preview__title:before {
			left: 15%;
			width: 100%;
			animation-name: link-hover-animation-1_1;
			animation-duration: 1.2s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}
	}

	&-2 {
		&:before {
			width: 100%;
			height: 0;
			left: 0;
			bottom: 0;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
			top: 100%;
			transform: translateY(-100%);
		}

		&:hover:before {
			bottom: 100%;
			height: 100%;
			animation-name: link-hover-animation-2;
			// animation-name: link-hover-animation-2_2;
			animation-duration: 1.2s;
			animation-timing-function: ease-in-out;
			//
			// animation-fill-mode: forwards;
		}
	}

	&-3 {
		&:hover {
			animation-name: link-hover-animation-2a;
			animation-duration: 1.2s;
			animation-timing-function: ease-in-out;
		}
	}
}

.b-circle-link {
	background-color: transparent !important;
	text-align: center;
	@include font-size(22px);
	text-decoration: none;
	width: 6.5em;
	height: 6.5em;
	line-height: 1.1;
	position: relative;
	transition: transform .4s ease;
	transform: scale(.9);
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		border: 1px solid currentColor;
		border-radius: 50%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		transform: scale(1);
	}
	@extend .link-mainPAge-type;

	@media screen and (max-width: 1440px) {
		@include font-size(20px);
	}
}

.link-mainPAge-type {
	color: rgba(255, 255, 255, 0.8);
	text-decoration: none;

	&:visited {
		color: rgba(255, 255, 255, 0.8);
		text-decoration: none;
	}

	&:hover,
	&:visited:hover {
		color: rgba(255, 255, 255, 1);
		text-decoration: none;
	}
}

.link-witch-gradient {
	position: relative;

	&__substrate {
		display: none;
	}

	.feature-backgroundcliptext & {
		&__substrate {
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			background-image: linear-gradient(to right, #16becf 0%, #6c3ce8 25%, #f04b54 50%, #6c3ce8 75%, #16becf 100%);
			background-size: 200% auto;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			animation: gradMove 5s infinite linear;
			opacity: 0;
			transition: opacity 0.4s ease;
		}
		transition-duration: 0.3s;

		&:hover {
			color: rgba(#ffffff, 0) !important;
			transition-duration: 0.4s;

			.link-witch-gradient__substrate {
				transition-duration: 0.3s;
				opacity: 1;
			}
		}
	}
}

.link-witch-arrow {
	@include webfont-icon($webfont-icon--list-arrow, 'after');

	&:after {
		padding-left: 1em;
		transition: transform .3s ease;
		font-size: .5em;
	}

	&:hover {
		&:after {
			transform: translateX(1em);
		}
	}
}

.link--external {
	@include webfont-icon($webfont-icon--arrow-external-link);
	// font-weight: 400;

	&:before {
		display: inline-block;
		@include font-size(14px);
		margin-right: 0.2em;
	}

	&:active,
	&:hover {
		color: $link-hover-color;
	}

	&:hover:before,
	&:focus:before {
		color: $link-hover-color;
		text-decoration: none;
	}

	&:visited {
		color: $link-visited-color;

		&:hover,
		&:focus {
			color: $link-hover-color;
			text-decoration: none;
		}

		&:before {
			color: $link-visited-color;
		}

		&:hover:before,
		&:focus:before {
			color: $link-hover-color;
			text-decoration: none;
		}
	}

	&.is-fixed {
		text-decoration: none;

		.link-text {
			text-decoration: underline;
		}

		&:hover .link-text {
			text-decoration: none;
		}
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}

.body--slider-services {
	.digital-selector {
		color: #939597;
	}
}

@keyframes link-hover-animation-1 {
	0% {
		width: 0;
		left: 0;
	}

	50% {
		width: 100%;
	}

	100% {
		left: 15%;
	}
}

@keyframes link-hover-animation-1_1 {
	0% {
		width: 0;
		left: 0;
	}

	50% {
		width: 100%;
	}

	60% {
		left: 15%;
	}

	100% {
		left: 100%;
	}
}

@keyframes link-hover-animation-2 {
	0% {
		height: 0;
		bottom: 0;
	}

	50% {
		height: 100%;
	}

	100% {
		bottom: 15%;
	}
}

@keyframes link-hover-animation-2_2 {
	0% {
		height: 0;
		bottom: 0;
	}

	50% {
		height: 100%;
	}

	60% {
		bottom: 15%;
	}

	100% {
		bottom: 100%;
		// height: 0;
	}
}

@keyframes link-hover-animation-2a {
	0% {
		color: $color-white;
	}

	100% {
		color: #404041;
	}
}

@keyframes gradMove {
	to {
		background-position: 200% center;
	}
}
