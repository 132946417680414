.b-form--contact {
	color: $color-white;

	.b-form__title {
		font-size: 30px;
		@include font(700);
		margin-bottom: 0.7em;
	}

	.b-form__field-placeholder--smart {
		color: #a6a8ab;
	}

	.b-form__field-title {
		color: #a6a8ab;
	}

	.b-form__field-input-wrapper {
		display: inline;
	}

	.b-form__field--upload {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 12px;
		padding-bottom: 25px;

		.b-form__field-input {
			padding-bottom: 0;
		}

		input {
			display: none;
		}
	}

	.b-form__field-text--upload {
		margin-top: 0px;
		display: block;
		font-size: 20px;
		font-weight: 600;
		padding-left: 0;
		color: #ED1846;
		border-bottom: 1px solid;
		white-space: nowrap;
		cursor: pointer;
	}

	.b-form__field-text--upload-file {
		font-size: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 32px;
	}

	.b-form__field--comment {
		margin-top: 5px;
	}

	.btn-group {
		.dropdown-toggle {
			transition: color 0.3s ease;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
			}
		}

		.dropdown-menu {
			li:first-child {
				margin-top: 0.6em;
			}
		}
	}

	textarea::placeholder {
		color: #58595b !important;
	}

	.b-captcha {
		@media (min-width: 1055px) and (max-width: 1300px) {
			@include captchaAdaptive();
		}

		@media (max-width: 480px) {
			@include captchaAdaptive();
		}
	}

	input::-ms-clear {
		display: none;
	}

	input[type="file"]::-ms-value {
		background: #050506;
		color: #fff;
		border: 1px solid transparent;
		padding: 4px;
	}

	input[type="file"]::-ms-browse {
		display: none;
		visibility: hidden;
	}

	#parsley-id-multiple-vacancy
	.parsley-required {
		margin-top: -2px;
	}

}
