.body--slider-mainpage {
	height: 100%;
	overflow: hidden;

	.l-navigation-panel__item--logo {
		&.is-static {
			cursor: default;
		}
	}

	// #popup-locker {
	// 	display: none !important;
	// }

	.l-page__nav {
		display: none;
	}

	.b-slider-wrapper.preloader {
		z-index: 100;

		&:after {
			background-color: #000000;
			z-index: 999;
		}

		// .b-sub-slider-pagination__item {
		// 	display: none;
		// }
	}

	.l-layout-wrapper {
		padding-top: 0;
		min-height: 100%;

		.l-page {
			height: 100%;
			padding-left: 80px;
			padding-right: 0;
		}
	}

	.l-page__inner {
		width: 100%;
	}

	// .b-popup--hire-us {
	// 	display: none;
	// 	left: auto;
	// 	right: 0;
	// 	top: 0;
	// 	transform: none;
	// 	width: calc(100% - 80px);
	// 	height: 100%;
	// 	overflow-x: hidden;
	// 	background-color: #404041;

	// 	.b-popup__inner {
	// 		top: 0;
	// 		left: 0;
	// 		max-width: 100%;
	// 		width: 100%;
	// 		height: 100%;
	// 		background-color: #404041;
	// 		-webkit-overflow-scrolling: touch;
	// 	}
	// }

	.brochure-link {
		position: absolute;
		right: calc(2.2em + 2vw);
		bottom: 5vh;
		margin-right: calc((100vw - 100%) / -2);
		display: inline-flex;
		align-items: flex-end;
		color: rgba(255, 255, 255, 0.7) !important;
		font-size: 20px;
		transition: color 0.2s ease, opacity 0.9s ease 1.2s, transform 0.9s ease 1.2s;
		opacity: 0;
		transform: translateY(-5%);
		text-decoration: none;
		line-height: 1;
		@include webfont-icon($webfont-icon--brochure);
		z-index: 1;

		&:before {
			font-size: 2.7em;
			margin-right: 0.25em;
		}

		&__text {
			margin-bottom: 0.05em;
		}

		&:hover {
			color: #FFF !important;

			.brochure-link__text {
				text-decoration: underline;
			}
		}
	}

	.l-layout-wrapper .l-slider-pagination-wrapper {
		width: 0;
		right: calc(25px + 2.6vw);
	}

	.b-slider-pagination-progress-bar {
		transition: background-color 0.2s ease, opacity 0.4s ease;

		&:before {
			display: none;
		}

		&__circle {

		}

		.js-progress-circle {
			transition: stroke 0.2s ease;
		}
	}

	.l-slider-pagination-progress-wrapper {
		.b-slider-pagination__item {
			transition: transform 0.6s ease;
		}

		&.on-begin {
			.b-slider-pagination-progress-bar {
				opacity: 0;

				&__circle {
					width: 0px;
					height: 0px;
					opacity: 0;
				}
			}

			.b-slider-pagination__item {
				transform: scale(0);
			}
		}
	}

	.l-slider-pagination-progress-wrapper._light {
		.b-slider-pagination__item {
			color: #FFF !important;
		}

		.b-slider-pagination-progress-bar {
			background-color: #FFF !important;
		}

		.js-progress-circle {
			stroke: #FFF !important;
		}
	}

	.b-circle-link {
		min-width: 6.5em;
		min-height: 6.5em;

		&._in-col {
			display: none;
		}
	}

	.b-slider-wrapper--mainpage {
		position: relative;
	}

	.b-slider-item {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	.b-slider-item--0 {
		// background-color: #231f20;

		.l-slider-item__inner {
			&:before {
				content: '';
				position: absolute;
				width: 100vw;
				height: 100vh;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/bg-overlay-part.png');
				background-size: 3px 3px;
				z-index: 1;
			}
		}

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 100%;
		// 	min-width: 1200px;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	margin: auto;
		// 	@include svg(100%, 100%, '/images/components/slider/images/bg-overlay-part.png');
		// 	z-index: 1;
		// }

		.brochure-link {
			transition: color 0.2s ease, opacity 0.9s ease 1.6s, transform 0.9s ease 1.6s;
		}

		.b-slide-main {
			max-width: 1150px;
			margin: auto;
		}

		.b-slide-main__data {
			position: relative;
			padding-bottom: 30px;
			margin: auto;
			z-index: 2;

			svg {
				overflow: visible !important;
			}
		}

		.b-slide-news {
			z-index: 2;
		}

		.browser-ie & {
			.l-slider-item__inner {
				position: relative;
			}

			.b-slider-item__bg {
				position: relative;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				transform: none;
				margin: auto;
			}

			.l-slider-item__container {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
			}
		}
	}

	.b-slider-item--2,
	.b-slider-item--3,
	.b-slider-item--4 {
		// background: url('https://cdn.zebra-group.ru/images/components/slider/images/bg-overlay-part-light.png');
		// background-size: 4px 4px;
		background: url('https://cdn.zebra-group.ru/images/components/slider/images/bg-overlay-part.png');
		background-size: 3px 3px;
	}

	.b-sub-slider-item {
		background-size: cover;
		background-position: center center;
		visibility: hidden;
	}

	.b-sub-slider-item--0 {
		background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/bg--4.jpg');
	}

	.b-sub-slider-item--1 {
		background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/bg--2.jpg');
	}

	.b-sub-slider-item--2 {
		background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/bg--3.jpg');
	}

	.b-sub-slider-item--3 {
		background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/bg--5.jpg');
	}

	.b-slider-item__bg {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		max-width: 1200px;
		transform: translate(-50%, -50%);

		& > [data-svg] svg {
			overflow: visible !important;
		}

		&--flat {
			&:before {
				content: '';
				position: absolute;
				width: 100vw;
				height: 100vh;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #231f20;
				// background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/splash-bg.jpg');
				// background-size: cover;
				// background-position: center center;
				// opacity: 0.8;
			}
		}
	}

	.b-slider-item__content,
	.b-sub-slider-item__content {
		padding: 0 25px;
		color: $color-white;

		*:last-child {
			margin-bottom: 0;
		}
	}

	// .l-sub-slider-pagination-wrapper {
	// 	position: absolute;
	// 	display: flex;
	// 	align-items: center;
	// 	width: 0;
	// 	height: 100%;
	// 	top: 0;
	// 	left: -25px;
	// }

	// .b-sub-slider-pagination {
	// 	width: 50px;
	// 	min-width: 50px;

	// 	&__item {
	// 		position: relative;
	// 		display: block;
	// 		@include rem(width, 50px);
	// 		@include rem(height, 50px);
	// 		background-color: #ed1846;
	// 		text-align: center;
	// 		opacity: 1;
	// 		border-radius: 100%;
	// 		transition: background-color 0.5s ease, transform 0.5s ease, margin 0.5s ease;
	// 		@include webfont-icon($webfont-icon--zebra-logo, 'after');
	// 		outline: 0;
	// 		transform: scale(0.24);
	// 		margin-bottom: 0;
	// 		z-index: 200;

	// 		&:before {
	// 			content: '';
	// 			position: absolute;
	// 			width: 65%;
	// 			height: 65%;
	// 			background-color: $color-white;
	// 			top: 0;
	// 			right: 0;
	// 			bottom: 0;
	// 			left: 0;
	// 			margin: auto;
	// 			border-radius: 100%;
	// 			transition: width 0.2s ease, height 0.2s ease, background-color 0.5s ease;
	// 		}

	// 		&:after {
	// 			position: absolute;
	// 			top: 0;
	// 			right: 0;
	// 			bottom: 0;
	// 			left: 0;
	// 			margin: auto;
	// 			color: $color-white;
	// 			font-size: 27px;
	// 			height: 27px;
	// 			transition: transform 0.5s ease;
	// 			transform: scale(0);
	// 		}

	// 		&:nth-child(1).swiper-pagination-bullet-active {
	// 			background-color: #ed1847;

	// 			&:before {
	// 				background-color: #ed1847;
	// 			}
	// 		}

	// 		&:nth-child(2).swiper-pagination-bullet-active {
	// 			background-color: #553b96;

	// 			&:before {
	// 				background-color: #553b96;
	// 			}
	// 		}

	// 		&:nth-child(3).swiper-pagination-bullet-active {
	// 			background-color: #fdb912;

	// 			&:before {
	// 				background-color: #fdb912;
	// 			}
	// 		}

	// 		&:nth-child(4).swiper-pagination-bullet-active {
	// 			background-color: #16bece;

	// 			&:before {
	// 				background-color: #16bece;
	// 			}
	// 		}

	// 		&.swiper-pagination-bullet-active {
	// 			// width: 50px;
	// 			// height: 50px;
	// 			transform: scale(1);
	// 			margin-bottom: 19px;

	// 			&:first-child {
	// 				margin-top: 18px;
	// 			}

	// 			&:not(:first-child) {
	// 				margin-top: -4px !important;
	// 			}

	// 			&:before {
	// 				width: 100%;
	// 				height: 100%;
	// 			}

	// 			&:after {
	// 				transform: scale(1);
	// 			}
	// 		}

	// 		& + .b-sub-slider-pagination__item {
	// 			margin-top: -22px;
	// 		}
	// 	}

	// 	&:not(.is-active) {
	// 		.b-sub-slider-pagination__item {
	// 			transform: scale(0);
	// 		}
	// 	}

	// 	&.is-hidden {
	// 		.b-sub-slider-pagination__item {
	// 			transform: scale(0);
	// 		}
	// 	}
	// }

	// @include breakpoint(xs) {
	// 	.b-sub-slider-pagination {
	// 		width: 40px;
	// 		min-width: 40px;

	// 		&__item {
	// 			@include rem(width, 40px);
	// 			@include rem(height, 40px);

	// 			&:after {
	// 				font-size: 22px;
	// 				height: 22px;
	// 			}
	// 		}
	// 	}
	// }

	.b-slide-mouse {
		position: absolute;
		display: block;
		left: 50%;
		margin-left: -11px;
		bottom: 1vh;
		cursor: pointer;
		z-index: 1;
	}

	.b-slide-news {
		position: absolute;
		right: 0;
		bottom: 10vh;
		max-width: 285px;
		transform: translateX(100%);
		transition: transform 0.7s ease;
		transition-delay: 2s;

		&__image {
			position: relative;
			overflow: hidden;
			max-width: 195px;
			padding-bottom: 10px;

			img {
				position: relative;
				transform: translateY(calc(100% + 17px));
				transition: transform 0.6s ease;
				transition-delay: 2.7s;
			}
		}

		&__title {
			position: relative;
			overflow: hidden;
			display: block;
			@include font(400);
			@include font-size(29px);
			border-top: 1px solid #ed1846;
			padding-top: 10px;
			padding-right: 16px;
			line-height: 1;

			span {
				position: relative;
				display: inline-block;
				transform: translateY(calc(-100% - 10px));
				transition: transform 0.6s ease;
				transition-delay: 2.7s;
			}
		}
	}

	.b-slide-text {
		position: relative;
		display: flex;
		align-items: center;
		max-width: 1300px;
		margin: auto;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: $color-white;
			border-radius: 50%;
			filter: blur(50px);
			opacity: 0;
			transition: opacity 1s ease;

			.browser-ie & {
				background-color: transparent;
				background-image: url('https://cdn.zebra-group.ru/images/components/slider/images/slide-gradient.png');
				border-radius: 0;
				background-size: 100% 100%;
			}
		}

		&__title {
			display: flex;
			justify-content: center;
			min-width: 780px;
			@include font(700);
			text-transform: uppercase;

			& > span {
				display: inline-block;
				white-space: nowrap;
			}
		}

		&__title-part--sup {
			position: relative;
			@include font-size(40px);
			line-height: 1.2;
			display: inline-block;
			overflow: hidden;
			// transition: transform 1s ease;
			transition: opacity 1s linear, transform 1s ease;
			transform: translateX(-10%);
			transition-delay: 0.2s;
			opacity: 0;

			span {
				display: inline-block;
				// transition: transform 1s ease;
				// transition-delay: 1s;
			}
		}

		&__title-part--main {
			position: relative;
			display: inline-block;
			@include font-size(150px);
			line-height: 1;
			opacity: 0;
			transition: opacity 1s linear;
		}

		&__side {
			position: relative;
			display: flex;
			align-items: center;
			// border-left: 1px solid;
			padding-left: 30px;
			margin-left: 30px;

			&:before {
				content: '';
				position: absolute;
				border-left: 1px solid;
				height: 100%;
				top: 50%;
				left: 0;
				transform: translateY(-50%) scaleY(0);
				transition: transform 0.45s ease-out;
				transition-delay: 0.7s;
			}
		}

		&__text {
			@include font(400);
			line-height: 1.2;
			padding-right: 16px;
			padding-top: 3vh;
			padding-bottom: 3vh;
			opacity: 0;
			transition: opacity 0.55s linear;
			transition-delay: 1.15s;

			p {
				color: #231f20;
				@include font-size(20px);
			}
		}

		.b-slide-link-wrapper {
			opacity: 0;
			transition: opacity 0.55s linear;
			transition-delay: 1.15s;
		}
	}

	.b-slide-link {
		@include webfont-icon($webfont-icon--arrow);
		color: #231f20 !important;
		text-decoration: none;
		display: flex;
		align-items: center;

		span {
			display: none;
			padding-left: 7px;
		}

		&:before {
			display: block;
			@include font-size(32px);
		}
	}

	.b-sub-slider-item--0 {
		.b-slide-text {
			&__title-part {
				// color: #ed1846;
				color: #16BECF;

				html:not(.browser-ie) & {
					span {
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}

					&:nth-of-type(1) {
						transform: translateX(10%);
						// transform: translateX(20%);

						span {
							// background-image: linear-gradient(to right, #4c1b51, #9b1d4c);
							background-image: linear-gradient(to right, #047069, #16BECF);
							// transform: translateX(-100%);
						}
					}

					&:nth-of-type(2) {
						span {
							// background-image: linear-gradient(to right, #4c1b51, #ed1847);
							background-image: linear-gradient(to right, #047069, #16BECF);
						}
					}

					&:nth-of-type(3) {
						// transform: translateX(-20%);

						span {
							// background-image: linear-gradient(to right, #841c4d, #ed1847);
							background-image: linear-gradient(to right, #047069, #16BECF);
							line-height: 1.9;
							// transform: translateX(100%);
						}
					}
				}

				&:nth-of-type(3) {
					margin-left: 5em;
				}
			}

			&__title-part--main {
				padding-left: 0.47em;
			}

			&__side:before {
				// border-left-color: #ed1846;
				border-left-color: #16BECF;
			}
		}
	}

	.b-sub-slider-item--1 {
		.b-slide-text {
			&__title-inner {
				color: #F04B54;
			}

			&__title-part:nth-of-type(2) {
				margin-left: 3.25em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__title-part:nth-of-type(3) {
				margin-left: 7.5em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__side:before {
				border-left-color: #F04B54;
			}
		}
	}

	.b-sub-slider-item--2 {
		.b-slide-text {
			&__title-inner {
				color: #7542F6;
			}

			&__title-part:nth-of-type(2) {
				margin-left: 3.25em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__title-part:nth-of-type(3) {
				margin-left: 9.5em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__side:before {
				border-left-color: #7542F6;
			}

			&__title-part--main {
				@include font-size(95px);
			}
		}
	}

	.b-sub-slider-item--3 {
		.b-slide-text {
			&__title-inner {
				color: #FDB913;
			}

			&__title-part:nth-of-type(2) {
				margin-left: 3.25em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__title-part:nth-of-type(3) {
				margin-left: 7.5em;
				// transform: translateX(20%);

				span {
					// transform: translateX(-100%);
				}
			}

			&__side:before {
				border-left-color: #FDB913;
			}
		}
	}

	.b-slide-key {
		max-width: 1300px;
		margin: auto;

		&__title {
			position: relative;
			margin-bottom: 35px;
			padding-bottom: 12px;
			flex-grow: 1;
			flex-shrink: 1;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				border-bottom: 1px solid #fff;
				transform: scaleX(0);
				// transition: transform 1s ease;
				// transition-delay: 0.35s;
			}
		}

		&__title-wrapper {
			display: flex;
		}

		&__title-inner {
			display: inline-block;
			@include font-size(40px);
			@include font(700);
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
			// background-image: linear-gradient(to right, #4c1b51, #ed1847);
			color: #fff;
			line-height: 1;
			opacity: 0;
			// transition: opacity 1s linear;
		}

		&__indicators-wrapper {
			padding-top: 4vh;
			// padding-right: 90px;
		}

		.b-indicator {
			&__value {
				display: inline-block;
				color: #fff;
				// @include font-size(100px);
				font-size: 4em;
				@include font(700);
			}

			&__unit {
				display: inline-block;
				color: #fff;
				@include font-size(40px);
				@include font(700);

				&:first-child {
					margin-top: 0.27em;
				}
			}

			&__text {
				display: block;
				color: #fff;
				@include font-size(20px);
				line-height: 1.1;
			}
		}

		.row {
			&:first-child {
				opacity: 0;
				transform: translateY(-10%);
				// transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1s;
			}

			&:last-child {
				opacity: 0;
				transform: translateY(-10%);
				// transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 1.3s;
			}

			& + .row {
				margin-top: 10vh;
			}
		}

		.b-slide-link-wrapper {
			opacity: 0;
			// transition: opacity 0.8s linear;
			// transition-delay: 0.9s;
		}

		.b-slide-link {
			color: $color-white !important;
		}
	}

	.b-slide-footer {
		display: flex;
		align-items: center;
		justify-content: center;

		&__text {
			text-transform: uppercase;
			@include font-size(80px);
			@include font(700);
			color: $color-white;
		}
	}

	.b-slide-icons {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		&__title {
			@include font-size(40px);
			@include font(700);
			margin-bottom: 5.8vh;
			line-height: 1;
			opacity: 0;
			// transition: opacity 1s linear;
		}

		&__link {
			position: relative;
			@include font-size(16px);
			text-transform: lowercase;
			text-decoration: none;
			background: white;
			color: #ed1846 !important;
			border-radius: 50px 0 0 50px;
			height: 61px;
			display: flex;
			align-items: center;
			padding: 13px 0 13px 13px;
			@include webfont-icon($webfont-icon--arrow);
			line-height: 1;
			text-align: left;

			&:before {
				@include font-size(32px);
				margin-right: 14px;
			}

			&:after {
				position: absolute;
				content: '';
				height: 100%;
				width: 100vw;
				top: 0;
				left: calc(100% - 1px);
				background: $color-white;
			}
		}

		&__col {
			opacity: 0;
			transform: translateY(-18%);
			// transition: opacity 0.9s linear, transform 0.9s ease;

			&:nth-child(n+4) {
				margin-top: 2.9vh;
			}

			// @for $i from 1 through 9 {
			// 	&:nth-child(#{$i}) {
			// 		transition-delay: (0.1s * $i) + 0.2s;
			// 	}
			// }
		}

		&__image {
			width: 50%;

			img {
				opacity: 0;
				transform: translateY(-2%);
				// transition: opacity 0.9s linear, transform 0.9s ease;
				// transition-delay: 0.2s;
			}
		}

		&__main-wrapper {
			width: 50%;
			padding-left: 40px;
		}

		&._linked {
			.b-slide-icons__link-wrap {
				opacity: 0;
				padding: 4vw;
				transition: opacity 0.9s linear 1.2s;
			}

			.b-slide-icons__outer-col {
				&._link {
					flex-grow: 0;
					align-items: center;
					justify-content: center;

					.browser-ie & {
						min-width: 13vw;
						max-width: 295px;
					}
				}
			}

			.b-slide-icons__col {
				&:nth-child(even) {
					align-items: flex-end;

					[data-svg] {
						min-width: 140px;
					}
				}

				&:nth-child(n+3) {
					margin-top: 2.9vh;
				}

				&:nth-child(n+5) {
					margin-top: 4.9vh;
				}
			}
		}
	}

	// .b-slide-contacts {
	// 	height: 100%;
	// 	display: flex;
	// 	flex-direction: column;

	// 	&__title {
	// 		@include font-size(60px);
	// 		@include font(700);
	// 		margin-bottom: 4vh;
	// 		line-height: 1;
	// 	}

	// 	&__info {
	// 		@include font-size(20px);
	// 		@include font(300);

	// 		p {
	// 			margin-bottom: 0;
	// 			@include font-size(20px);
	// 		}

	// 		span {
	// 			color: #6d6e70;
	// 			@include font(400);
	// 		}

	// 		a {
	// 			color: $color-white !important;
	// 			text-decoration: none;
	// 			line-height: 1;
	// 			border-bottom: 1px solid #ec1847;
	// 			transition: 0.3s ease;

	// 			&:hover,
	// 			&:focus {
	// 				border-bottom-color: transparent;
	// 			}
	// 		}

	// 		& + .b-slide-contacts__info {
	// 			margin-top: 25px;
	// 		}
	// 	}

	// 	&__info-address {
	// 		a {
	// 			display: none;
	// 			@include webfont-icon($webfont-icon--location);
	// 			color: $color-white;
	// 			transition: color 0.3s ease;
	// 			border: none;

	// 			&:before {
	// 				font-size: 0.9em;
	// 				vertical-align: baseline;
	// 				margin-right: 0;
	// 			}

	// 			&:hover,
	// 			&:focus {
	// 				color: #ec1847 !important;
	// 			}
	// 		}
	// 	}

	// 	&__form-btn {
	// 		display: none;
	// 		@include font-size(25px);
	// 		@include font(600);
	// 		margin-top: 16px;
	// 		border-bottom: 1px solid #ec1847;
	// 		line-height: 1.15;
	// 		transition: 0.3s ease;
	// 		cursor: pointer;

	// 		&:hover,
	// 		&:focus {
	// 			border-bottom-color: transparent;
	// 		}
	// 	}

	// 	&__subtitle {
	// 		@include font-size(20px);
	// 		@include font(400);
	// 		color: #ec1847;
	// 	}

	// 	&__data {
	// 		padding-top: 6vh;
	// 		padding-bottom: 4vh;
	// 	}

	// 	&__map {
	// 		position: relative;
	// 		display: flex;
	// 		width: 100vw;
	// 		margin-left: -40px;
	// 		left: 50%;
	// 		transform: translateX(-50%);
	// 		padding-left: 80px;
	// 		flex-grow: 1;
	// 		flex-shrink: 1;
	// 		flex-basis: 0;
	// 	}
	// }

	.b-slider-item--1 {
		.l-slider-item__container {
			padding-right: 0px !important;
		}

		.l-slider-item__inner {
			max-width: 100%;
		}

		.b-slider-item__content {
			padding: 0;
			height: 100%;
		}

		.b-sub-slider-wrapper {
			height: 100%;
		}
	}

	.b-slider-item--2 {
		.l-slider-item__inner {
			display: flex;
			flex-direction: column;
		}

		.b-slide-link-wrapper {
			align-self: flex-end;
			margin-bottom: 19px;
			margin-left: 58px;
		}
	}

	.b-slider-item--3 {
		.b-slide-icons__col {
			justify-content: center;
			text-align: center;

			&:last-child {
				transform: none;
			}

			[data-svg] {
				transform: translateX(-18%);
			}
		}

		.b-slide-icons__image {
			text-align: center;
			// padding-right: 16px;

			img {
				width: 100%;
				// max-width: 595px;
			}
		}

	}

	.b-slider-item--4 {
		.b-slide-icons__col {
			justify-content: center;
		}

		.b-slide-icons__image {
			text-align: center;
			padding-right: 16px;

			img {
				// max-width: initial;
				// width: 123%;
				// margin-left: -23%;
				width: 100%;
				max-width: 540px;
			}
		}
	}

	// .b-slider-item--5 {
	// 	background-color: #404041;

	// 	.b-slider-item__content {
	// 		height: 100%;
	// 	}
	// }

	.is-animated {
		.brochure-link {
			opacity: 1;
			transform: translateY(0%);
		}

		.b-slide-news {
			transform: translateX(0%);

			&__image {
				img {
					transform: translateY(0%);
				}
			}

			&__title {
				span {
					transform: translateY(0%);
				}
			}
		}

		.b-slide-text {
			&:before {
				opacity: 1;
			}

			&__title-part--main {
				opacity: 1;
			}

			&__title-part--sup {
				opacity: 1;
				transform: translateX(0) !important;

				// span {
				// 	transform: translateX(0%) !important;
				// }
			}

			&__side:before {
				transform: translateY(-50%) scaleY(1);
			}

			&__text,
			.b-slide-link-wrapper {
				opacity: 1;
			}
		}

		.b-slide-key {
			&__title {
				&:after {
					transform: scaleX(1);
					transition: transform 1s ease;
					transition-delay: 0.35s;
				}
			}

			&__title-inner {
				opacity: 1;
				transition: opacity 1s linear;
			}

			.row {
				&:first-child {
					opacity: 1;
					transform: translateY(0%);
					transition: opacity 0.9s linear, transform 0.9s ease;
					transition-delay: 1s;
				}

				&:last-child {
					opacity: 1;
					transform: translateY(0%);
					transition: opacity 0.9s linear, transform 0.9s ease;
					transition-delay: 1.3s;
				}
			}

			.b-slide-link-wrapper {
				opacity: 1;
				transition: opacity 0.8s linear;
				transition-delay: 0.9s;
			}
		}

		.b-slide-icons {
			&__title {
				opacity: 1;
				transition: opacity 1s linear;
			}

			&__col {
				opacity: 1;
				transform: translateY(0%);
				transition: opacity 0.9s linear, transform 0.9s ease;

				@for $i from 1 through 9 {
					&:nth-child(#{$i}) {
						transition-delay: (0.1s * $i) + 0.2s;
					}
				}
			}

			&__image {
				width: 50%;

				img {
					opacity: 1;
					transform: translateY(0%);
					transition: opacity 0.9s linear, transform 0.9s ease;
					transition-delay: 0.2s;
				}
			}
		}

		.b-slide-icons._linked .b-slide-icons__link-wrap {
			opacity: 1;
		}
	}

	@media (max-height: 750px) {
		// .b-slide-contacts {
		// 	&__map {
		// 		display: none;
		// 	}
		// }

		// .b-slider-item--5 {
		// 	.b-slider-item__content {
		// 		// height: auto;
		// 	}
		// }
	}

	@media (max-height: 620px) {
		.l-layout-wrapper .b-slider-pagination__item:not(:last-child) {
			margin-bottom: 6.5vh;
		}
	}

	@media (max-height: 490px) {
		.b-slider-item--2 {
			.b-slide-key__title-inner {
				br {
					display: none;
				}
			}
		}

		.b-slider-item--3 {
			.b-slide-icons__title {
				br {
					display: none;
				}
			}
		}
	}

	@media (max-width: 1600px) {
		.l-slider-item__container,
		.l-sub-slider-item__container {
			padding-right: 50px;
		}

		.l-layout-wrapper .l-slider-pagination-wrapper {
			right: calc(25px + 12px);
		}
	}

	@media (max-width: 1440px) {
		.b-slide-icons._linked {
			.b-slide-icons__link-wrap {
				padding: 3vw 3vw 3vw 2.5vw;
			}
		}

		.brochure-link {
			font-size: 16px;
			bottom: 3vh;
		}
	}

	@media (max-width: 1260px) {
		.b-slide-icons._linked {
			.b-slide-icons__link-wrap {
				padding: 0 2vw 0 0;
			}
		}

		.b-slide-text {
			&__title {
				min-width: 53vw;
			}

			&__title-part--main {
				font-size: 10vw;
			}

			&__title-part--sup {
				font-size: 3vw;
			}

			&__text {
				padding-right: 0;

				p {
					font-size: 18px;
				}
			}

			&__side {
				margin-left: 2.1vw;
				padding-left: 2.1vw;
			}
		}

		.b-sub-slider-item--2 .b-slide-text__title-part--main {
			font-size: 6.5vw;
		}

		.b-slide-key {
			.b-indicator {
				&__value {
					// font-size: 8vw;
					font-size: 3em;
				}

				&__unit {
					font-size: 3vw;
				}

				&__text {
					font-size: 17px;
				}
			}

			&__indicators-wrapper {
				margin-top: 1vh;
			}

			.row + .row {
				margin-top: 8vh;
			}
		}

		.b-slider-item--3 {
			.b-slide-icons__col {
				[data-svg] {
					transform: scale(0.9) translateX(-18%);
				}
			}
		}

		.b-slider-item--4 {
			.b-slide-icons__col {
				[data-svg] {
					transform: scale(0.9);
				}
			}
		}
	}

	@media (max-width: 1100px) {
		.b-slide-icons {
			&__image {
				width: 45%;
			}

			&__main-wrapper {
				width: 55%;
			}
		}

		.b-slider-item--3 {
			.b-slide-icons__col {
				[data-svg] {
					transform: scale(0.8) translateX(-18%);
				}
			}
		}

		.b-slider-item--4 {
			.b-slide-icons__col {
				[data-svg] {
					transform: scale(0.8);
				}
			}
		}
	}

	@media (max-width: 1000px) {
		.b-slide-icons {
			&__image {
				display: none;
			}

			&__main-wrapper {
				width: 100%;
			}
		}
	}

	@media (max-width: 980px) {
		// .b-slide-icons {
		// 	&__image {
		// 		display: none;
		// 	}

		// 	&__main-wrapper {
		// 		width: 100%;
		// 	}
		// }

		.brochure-link {
			font-size: 14px;
			bottom: 3vh;
		}

		// .b-slide-contacts {
		// 	flex-direction: row;

		// 	&__map {
		// 		display: block;
		// 		padding-left: 0;
		// 		margin-left: 0;
		// 		transform: none;
		// 		left: auto;
		// 		margin-right: -25px;
		// 		// margin-top: -30px;
		// 		// margin-bottom: -30px !important;
		// 		height: 100vh;
		// 	}

		// 	&__data {
		// 		padding-right: 16px;

		// 		.browser-ie & {
		// 			flex-grow: 1.15;
		// 		}
		// 	}

		// 	&__col {
		// 		flex-basis: 100%;
		// 		max-width: 100%;
		// 	}

		// 	&__form-btn {
		// 		display: inline-block;
		// 	}

		// 	.b-form--hire-us {
		// 		display: none;
		// 	}
		// }

		// .b-popup--hire-us {
		// 	display: block;
		// }
	}

	@media (min-width: 981px) {
		// .b-popup--hire-us {
		// 	display: block;
		// 	visibility: visible;
		// 	opacity: 1;
		// 	position: relative;
		// 	top: auto;
		// 	left: auto;
		// 	overflow: visible;

		// 	.b-popup__inner {
		// 		opacity: 1;
		// 		background: none;
		// 		padding: 0;
		// 		overflow: visible;
		// 		transform: none;
		// 		transition: none;
		// 	}

		// 	.b-popup__close {
		// 		display: none;
		// 	}
		// }
	}

	@include breakpoint(sm) {
		@media (max-height: 530px) {
			.hide-xsh {
				display: none;
			}

			.b-slide-icons._linked .b-slide-icons__col {
				&:nth-child(2) {
					align-items: normal !important;
				}

				@for $i from 1 through 9 {
					&:not(.hide-xsh):nth-child(#{$i}) {
						transition-delay: (0.1s * $i) + 0.2s;
					}
				}
			}
		}

		.brochure-link {
			display: none;
			// bottom: 2vh;
			// right: 2vw;
		}

		.b-slide-icons._linked {
			.b-slide-icons__link-wrap {
				padding: 0 3.5vw 0 0.8vw;
			}

			.b-slide-icons__col {
				&:nth-child(4) {
					margin-top: 0;
				}
			}
		}

		.b-circle-link {
			font-size: 18px;
		}

		.l-layout-wrapper {
			.l-page {
				padding-left: 0px;
			}

			// .l-sub-slider-pagination-wrapper {
			// 	left: 7px;
			// 	transform: none;
			// 	z-index: 110;
			// }

			.l-layout-wrapper__navigation {
				position: relative;
			}

			.l-slider-pagination-progress-wrapper .b-slider-pagination-progress-bar__circle {
				width: 36px;
				height: 36px;
			}
		}

		// .b-sub-slider {
		// 	.l-sub-slider-item__inner {
		// 		padding-left: 32px;
		// 	}
		// }

		.b-slide-text {
			flex-direction: column;

			&__title {
				// min-width: auto;
				min-width: 100%;
				width: 100%;
				flex-grow: 1;
			}

			&__title-part--main {
				font-size: 14vw;
			}

			&__title-part--sup {
				font-size: 4.7vw;
			}

			&__side {
				// margin-left: 0;
				padding-left: 0;

				&:before {
					content: none;
				}
			}

			&__text {
				display: none;
			}

			.b-slide-link-wrapper {
				margin-top: 2vh;
				transition-delay: 0.7s;
			}

			// .b-slide-link {
			// 	span {
			// 		display: block;
			// 	}
			// }
		}

		.b-sub-slider-item--2 .b-slide-text__title-part--main {
			font-size: 9.5vw;
		}

		// .b-slide-contacts {
		// 	&__map {
		// 		padding-left: 0;
		// 		margin-left: 0;
		// 		height: calc(100vh - 80px);
		// 	}
		// }

		// .b-popup--hire-us {
		// 	width: 100%;
		// 	height: calc(100% - 80px);
		// 	top: 80px;
		// 	bottom: 0;
		// }
	}

	@include breakpoint(xs) {
		.b-slide-icons._linked {
			.b-slide-icons__col:nth-child(even) {
				align-items: normal;
			}

			.b-slide-icons__outer-col._link {
				display: none;
			}
		}

		.b-circle-link._in-col {
			display: flex;
			font-size: 15px;
			margin-left: -31%;
		}

		.l-slider-item__container,
		.l-sub-slider-item__container {
			padding-right: 26px;
		}

		.l-layout-wrapper {
			.l-slider-pagination-progress-wrapper {
				.b-slider-pagination__item {
					width: 8px;
					height: 8px;
				}

				.b-slider-pagination-progress-bar {
					width: 10px;
					height: 10px;
				}

				.b-slider-pagination-progress-bar__circle {
					width: 26px;
					height: 26px;
				}
			}

			.b-slider-pagination__item:not(:last-child) {
				margin-bottom: 30px;

				@media (max-height: 440px) {
					margin-bottom: 6vh;
				}
			}

			.l-slider-pagination-wrapper {
				// width: 26px;
				right: calc(13px + 12px);
			}
		}

		.b-slide-key {
			&__title-inner {
				font-size: 8vw;
			}

			.b-indicator {
				&__value {
					font-size: 11vw;
				}

				&__unit {
					font-size: 7vw;
				}

				&__text {
					font-size: 15px;
				}
			}
		}

		.b-slide-icons {
			&__title {
				font-size: 8vw;
				margin-bottom: 5vh;
			}

			&__col {
				&:nth-child(3) {
					margin-top: 2.9vh;
				}

				@for $i from 1 through 9 {
					&:not(.hide-xs):nth-child(#{$i}) {
						transition-delay: (0.1s * $i) + 0.2s;
					}
				}

				&._linked {
					align-items: center;
				}
			}
		}

		// .b-slide-contacts {
		// 	&__map {
		// 		display: none;
		// 	}

		// 	&__info-address {
		// 		a {
		// 			display: inline;
		// 		}
		// 	}
		// }
	}

	@media (max-width: 360px) {
		.b-circle-link._in-col {
			margin-left: -14%;
		}
	}
}
