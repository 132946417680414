.terms-wrapper {
	@include font(300);

	p {
		font-size: 20px;
	}

	h2 {
		margin-top: 0;
		font-weight: 400;
		font-size: 1.83333rem;
	}

	h3 {
		margin-top: 10px !important;
		margin-bottom: 0.43em;
		font-weight: 400;
	}
}
