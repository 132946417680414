$copyright_base--color: $color-white;
$copyright_base--link-color: $color-white;
$copyright_base--link-color-hover: $color-white;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;
	@include font-size(14px);

	&__name {
		display: block;
	}

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;

		&:visited {
			color: $copyright_base--link-color;

			&:hover {
				color: $copyright_base--link-color-hover;
			}
		}

		&:hover,
		&:active,
		&:focus {
			color: $copyright_base--link-color-hover;
			//text-decoration: underline;
		}
	}
}
