%text--body,
.text--body {
	@include font(400);
	@include font-size($base-font-size);
}

%arial,
.arial {
	font-family: Arial, Helvetica, sans-serif;
}

%caption {
	@include font-size(16px);
	font-weight: bold;
	color: $base-text-color;
	border-bottom: 2px solid $base-text-color;

	.caption__units {
		font-weight: normal;
	}
}

// Выделенное обращение
.compellation {
	@include font-size(16px);
}

p {
	margin-bottom: 1em;
}

.terms-wrapper {
	h1 {
		font-size: 2.6rem;
	}
}

.font-300 {
	@include font(300);
}

.font-400 {
	@include font(400);
}

.font-600 {
	@include font(600);
}

.font-700 {
	@include font(700);
}
