.b-form--feedback {
	@include breakpoint(sm) {
		.b-captcha__data {
			margin-top: 1em;
		}

		.b-form__field-input--send {
			margin-top: 0.5em;
		}
	}
}
