$hire-us--base-color: $color-white;

.b-form--hire-us {
	.b-form__title {
		color: $hire-us--base-color;
		// margin-top: 20px;
		// margin-bottom: 4vh;

		@extend .header-main;
	}

	.b-form__link {
		margin-bottom: 4vh;
		color: #6d6e70;
		@include font-size(20px);

		a {
			@include font(600);
			color: #ec1847 !important;
			border-bottom: 1px solid #ec1847;
			transition: 0.3s ease;
			text-decoration: none;

			&:hover,
			&:focus {
				border-bottom-color: transparent;
			}
		}
	}

	.b-form__row--footer {
		width: 100%;
	}

	.b-form__fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.b-form__field {
			flex-basis: 100%;
			max-width: 100%;
		}

		.b-form__field--half {
			flex-basis: 48%;
			max-width: 48%;
		}
	}

	.b-form__field-input {
		input[type="tel"],
		input[type="email"],
		input[type="text"],
		textarea {
			border-color: $hire-us--base-color;
			color: $color-white;
		}
	}

	.b-form__field--comment {
		height: 100%;
		padding-top: 20px;

		.b-form__field-input,
		textarea {
			height: 100%;
		}

		.b-form__field-placeholder--smart {
			left: 10px;
			top: 12px;
		}
	}

	.b-form__field-input--policy {
		color: $hire-us--base-color;

		a {
			color: $hire-us--base-color;
		}

		.b-checkbox__label:before {
			border-color: $hire-us--base-color;
		}
	}

	.b-form__field-placeholder--smart,
	.b-form__field-title {
		color: $hire-us--base-color;
	}

	.b-form__field-placeholder--smart--blue span {
		color: #16BECF;
	}

	.b-form__field-placeholder--smart--yellow span {
		color: #FDB913;
	}

	.b-form__field-input--submit {
		display: flex;
		margin: 0 auto;
	}

	.b-captcha {
		&__reload-btn {
			color: $hire-us--base-color;
		}

		&__image-wrapper {
			border: 1px solid $color-white;
			padding: 1px;
		}

		// @media (min-width: 981px) and (max-width: 1400px) {
		// 	@include captchaAdaptive();
		// }

		@media (min-width: 511px) and (max-width: 981px) {
			@include captchaAdaptive();
		}

		@media (max-width: 435px) {
			@include captchaAdaptive();
		}
	}

	@include breakpoint(xs) {
		.b-form__row {
			align-items: flex-start;
		}
	}
}
