.b-search-result {
	margin-top: 2em;

	&__item {
		padding-top: $grid-gutter-width;
		@include rem(border-top-width, 1px);
		border-top-style: solid;

		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
	}

	&__title {
		@include font-size(18px);
	}

	// &__link {
	// 	color: inherit;
	// 	text-decoration: inherit;

	// 	&:hover {
	// 		text-decoration: underline;
	// 	}
	// }
	
	&__loading-trigger {
		width: 100%;
		
		&.is-finidhed {
			display: none;
		}
		
		&.is-loading {
			height: 40px;
		}
	}
}

@media (max-width: 798px) {
	.b-search-result {
		.b-search-result__item {
			.b-search-result__title {
				font-size: 18px;
			}	
		}
	}
}
