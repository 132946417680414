.header-1 {
	@include font-size(60px);
	margin: 0;
	line-height: 1;
	@include font(600);
	color: $color-aubergine;

	.body--interactive-reports-products {
		color: $color-white;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font-size(26px);
	margin-bottom: 0.53em;

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font-size(24px);
	margin-bottom: 0.83em;
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font-size(20px);
	margin-bottom: 1em;
}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font-size(18px);
	margin-bottom: 1em;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
}

h6 {
	@extend .header-6;
}

.header-main {
	@include font(600, 'FuturaNew');
	line-height: 1.3;
	margin-bottom: 0.5em;
	@include font-size(70px);

	@media screen and (max-width: 1440px) {
		@include font-size(50px);
	}

	@include breakpoint(xs) {
		@include font-size(40px);
	}
}
