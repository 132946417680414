.body--survey {
	background-color: #f6f6f8;

	.l-layout-wrapper--1 {
		padding-top: 0;
		max-width: 100%;

		.l-page {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	.l-page__content {
		padding: 0;
	}

	.l-layout-wrapper__navigation {
		display: none;
	}

	.b-up-button--side {
		display: none !important;
	}

	.survey {
		display: flex;
		flex-direction: column;
		height: 100vh;
		// height: 100svh;

		&__top {
			background-color: #fff;
			flex-grow: 1;
			box-shadow: 0 0 6px 0px #d9d9d9;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				display: block;
				height: auto;
				// max-width: 80vw;
			}
		}

		&__bottom {
			flex-grow: 1;
			max-height: 34vh;
			text-align: center;
		}

		&__text {
			@include font-size(34px);
			max-width: em(670, 34);
			padding-top: 1em;
			width: 74vw;
			display: inline-block;
		}

		@include breakpoint(xs) {
			&__text {
				font-size: 24px;
			}
		}
	}
}
