$menu--font-size: 13px;
$menu--font-color: #343434;
$menu_vertical--font-size: 13px;
$menu_vertical--font-color: #343434;
$menu--list-lvl1-bg: $color-eallery;
$menu--list-lvl2-bg: $color-eallery;
$menu--list-lvl3-bg: #ededed;
$menu--list-lvl4-bg: #dcdcdc;
$menu--sub-space-bg: #aeaeae;
$menu--bg-expand-hover: #656565;
$menu--color-expand-hover: #fff;
$menu--current-bg: #343434;
$menu--current-color: #fff;
$menu--icon-color: #444444;
$menu--icon-color-hover: #fff;
$menu_horizontal--height: 50px;

.b-nav {
	position: relative;
}

.b-nav--left {
	position: relative;
	width: 100%;
}
