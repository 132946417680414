.project {
	position: relative;

	&__top-block {
		display: flex;
		flex-direction: column;

		.project-logo {
			width: 10%;
			margin-bottom: 5%;

			p {
				font-size: 42px;
				text-transform: uppercase;
				font-weight: 700;
				padding-top: 30px;
				color: #2a2b2c;
				line-height: 1.2;
			}
		}

		.project-links {
			.b-link-icon__text {
				font-size: 24px;

				a {
					text-decoration: none;
					color: #231f20;

					&:hover,
					&:active,
					&:focus {
						color: #ec1847;
					}

					&:before {
						content: none !important;
					}
				}
			}

			.b-link-icon__icon {
				max-width: 18px;
				min-width: 18px;
				margin-right: 15px;
			}
		}
	}

	&__line {
		--line-color: #fcb813;

		background: var(--line-color);
		position: relative;
		color: white;
		font-size: 18px;
		margin-top: 5%;
		margin-bottom: 5%;
		min-height: 70px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			background: var(--line-color);
			height: 100%;
			width: 100%;
			top: 0;
		}

		&:before {
			left: 100%;
		}

		&:after {
			right: 100%;
		}

		.b-link-icon {
			padding-bottom: 20px;
			padding-top: 20px;

			&__text {
				font-size: 17px;
			}

			&__icon {
				max-width: 30px;
				min-width: 30px;
				min-height: 30.5px;
				margin-right: 10px;
			}
		}
	}

	&__line-elements {
		display: flex;
		flex-wrap: wrap;

		&:not(.row) {
			margin-left: -15px;
			margin-right: 15px;

			.b-link-icon {
				margin: 0 15px;
			}
		}
	}

	&__line--orange {
		background-color: #fcb813;
	}

	&__bottom-block {
		display: flex;
		flex-direction: column;
		max-width: 45%;
	}

	p {
		font-size: 24px;
		color: #231f20;
		z-index: 1;
	}

	&__mockup {
		position: absolute;
		bottom: 0;
		top: 0;
		margin: auto;
		right: 0;
	}
}

.project--afk {
	.project-logo {
		width: 15%;
	}

	.project__line {
		--line-color: #001375;
	}

	.project__mockup {
		&--2020 {
			right: -24%;
			width: 95%;

			@media (min-width: 980px) and (max-width: 1580px) {
				margin-top: -13%;
				width: 95%;
				right: -20% !important;
			}
		}
	}

	@media (max-width: 1244px) {
		.project__bottom-block {
			max-width: 100%;
		}
	}
}

.project--rosneft {
	.project__mockup {
		@media (max-width: 1580px) {
			margin-top: 0 !important;
			top: -10%;
		}

		@media (min-width: 981px) and (max-width: 1181px) {
			margin-top: 44px;
		}
	}
}

.project--nornikel {
	.project__line {
		--line-color: #0075b2;
	}

	.project__mockup {
		&--2017 {
			right: -8%;
			width: 51%;

			@media (min-width: 980px) and (max-width: 1580px) {
				margin: 0 !important;
				width: 45%;
			}
		}

		&--2018 {
			width: 49%;
			margin-top: -30px;

			@media (min-width: 981px) and (max-width: 1054px) {
				width: 45%;
				margin-top: -25px;
			}

			@media (max-width: 800px) {
				width: 60%;
			}
		}
	}
}

.project--evraz {
	.project__line {
		--line-color: #dd4620;
	}

	.project__mockup {
		&--2017 {
			right: -12%;

			@media (min-width: 981px) and (max-width: 1200px) {
				right: -14% !important;
			}
		}

		&--2018 {
			right: 0%;
			width: 50%;

			@media (min-width: 1050px) and (max-width: 1500px) {
				margin-top: 0 !important;
				width: 40%;
			}

			@media (min-width: 980px) and (max-width: 1049px) {
				margin-top: 0 !important;
				width: 43%;
			}

			@media (max-width: 450px) {
				width: 100%;
			}
		}
	}
}

.project--sberbank {
	.project__line {
		--line-color: #36c370;
	}

	.project-logo {
		width: 14%;
	}

	.project__mockup {
		right: -12%;

		@media (min-width: 981px) and (max-width: 1084px) {
			margin-top: 0px;
			right: -2% !important;
		}
	}
}

.project--mosbirzha,
.project--mts {
	.project__line {
		--line-color: #b60102;

		&--2022 {
			--line-color: #ff0508;
		}
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			right: 0%;
			width: 56%;
		}
	}

	.project-logo {
		width: 15%;
	}
}

.project--mother-and-child {
	.project__line {
		--line-color: #e34a21;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			width: 70%;
		}

		&--2019 {
			right: -12%;
		}
	}
}

.project--gv {
	.project__line {
		--line-color: #363848;
	}

	.project__mockup {
		&--2017,
		&--2018 {
			right: -12%;
		}

		&--2020 {
			margin-top: 0;
			width: 80%;
			right: -13%!important;

			@media (min-width: 980px) and (max-width: 1580px) {
				margin-top: -60px;
			}

			@media (max-width: 980px) {
				width: 100%;
				right: auto!important;
			}
		}
	}
}

.project--mrsk-c {
	.project__line {
		--line-color: #3a7ab3;
	}

	.project-logo {
		width: 18%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
			padding-bottom: 30px;
		}

		&--2018 {
			width: 63%;
		}
	}
}

.project--mrsk-cp {
	.project__line {
		--line-color: #3a7ab3;
	}

	.project-logo {
		width: 15%;
	}

	.project__mockup {
		right: -12%;
	}
}

.project--mrsk-yuga {
	.project__line {
		&--2017 {
			--line-color: #ffdd00;
		}

		&--2018 {
			--line-color: #d1ab66;
		}
	}

	.project-logo {
		width: 13%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			right: -5%;
			width: 60%;

			@media (min-width: 980px) and (max-width: 1800px) {
				margin-top: 0;
				width: 50%;
			}
		}
	}
}

.project--x5 {
	.project__line {
		--line-color: #f47d1f;
	}

	.project-logo {
		width: 17%;
	}

	.project__mockup {
		&--2017,
		&--2018 {
			right: -12%;
		}
	}
}

.project--transneft {
	.project__line {
		--line-color: #0af;
	}

	.project-logo {
		width: 14%;
	}

	.project__mockup {
		width: 60%;

		@media (min-width: 980px) and (max-width: 1155px) {
			width: 50%;
			margin-top: 0;
		}
	}
}

.project--evrohim {
	.project__line {
		--line-color: #26c2e2;
	}
}

.project--enplus {
	.project__line {
		--line-color: #008a8b;
	}
}

.project--inarctica {
	.project__line {
		--line-color: #44a79e;
	}
}

.project--kamaz {
	.project__line {
		--line-color: #0d54a1;
	}
}

.project--sovkomflot {
	.project__line {
		--line-color: #2b3d85;
	}
}

.project--tplus {
	.project__line {
		--line-color: #65737f;
	}

	.project-logo {
		width: 7%;
	}
}

.project--phosagro-2022 {
	.project__line {
		--line-color: #004A93;
	}

	.project-logo {
		width: 12%;
	}
}

.project--fpc {
	.project__line {
		--line-color: #e21a1a;
	}

	.project-logo {
		width: 12%;

		&--2022 {
			width: 10%;
		}
	}
}

.project--element {
	.project__line {
		--line-color: #f47532;
	}

	.project-logo {
		width: 12%;
	}
}

.project--erg,
.project--enplus {
	.project__line {
		--line-color: #32373b;
	}

	.project-logo {
		width: 8%;
	}

	.project__mockup {
		right: -4%;
		width: 60%;

		@media (min-width: 980px) and (max-width: 1580px) {
			width: 50%;
		}
	}
}

.project--mrsk-cuban {
	.project-logo {
		width: 15%;
	}
}

.project--mrsk-cuban,
.project--mrsk-cp {
	.project__line {
		--line-color: #526990;
	}

	.project__mockup {
		&--2020 {
			width: 100%;
			margin-top: -8%;
			right: -20%!important;

			@media (min-width: 980px) and (max-width: 1450px) {
				width: 80%;
				margin-top: -8%;
				right: 0% !important;
			}

			@media (max-width: 980px) {
				right: auto!important;
			}
		}
	}

	.project__bottom-block {
		@media (max-width: 1450px) {
			max-width: 100%;
		}
	}
}

.project--gpn {
	.project__line {
		--line-color: #0d83b0;

		@media (min-width: 982px) and (max-width: 1200px) {
			.media-divider {
				display: block;
				width: 100%;
			}

			.col {
				flex-basis: 25%;
				max-width: 25%;
			}
		}
	}

	.project-logo {
		width: 14%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
			margin-top: 0;
			width: 58%;

			@media (min-width: 1400px) and (max-width: 1800px) {
				right: -8%;
				margin-top: 0;
				width: 51%;
			}

			@media (min-width: 1251px) and (max-width: 1400px) {
				right: -10% !important;
				margin-top: 8px;
				width: 51%;
			}

			@media (min-width: 981px) and (max-width: 1046px) {
				right: -3% !important;
				margin-top: 8px;
				width: 51%;
			}

			@media (max-width: 450px) {
				width: 100%;
			}
		}

		&--2018 {
			right: 0;
			margin-top: 0;
			width: 60%;

			@media (min-width: 980px) and (max-width: 1800px) {
				margin-top: 0;
				width: 50%;
			}

			@media (max-width: 450px) {
				width: 100%;
			}
		}
	}
}

.project--gpn-mh {
	.project__mockup {
		&--2020 {
			right: -24%!important;
			margin-top: -12%;
			width: 100%;

			@media (min-width: 1230px) and (max-width: 1530px) {
				margin-top: 0;
			}

			@media (max-width: 980px) {
				right: auto!important;
			}
		}
	}

	.project__bottom-block {

	}

	.project__line {
		--line-color: #009fe3;
	}
}

.project--vtb,
.project--otlk-era,
.project--chtpz {
	.project__line {
		&--2017 {
			--line-color: #c14ffb;
		}

		&--2018 {
			--line-color: #0af;
		}

		&--vtb-2019 {
			--line-color: #1e3a6d;
		}

		&--otlk-2019 {
			--line-color: #904b8a;
		}

		&--otlk-2021 {
			--line-color: #0C6928;
		}
	}

	.project-logo {
		width: 14%;

		&--otlk {
			width: 11%;
		}
	}

	.project__mockup {
		&--2017 {
			right: -12%;
			margin-top: 0;
		}

		&--2018 {
			width: 60%;

			@media (max-width: 450px) {
				width: 100%;
			}
		}

		&--2019 {
			right: -12%;
		}
	}
}

.project--chtpz {
	.project__line {
		&--2020 {
			--line-color: #eb564f;
		}
	}

	.project__mockup {
		&--2020 {
			width: 65%;
			margin-top: 0;

			@media (max-width: 450px) {
				width: 100%;
			}
		}
	}

	.project__bottom-block {
		@media (max-width: 1450px) {
			max-width: 100%;
		}
	}
}

.project--zarubezhneft,
.project--megafon,
.project--codd {
	.project__line {
		--line-color: #6dbf70;
	}

	.project-logo {
		width: 12%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			right: 0;
			margin-top: 0;
			width: 60%;

			@media (min-width: 980px) and (max-width: 1800px) {
				margin-top: 0;
				width: 50%;
			}

			@media (max-width: 450px) {
				margin-top: 0;
				width: 100%;
			}
		}
	}
}

.project--megafon {
	.project-logo {
		width: 20%;
	}
}

.project--lukoil {
	.project__line {
		&--2017 {
			--line-color: #f68925;
		}

		&--2018 {
			--line-color: #db2b36;
		}
	}

	.project-logo {
		width: 6%;

		&--2022 {
			width: 9%;
		}
	}

	.lukoil-text-width--2020 {
		max-width: 45%;

		@media (max-width: 980px) {
			max-width: 100%;
		}
	}

	.project__mockup {
		&--2017 {
			right: -13%;
			padding-bottom: 50px;

			@media (min-width: 981px) and (max-width: 1570px) {
				margin-top: 7px;
			}
		}

		&--2018 {
			right: 0%;
			padding-bottom: 110px;
			width: 60%;

			@media (min-width: 980px) and (max-width: 1800px) {
				margin-top: 0;
				width: 50%;
			}
		}

		&--2020 {
			width: 95%;
			right: -23%!important;

			@media (min-width: 980px) and (max-width: 1330px) {
				margin-top: -7%;
			}

			@media (max-width: 980px) {
				width: 100%;
				right: auto!important;
			}
		}
	}
}

.project--rzd {
	.project__line {
		&--2017 {
			--line-color: #4484a8;
		}

		&--2018 {
			--line-color: #e21a1a;
		}
	}

	.project-logo {
		width: 11%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;

			@media (min-width: 1060px) and (max-width: 1339px) {
				margin-top: -31px;
			}

			@media (min-width: 981px) and (max-width: 1060px) {
				margin-top: -100px;
			}
		}

		&--2018 {
			width: 50%;
			margin-top: -50px;

			@media (max-width: 980px) {
				width: 60%;
				margin-top: -10px;
			}
		}
	}
}

.project--rusgidro {
	.project__line {
		--line-color: #29b1cc;
	}

	.project-logo {
		width: 9%;
		margin-bottom: 5% !important;
	}

	.project__mockup {
		&--2017 {
			right: -12%;

			@media (min-width: 1900px) {
				padding-bottom: 40px;
			}
		}

		&--2018 {
			right: -5%;
			width: 60%;

			@media (min-width: 980px) and (max-width: 1800px) {
				margin-top: 0;
				width: 50%;
			}
		}
	}
}

.project--suek {
	.project__line {
		--line-color: #943080;

		&--2022 {
			--line-color: #FFDD00;
		}
	}

	.project-logo {
		width: 13%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;

			@media (min-width: 981px) and (max-width: 1054px) {
				margin-top: -79px;
			}

			@media (min-width: 1054px) and (max-width: 1215px) {
				margin-top: -42px;
			}
		}

		&--2018 {
			width: 50%;
			margin-top: -50px;

			@media (max-width: 980px) {
				width: 60%;
				margin-top: -10px;
			}
		}
	}
}

.project--transkonteiner,
.project--russian-post,
.project--scf,
.project--phosagro,
.project--default {
	.project__line {
		--line-color: #26c2e2;
	}

	.project-logo {
		width: 12%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			width: 60%;
			margin-top: -50px;

			@media (max-width: 980px) {
				width: 60%;
				margin-top: -10px;
			}
		}

		&--2019 {
			width: 60%;

			@media (max-width: 980px) {
				width: 60%;
				margin-top: -10px;
			}
		}
	}
}

.project--fpk,
.project--fgc {
	.project__line {
		--line-color: #fa9c20;
	}

	.project-logo {
		width: 18%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			width: 60%;
			margin-top: -50px;

			@media (max-width: 980px) {
				width: 60%;
				margin-top: -10px;
			}
		}
	}
}

.project--mosgortrans {
	.project__line {
		--line-color: #f47e36;
	}

	.project-logo {
		width: 10%;
	}

	.project__mockup {
		right: -12%;
	}
}

.project--lenenergo {
	.project__line {
		--line-color: #4aa5b7;
	}

	.project-logo {
		width: 18%;
	}

	.project__mockup {
		&--2017 {
			right: -12%;
		}

		&--2018 {
			width: 60%;
			margin-top: -30px;

			@media (max-width: 800px) {
				width: 60%;
			}
		}
	}
}

.project--mvideo {
	.project__line {
		--line-color: #74c044;
	}

	.project-logo {
		width: 14%;
	}

	.project__mockup {
		width: 60%;

		@media (min-width: 980px) and (max-width: 1155px) {
			width: 50%;
			margin-top: 0;
		}
	}
}

.project--mvideo-2023 {
	.project__line {
		--line-color: #ff0400;
	}

	.project-logo {
		width: 14%;
	}
}

.project--lukoil-2023 {
	.project__line {
		--line-color: #ed1b34;
	}
}

.project--lsr-2023 {
	.project__line {
		--line-color: #E01D42;
	}
}

.project--kaspersky-2023 {
	.project__line {
		--line-color: #00AA8E;
	}
}

.project--inarctica-2023 {
	.project__line {
		--line-color: #001235;
	}
}

.project--evrohim-2023 {
	.project__line {
		--line-color: #053768;
	}
}

.project--globalports-2023 {
	.project__line {
		--line-color: #ED1D24;
	}
}

.project--gazprom-neft-2023 {
	.project__line {
		--line-color: #0079C0;
	}

	.project-logo {
		width: 7%;
	}
}

.project--gazprom-2023 {
	.project__line {
		--line-color: #0079C0;
	}

	.project-logo {
		width: 7%;
	}
}

.project--vtb-2023 {
	.project__line {
		--line-color: #0A9BD8;
	}

	.project-logo {
		width: 10%;
	}
}

.project--aeroflot-2023 {
	.project__line {
		--line-color: #0A5095;
	}
}

.project--a101-2023 {
	.project__line {
		--line-color: #e8363d;
	}

	.project-logo {
		width: 4%;
	}
}

.project--beline-2023 {
	.project__line {
		--line-color: #ffd90e;
	}
}

.project--domrf-bank-2023 {
	.project__line {
		--line-color: #30454f;
	}
}

.project--domrf-2023 {
	.project__line {
		--line-color: #30454f;
	}

	.project-logo {
		width: 4%;
	}
}

.project--nlmk-2023 {
	.project__line {
		--line-color: #0054A6;
	}

	.project-logo {
		width: 8%;
	}
}

.project--moex-2023 {
	.project__line {
		--line-color: #FF0508;
	}
}

.project--nornickel-2023 {
	.project__line {
		--line-color: #005FA1;
	}
}

.project--okey-2023 {
	.project__line {
		--line-color: #ED1C24;
	}

	.project-logo {
		width: 8%;
	}
}

.project--rzd-2023 {
	.project__line {
		--line-color: #E21A1A;
	}

	.project-logo {
		width: 6%;
	}
}

.project--rzd-ua-2023 {
	.project__line {
		--line-color: #E21A1A;
	}

	.project-logo {
		width: 12%;
	}
}

.project--raspadskaya-2023 {
	.project__line {
		--line-color: #003b71;
	}
}

.project--rosneft-2023 {
	.project__line {
		--line-color: #FFD200;
	}

	.project-logo {
		width: 6%;
	}
}

.project--rosseti-2023 {
	.project__line {
		--line-color: #005b9c;
	}

	.project-logo {
		width: 9%;
	}
}

.project--sber-2023 {
	.project__line {
		--line-color: #21a038;
	}

	.project-logo {
		width: 9%;
	}
}

.project--t1-2023 {
	.project__line {
		--line-color: #26B3E8;
	}

	.project-logo {
		width: 5%;
	}
}

.project--sovcombank-2023 {
	.project__line {
		--line-color: #213A8B;
	}

	.project-logo {
		width: 12%;
	}
}

.project--fpc-2023 {
	.project__line {
		--line-color: #ED3424;
	}

	.project-logo {
		width: 25%;
	}
}

.project--evraz-2023 {
	.project__line {
		--line-color: #F47F28;
	}

	.project-logo {
		width: 9%;
	}
}

.project--fesco-2023 {
	.project__line {
		--line-color: #104C8E;
	}

	.project-logo {
		width: 11%;
	}
}

.project--ural-steel-2023 {
	.project__line {
		--line-color: #f15e2d;
	}
}

.project--phosagro-2023 {
	.project__line {
		--line-color: #2b91a4;
	}

	.project-logo {
		width: 5%;
	}
}

.project--element-2023 {
	.project__line {
		--line-color: #E83400;
	}
}

.project--posi-2023 {
	.project__line {
		--line-color: red;
	}

	.project-logo {
		width: 15%;
	}
}

.project--expert-ra-2023 {
	.project__line {
		--line-color: #ff1a22;
	}

	.project-logo {
		width: 12%;
	}
}

.project--vk-2023 {
	.project__line {
		--line-color: #0077ff;
	}

	.project-logo {
		width: 3%;
	}
}

.project--ncsp,
.project--nlmk {
	.project__line {
		--line-color: #3466ba;
	}

	.project-logo {
		width: 12%;
	}

	.project__mockup {
		right: -12%;
	}
}

.project--rostelecom {
	.project__line {
		--line-color: #4aa5b7;
	}

	.project-logo {
		width: 15%;
	}

	.project__mockup {
		right: -6%;
		width: 65%;
		margin-top: 0;

		@media (min-width: 980px) and (max-width: 1055px) {
			width: 50%;
		}
	}
}

.project--inteko {
	.project-logo {
		width: 15%;

		@media (max-width: 980px) {
			width: 30%!important;
		}

		@media (max-width: 600px) {
			width: 50%!important;
		}
	}

	.project__line {
		--line-color: #4764b0;
	}

	.project__mockup {
		&--2020 {
			width: 100%;
			right: -23%!important;
			margin-top: -10%;
		}

		@media (max-width: 980px) {
			right: auto!important;
		}
	}
}

.project--interrao {
	.project__line {
		--line-color: #3a7ab3;
	}

	.project__mockup {
		right: -10%;

		@media (min-width: 980px) and (max-width: 1580px) {
			margin-top: 0;
			width: 50%;
		}
	}
}

.project--rusagro {
	.project__line {
		--line-color: #9a9a9a;
	}

	.project-logo {
		width: 14%;
	}

	.project__mockup {
		right: -5%;
		width: 60%;

		@media (min-width: 980px) and (max-width: 1800px) {
			margin-top: 0;
			width: 52%;
		}
	}
}

.project--open {
	.project__line {
		--line-color: #26c2e2;
	}

	.project-logo {
		width: 18%;
	}

	.project__mockup {
		width: 60%;
		margin-top: -50px;

		@media (max-width: 980px) {
			width: 60%;
			margin-top: -10px;
		}
	}
}

.project--ovk {
	.project__line {
		--line-color: #39607a;
	}
}

.project--mrsk-sib {
	.project__line {
		--line-color: #3a7ab3;
	}

	.project-logo {
		width: 15%;
	}

	.project__mockup {
		right: -4%;

		@media (min-width: 980px) and (max-width: 1580px) {
			margin-top: 0;
			width: 50%;
		}
	}
}

.project--novikombank {
	.project__line {
		--line-color: #0af;
	}

	.project-logo {
		width: 15%;
	}

	.project__mockup {
		right: -6%;
		width: 65%;
		margin-top: 0;

		@media (min-width: 980px) and (max-width: 1155px) {
			width: 50%;
		}
	}
}

.project--kubanenergo,
.project--kazakhtelecom,
.project--kmg {
	.project__line {
		--line-color: #ffdd00;

		&--kazakhtelecom-2019 {
			--line-color: #8d20a8;
		}

		&--kmg-2019 {
			--line-color: #8cc06a;
		}
	}

	.project-logo {
		width: 17%;

		&--kazakhtelecom {
			width: 10%;
		}

		&--kmg {
			width: 17%;
		}
	}

	.project__mockup {
		right: -5%;
		width: 70%;

		@media (min-width: 980px) and (max-width: 1800px) {
			margin-top: 0;
			width: 50%;
		}

		@media (max-width: 450px) {
			width: 100%;
		}
	}
}

.project--kmg {
	.project__mockup {
		&--2020 {
			right: -26%!important;
			width: 100%;

			@media (max-width: 1024px) {
				right: -10% !important;
				width: 85%;
				margin-top: -14%;
			}

			@media (max-width: 980px) {
				right: auto!important;
			}
		}
	}

	.kmg-text-width--2020 {
		max-width: 45%;

		@media (max-width: 1024px) {
			max-width: 100%;
		}
	}
}

.project--magnit,
.project--ovk {
	.project-logo {
		width: 17%;
	}

	.project__line {
		--line-color: #e30613;
	}

	.project__mockup {
		right: -5%;
		width: 60%;

		@media (min-width: 980px) and (max-width: 1800px) {
			margin-top: 0;
			width: 60%;
		}
	}
}

.project--rvc {
	.project__line {
		--line-color: #5986aa;
	}

	.project-logo {
		width: 11%;
	}

	.project__mockup {
		&--2018 {
			right: 0%;
			width: 55%;
		}

		&--2017 {
			right: -12%;
		}
	}
}

.body--projects {
	--projects-bg: #F7F7F7;
	// background-image: url(https://cdn.zebra-group.ru/images/content/project-bg.png);
	// background-size: cover;
	//background-repeat: no-repeat;
	background-color: var(--projects-bg);

	.l-navigation-panel__item--link-projects {
		display: block;
		background: #ec1847;
		border-radius: 50%;
		height: 50px;
		width: 50px;
		text-decoration: none;
		position: absolute;
		z-index: 1;
		top: 27%;
		right: -24px;
		@include webfont-icon($webfont-icon--arrow-back);

		&:before {
			font-size: 20px;
			position: absolute;
			margin: auto;
			top: 15px;
			left: 0;
			right: 0;
			color: $color-white;
		}

		&:hover {
			background-color: #cc0934;
		}
	}
}

.project--2023 {
	.project__top-block .project-logo p {
		font-size: 48px;
	}

	.project__line {
		// --line-color: #041370;

		background: linear-gradient(90deg, var(--line-color), transparent 40%);

		&:before {
			content: none;
		}
	}

	.project__mockup {
		position: fixed;
		top: calc(var(--layout-pt) * -1);
		right: 0;
		bottom: -200px;
		width: clamp(600px, 60%, 1358px);
		z-index: -1;

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	background: linear-gradient(90deg, var(--projects-bg) 15%, transparent 50%);
		// }
	}

	.project__mockup-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top left;
	}

	.project__bottom-block {
		max-width: 40%;
	}
}

// Adaptive

@media (min-width: 1921px) {
	.project--2023 .project__mockup {
		position: absolute;
		top: 0;
		bottom: unset;
	}
}

@media (max-width: 1800px) {
	.project {
		&__mockup {
			width: 65%;
		}
	}

	.project--mrsk-c {
		.project__mockup {
			padding-bottom: 0;
		}
	}

	.project--lukoil {
		.project__mockup {
			padding-bottom: 0;
		}
	}
}

@media (max-width: 1600px) {
	.body--projects {
		.l-layout-wrapper--1 {
			overflow-x: hidden;
		}
	}

	.project--2023 .project__mockup {
		top: 0;
		bottom: 0;
	}
}

@media (max-width: 1580px) {
	.project {
		&__mockup {
			right: 0 !important;
			width: 50%;
		}
	}

	.project--vtb {
		.project__mockup {
			width: 60vw;
		}
	}

	.project--mrsk-c {
		.project__mockup {
			padding-bottom: 0;
		}
	}

	.project--lukoil {
		.project__mockup {
			padding-bottom: 0;
		}
	}
}

@media (max-width: 1200px) {
	.project {
		&__mockup {
			width: 55%;
		}
	}

	.project--mrsk-c {
		.project__mockup {
			padding-bottom: 0;
		}
	}

	.project--lukoil {
		.project__mockup {
			padding-bottom: 0;
		}
	}
}

@media (max-width: 980px) {
	.project {
		&__mockup {
			width: 100%;
			right: auto !important;
			position: relative;
		}

		&__bottom-block {
			max-width: 100%;
		}

		.gv-text-width {
			max-width: 100%;
		}

		.project-logo {
			width: 100%;
			max-width: 230px;
		}

		.col-2 {
			max-width: 50%;
			flex-basis: 50%;
		}

		&--gpn,
		&--mosgortrans,
		&--evraz,
		&--mother-and-child {
			.project-logo {
				max-width: 220px;
			}
		}

		&--lukoil,
		&--enplus,
		&--erg,
		&--interrao {
			.project-logo {
				max-width: 90px;
			}
		}

		&--ncsp,
		&--rosneft {
			.project-logo {
				max-width: 210px;
			}
		}

		&--nornikel,
		&--rzd,
		&--rvc,
		&--gv,
		&--transneft,
		&--rostelecom,
		&--novikombank,
		&--magnit,
		&--kubanenergo {
			.project-logo {
				max-width: 190px;
			}
		}

		&--rusgidro,
		&--rusagro,
		&--open,
		&--mrsk-sib {
			.project-logo {
				max-width: 135px;
			}
		}
	}

	.project--2023 {
		.project__top-block .project-logo p {
			font-size: 24px;
		}

		.project__line {
			background: var(--line-color);
			width: calc(100% + var(--page-pl) + var(--page-pr));
			margin-left: calc(var(--page-pl) * -1);
			margin-right: calc(var(--page-pr) * -1);
			padding-left: var(--page-pl);

			&:after {
				content: none;
			}
		}

		.project__mockup {
			position: relative;
			top: -45px;
			right: 0 !important;
			width: 100%;
			margin-right: calc(var(--page-pr) * -1);

			&::after {
				content: none;
			}
		}

		.project__bottom-block {
			max-width: unset;
		}
	}

	.project--mrsk-c {
		.project__mockup {
			padding-bottom: 0;
		}
	}

	.project--lukoil {
		.project__mockup {
			padding-bottom: 0;
		}
	}
}

@media (max-width: 1024px) {
	.browser-ie {
		.body--projects {
			.l-page__content {
				width: 100%;
			}
		}
	}
}

.rvc-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.rostelecom-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.gpn-text-width {
	&--2017,
	&--2018 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}

	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.gv-text-width {
	&--2018 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.evraz-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}

	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.zarubezhneft-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}

	&--2018 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}
}

.interrao-text-width {
	&--2018 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}

	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.kubanenergo-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.kazakhtelecom-text-width,
.kmg-text-width {
	&--2018 {
		@media (max-width: 1200px) {
			max-width: 100%;
		}
	}
}

.rzd-u-text-width {
	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.lukoil-text-width {
	&--2018 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.lenenergo-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.magnit-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.mosbirzha-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.mosgortrans-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.mvideo-text-width {
	&--2018 {
		@media (max-width: 1155px) {
			max-width: 100%;
		}
	}
}

.mrsk-sib-text-width {
	&--2018 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.novikombank-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.nornickel-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.open-text-width {
	&--2018 {
		@media (max-width: 1150px) {
			max-width: 100%;
		}
	}
}

.rosneft-text-width {
	&--2018 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.rzd-text-width {
	&--2018 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.rusgidro-text-width {
	&--2018 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.rusagro-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.transneft-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.enplus-text-width,
.erg-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.suek-text-width {
	&--2017 {
		max-width: 40%;

		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}

	&--2018 {
		max-width: 60%;

		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}

	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.mrsk-yuga-text-width {
	max-width: 50%;

	&--2017 {
		max-width: 53%;

		@media (max-width: 980px) {
			max-width: 100%;
		}
	}

	&--2018 {
		max-width: 60%;

		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.zarubezhneft-text-width {
	max-width: 54%;

	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

.fpk-text-width {
	&--2017 {
		max-width: 53%;

		@media (max-width: 980px) {
			max-width: 100%;
		}
	}

	&--2018 {
		max-width: 60%;

		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2019 {
		max-width: 40%;

		@media (max-width: 1130px) {
			max-width: 100%;
		}
	}
}

.transkonteiner-text-width,
.russian-post-text-width {
	&--2017 {
		max-width: 44%;

		@media (max-width: 980px) {
			max-width: 100%;
		}
	}

	&--2018 {
		max-width: 60%;

		@media (max-width: 1130px) {
			max-width: 100%;
		}
	}

	&--2019 {
		max-width: 50%;

		@media (max-width: 1130px) {
			max-width: 100%;
		}
	}
}

.kazakhtelecom-text-width,
.kmg-text-width,
.rusagro-text-width {
	&--2019 {
		@media (max-width: 1800px) {
			max-width: 100%;
		}
	}
}

.x5-text-width {
	&--2018 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}

	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.mother-and-child-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.mrsk-cp-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.ncsp-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.sberbank-text-width {
	&--2017 {
		@media (max-width: 1580px) {
			max-width: 100%;
		}
	}
}

.vtb-text-width,
.mother-and-child-text-width,
.megafon-text-width,
.mosbirzha-text-width,
.mrsk-c-text-width,
.mrsk-cp-text-width,
.mts-text-width,
.nlmk-text-width,
.nmtp-text-width,
.otlk-era-text-width,
.russian-post-text-width,
.rzd-text-width,
.rosneft-text-width,
.mrsk-yuga-text-width,
.scf-text-width,
.fgk-text-width,
.fpc-text-width,
.phosagro-text-width,
.codd-text-width,
.chtpz-text-width,
.enplus-text-width,
.default-text-width {
	&--2019 {
		@media (max-width: 1600px) {
			max-width: 100%;
		}
	}
}

@media (max-width: 939px) and (min-width: 930px) {
	.project--vtb {
		.project__mockup {
			right: -41px !important;
		}
	}
}

@media (max-width: 519px) {
	.project--vtb {
		.project__mockup {
			width: 83vw;
		}
	}
}

@include breakpoint(md) {
	.project__top-block {
		.project-logo {
			p {
				font-size: 19px;
			}
		}
	}
}

@media (min-width: 789px) and (max-width: 1250px) {
	.project--gpn {
		.project-logo {
			p {
				font-size: 30px;
			}
		}
	}
}

@media (min-width: 981px) and (max-width: 1580px) {
	.project--vtb {
		.project__mockup {
			width: 55vw;
			right: -9% !important;
			top: 30%;
		}
	}
}

.project__mockup--2019 {
	right: -12%;
	width: 70%;

	@media (max-width: 1600px) {
		margin-top: 0;
		right: -12% !important;
	}

	@media (max-width: 600px) {
		width: 100% !important;
		right: 0 !important;
	}
}

.project--vtb {
	.project__line {
		&--vtb-2020 {
			background: #1e3a6d;

			&:before,
			&:after {
				background: #1e3a6d;
			}
		}
	}

	.project__mockup {
		&--2020 {
			width: 80%;
			right: -12%;

			@media (min-width: 1055px) and (max-width: 1600px) {
				top: -15%;
				width: 80%;
			}

			@media (min-width: 981px) and (max-width: 1120px) {
				top: -60%;
				width: 70%;
				right: -15% !important;
			}

			@media (max-width: 450px) {
				right: auto;
			}
		}

		&--2021 {
			width: 80%;
			right: -12%;

			@media (max-width: 450px) {
				width: 100%;
				right: auto;
			}
		}
	}
}

.project {
	&--codd {
		.project__mockup {
			&--2019 {
				width: 70%;
				right: -7%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}
		}
	}

	&--mosbirzha {
		.project__mockup {
			&--2019 {
				width: 65%;
				right: -12%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2018 {
				@media (max-width: 1600px) {
					top: -25%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -35%;
				}
			}
		}
	}

	&--interrao {
		.project__mockup {
			&--2019 {
				width: 75%;
				right: -12%;

				@media (max-width: 1600px) {
					top: -20%;
				}
			}

			&--2020 {
				width: 95%;
				right: -20%;

				@media (min-width: 980px) and (max-width: 1054px) {
					right: -10% !important;
				}

				@media (min-width: 980px) and (max-width: 1580px) {
					width: 78%!important;
				}
			}
		}
	}

	&--evraz {
		.project__mockup {
			&--2020 {
				right: -21%!important;
				width: 100%;

				@media (min-width: 981px) and (max-width: 1600px) {
					right: -7% !important;
					width: 75%;
					top: -35%;
				}

				@media (max-width: 980px) {
					right: auto!important;
				}
			}

			&--2019 {
				right: -12%;
				width: 70%;

				@media (min-width: 981px) and (max-width: 1054px) {
					right: -15% !important;
				}

				@media (max-width: 1600px) {
					top: -5%;
				}
			}

			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -40%;
				}
			}
		}
	}

	&--fgc {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 70%;

				@media (min-width: 1054px) and (max-width: 1600px) {
					top: -20%;
				}

				@media (min-width: 981px) and (max-width: 1054px) {
					top: -10%;
				}
			}
		}
	}

	&--fpk {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 70%;

				@media (min-width: 1054px) and (max-width: 1600px) {
					top: -20%;
				}

				@media (min-width: 981px) and (max-width: 1054px) {
					top: -10%;
				}
			}

			&--2018 {
				@media (max-width: 1600px) {
					top: -15%;
				}
			}
		}
	}

	&--gpn {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 71%;

				@media (max-width: 1100px) {
					top: -10%;
				}
			}

			&--2020 {
				right: -16%!important;
				width: 93%;

				@media (max-width: 1600px) {
					margin-top: -10%!important;
				}

				@media (max-width: 980px) {
					right: auto!important;
				}
			}
		}
	}

	&--kubanenergo {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}
		}
	}

	&--lenenergo {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -50%;
				}
			}
		}
	}

	&--magnit {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}
		}
	}

	&--megafon {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 75%;

				@media (min-width: 1200px) and (max-width: 1600px) {
					top: -20%;
				}

				@media (max-width: 1199px) {
					top: -10%;
				}
			}
		}
	}

	&--mts {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 71%;
				top: -20%;

				@media (max-width: 1600px) {
					right: -7% !important;
					top: -10%;
				}

				@media (max-width: 1054px) {
					top: -17%;
				}
			}
		}
	}

	&--nlmk {
		.project__mockup {
			&--2019 {
				right: -9%;
				width: 71%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}
		}
	}

	&--novikombank {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}
		}
	}

	&--nornikel {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 71%;
				top: -15%;
			}

			&--2017 {
				@media (min-width: 981px) and (max-width: 1054px) {
					top: -5%;
				}
			}
		}
	}

	&--scf {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 70%;

				@media (min-width: 1054px) and (max-width: 1600px) {
					top: -15%;
				}

				@media (min-width: 981px) and (max-width: 1054px) {
					top: -10%;
				}
			}
		}
	}

	&--russian-post {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 70%;
				top: -20%;

				@media (max-width: 1600px) {
					right: -7% !important;
					top: -13%;
				}

				@media (max-width: 1480px) {
					top: -10%;
				}
			}
		}
	}

	&--mrsk-yuga {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 60%;

				@media (max-width: 1600px) {
					right: -7% !important;
				}
			}

			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}
		}
	}

	&--rusagro {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 65%;

				@media (max-width: 1600px) {
					right: -7% !important;
				}
			}
		}
	}

	&--rzd {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 70%;

				@media (max-width: 1600px) {
					top: -12%;
				}
			}

			&--2017 {
				@media (min-width: 1340px) and (max-width: 1580px) {
					top: -25%;
				}
			}
		}
	}

	&--suek {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 70%;

				@media (max-width: 1600px) {
					top: -10%;
				}
			}

			&--2017 {
				@media (min-width: 1216px) and (max-width: 1580px) {
					top: -25%;
				}
			}
		}
	}

	&--zarubezhneft {
		.project__mockup {
			&--2019 {
				width: 71%;

				@media (min-width: 981px) and (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -50%;
				}
			}
		}
	}

	&--mother-and-child {
		.project__mockup {
			&--2019 {
				width: 71%;

				@media (min-width: 981px) and (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2017 {
				@media (min-width: 981px) and (max-width: 1600px) {
					top: -20%;
				}

				@media (min-width: 1055px) and (max-width: 1200px) {
					top: -40%;
					right: -10% !important;
				}
			}
		}
	}

	&--mrsk-cp {
		.project__mockup {
			&--2019 {
				width: 71%;
				top: -9%;

				@media (min-width: 1481px) and (max-width: 1600px) {
					top: -15%;
				}

				@media (max-width: 1480px) {
					top: -10%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -35%;
				}
			}
		}
	}

	&--ncsp {
		.project__mockup {
			&--2019 {
				width: 71%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -25%;
				}
			}
		}
	}

	&--chtpz {
		.project__mockup {
			&--2019 {
				width: 71%;

				@media (max-width: 1600px) {
					top: -20%;
				}
			}
		}
	}

	&--vtb {
		.project__mockup {
			&--2019 {
				width: 80%;

				@media (min-width: 981px) and (max-width: 1054px) {
					top: -25%;
				}

				@media (min-width: 1055px) and (max-width: 1600px) {
					top: -15%;
				}
			}
		}
	}

	&--phosagro {
		.project__mockup {
			&--2019 {
				width: 70%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}
		}
	}

	&--default {
		.project__mockup {
			&--2019 {
				right: -7%;
				width: 70%;

				@media (max-width: 1600px) {
					top: -17%;
				}
			}
		}
	}

	&--enplus {
		.project__mockup {
			&--2019 {
				width: 70%;

				@media (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2018 {
				@media (min-width: 981px) and (max-width: 1200px) {
					top: -50%;
				}

				@media (min-width: 1201px) and (max-width: 1600px) {
					top: -20%;
				}
			}
		}
	}

	&--erg {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -20%;
				}
			}
		}
	}

	&--rostelecom {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}
		}
	}

	&--rzd-u {
		.project__mockup {
			&--2019 {
				@media (max-width: 1600px) {
					top: -20%;
				}
			}
		}
	}

	&--rvc {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -25%;
				}
			}
		}
	}

	&--transneft {
		.project__mockup {
			&--2018 {
				@media (min-width: 1156px) and (max-width: 1600px) {
					top: -40%;
				}
			}
		}
	}

	&--lukoil {
		.project__mockup {
			&--2019 {
				@media (min-width: 1600px) and (max-width: 1800px) {
					top: -20%;
				}
			}
		}
	}

	&--mosgortrans {
		.project__mockup {
			&--2017 {
				@media (max-width: 1580px) {
					top: -35%;
				}

				@media (min-width: 1055px) and (max-width: 1200px) {
					right: -7% !important;
				}
			}
		}
	}

	&--otlk-era {
		.project__mockup {
			&--2019 {
				@media (max-width: 1600px) {
					top: -20%;
				}
			}
		}
	}

	&--rosneft {
		.project__mockup {
			&--2019 {
				@media (max-width: 1600px) {
					top: -10%;
				}
			}

			&--2018 {
				@media (min-width: 1055px) and (max-width: 1200px) {
					top: 5%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: 0;
				}
			}
		}
	}

	&--gv {
		.project__mockup {
			&--2018 {
				@media (min-width: 981px) and (max-width: 1200px) {
					top: -10%;
				}
			}
		}
	}

	&--x5 {
		.project__mockup {
			&--2018 {
				@media (max-width: 1600px) {
					top: -15%;
				}
			}

			&--2017 {
				@media (max-width: 1580px) {
					top: -35%;
				}
			}
		}
	}

	&--rusgidro {
		.project__mockup {
			&--2017 {
				@media (max-width: 1580px) {
					top: -10%;
				}
			}
		}
	}

	&--sberbank {
		.project__mockup {
			&--2017 {
				@media (min-width: 1085px) and (max-width: 1580px) {
					top: -30%;
				}
			}
		}
	}
}

.project--lenta {
	.project__line {
		background: #0003c9;

		&:before,
		&:after {
			background: #0003c9;
		}
	}
}

.project--yandex {
	.project__line {
		background: #fc3f1d;

		&:before,
		&:after {
			background: #fc3f1d;
		}
	}
}

.project--transkonteiner {
	.project__line {
		background: #024269;

		&:before,
		&:after {
			background: #024269;
		}
	}
}

.project--tmk {
	.project__line {
		background: #fe5a17;

		&:before,
		&:after {
			background: #fe5a17;
		}
	}
}

.project--okey {
	.project__line {
		background: #ED1C24;

		&:before,
		&:after {
			background: #ED1C24;
		}
	}
}

.project--mrsk-center {
	.project__line {
		background: #526990;

		&:before,
		&:after {
			background: #526990;
		}
	}
}

.project--fesco {
	.project__line {
		background: #104c8e;

		&:before,
		&:after {
			background: #104c8e;
		}
	}
}

.project--whoosh {
	.project__line {
		background: #FFB928;

		&:before,
		&:after {
			background: #FFB928;
		}
	}
}

.project--vk {
	.project__line {
		background: #0077FF;

		&:before,
		&:after {
			background: #0077FF;
		}
	}

	.project-logo {
		width: 5%;
	}
}

.project--fixprice {
	.project__line {
		background: #7EC500;

		&:before,
		&:after {
			background: #7EC500;
		}
	}
}

.project--globalports {
	.project__line {
		background: #ED1D24;

		&:before,
		&:after {
			background: #ED1D24;
		}
	}
}

.project--pt {
	.project__line {
		background: red;

		&:before,
		&:after {
			background: red;
		}
	}

	.project-logo {
		width: 15%;
	}
}

.project--x5-2021 {
	.project__line {
		background: #55b435;

		&:before,
		&:after {
			background: #55b435;
		}
	}
}

.project--aeroflot {
	.project__line {
		background: #00529B;

		&:before,
		&:after {
			background: #00529B;
		}
	}
}

.project--aeroflot-2022 {
	.project__line {
		background: #00529B;

		&:before,
		&:after {
			background: #00529B;
		}
	}

	.project-logo {
		width: 15%;
	}
}

.project--deloports {
	.project__line {
		background: #00AFF0;

		&:before,
		&:after {
			background: #00AFF0;
		}
	}
}

.project--detsky-mir {
	.project__line {
		background: #0072BC;

		&:before,
		&:after {
			background: #0072BC;
		}
	}
}

.project--lsr {
	.project__line {
		background: #E01D42;

		&:before,
		&:after {
			background: #E01D42;
		}
	}
}

.project--technomoscow {
	.project__line {
		background: #970020;

		&:before,
		&:after {
			background: #970020;
		}
	}

	.project-logo {
		width: 20%;
	}
}

.project--russian-post-2021 {
	.project__line {
		background: #0055A6;

		&:before,
		&:after {
			background: #0055A6;
		}
	}
}

.project--raspadskaya {
	.project__line {
		background: #003B71;

		&:before,
		&:after {
			background: #003B71;
		}
	}

	.project-logo {
		width: 15%;
	}
}

.project--rolf {
	.project__line {
		background: #EC2830;

		&:before,
		&:after {
			background: #EC2830;
		}
	}

	.project-logo {
		width: 10%;
	}
}

.project--rosseti-kuban {
	.project__line {
		background: #093C80;

		&:before,
		&:after {
			background: #093C80;
		}
	}
}

.project--rosseti-centr {
	.project__line {
		background: #093C80;

		&:before,
		&:after {
			background: #093C80;
		}
	}
}

.project--rosseti-cp {
	.project__line {
		background: #093C80;

		&:before,
		&:after {
			background: #093C80;
		}
	}
}

.project--samolet {
	.project__line {
		background: #007BFB;

		&:before,
		&:after {
			background: #007BFB;
		}
	}
}

.project--sovkombank {
	.project__line {
		background: #213A8B;

		&:before,
		&:after {
			background: #213A8B;
		}
	}
}

.project--trust {
	.project__line {
		background: #00446B;

		&:before,
		&:after {
			background: #00446B;
		}
	}
}

.project--yatek {
	.project__line {
		background: #213A8B;

		&:before,
		&:after {
			background: #213A8B;
		}
	}
}

.project--fpk-2021 {
	.project__line {
		background: #E42613;

		&:before,
		&:after {
			background: #E42613;
		}
	}
}

.project--interrao-2021 {
	.project__line {
		background: #002855;

		&:before,
		&:after {
			background: #002855;
		}
	}
}

.project--ink-capital-2023 {
	.project__line {
		--line-color: #006654;
	}

	.project-logo {
		width: 10%;
	}
}
