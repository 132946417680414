.body--interactive-reports-products {	
	background-color: #000;
    padding-bottom: 4vh;
    max-width: 1450px;
    margin: 0 auto;
    width: 100%;

	.h1__title {
		color: #fff;
		margin-bottom: 0.5em;
	}
}
