.body--wine-25 {
	$color-text-wine25: #0B0807;
	$color-pinot: #2F5667;
	$color-pinot-light: #4DADF7;
	$color-cabernet: #285631;
	$color-cabernet-light: #8BC2A5;

	overflow: hidden;

	&-pinot {
		.wine-25 {

			&__show-button {
				background-color: rgba($color: $color-pinot-light, $alpha: 0.4);

				&::before {
					color: $color-pinot !important;
				}
			}

			&__scroll-wrapper {

				// scrollbar-width: thin;
				// scrollbar-color: $color-pinot $color-white;

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $color-pinot;
				}

				&::-webkit-scrollbar-track {
					background-color: #2f55654d;
				}
			}

			&__text-block {
				&--icon {
					width: 45px;

					svg {
						path {
							fill: $color-pinot;
						}
					}
				}
			}

			&__about {
				&--block {
					&:nth-child(4) {
						.wine-25__about--value {
							max-width: 230px;

							@media (max-width: 440px) {
								max-width: 170px;
							}
						}
					}

					&:last-child {
						.wine-25__about--value {
							max-width: 340px;

							@media (max-width: 1600px) {
								max-width: 230px;
							}

							@media (max-width: 1054px) {
								max-width: 340px;
							}

							@media (max-width: 600px) {
								max-width: 230px;
							}

							@media (max-width: 440px) {
								max-width: 170px;
							}
						}
					}
				}
			}

			&__lead {
				color: $color-pinot;
			}

			&__subtitle {
				color: $color-pinot;
			}

			&__about {
				&--param {
					color: $color-pinot;
				}
			}
		}
	}

	&-cabernet {
		.wine-25 {

			&__show-button {
				background-color: rgba($color: $color-cabernet-light, $alpha: 0.4);

				&::before {
					color: $color-cabernet !important;
				}
			}

			&__scroll-wrapper {

				// scrollbar-width: thin;
				// scrollbar-color: $color-cabernet $color-white;

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $color-cabernet;
				}

				&::-webkit-scrollbar-track {
					background-color: #2857324d;
				}
			}

			&__text-block {
				&--icon {
					width: 45px;

					svg {
						path {
							fill: $color-cabernet;
						}
					}
				}
			}

			&__about {
				&--block {
					&:last-child {
						.wine-25__about--value {

							@media (max-width: 1600px) {
								max-width: 305px;
							}

							@media (max-width: 1380px) {
								max-width: 190px;
							}

							@media (max-width: 1054px) {
								max-width: fit-content;
							}

							@media (max-width: 600px) {
								max-width: 190px;
							}
						}
					}
				}
			}

			&__lead {
				color: $color-cabernet;
			}

			&__subtitle {
				color: $color-cabernet;
			}

			&__about {
				&--param {
					color: $color-cabernet;
				}
			}
		}
	}

	.l-layout-wrapper {
		max-width: none;
		min-width: auto;
		height: 100%;
		min-height: auto;
		padding: 0;
		margin: 0;
	}

	.l-page {
		max-height: 100%;
		padding: 0;
		margin: 0;

		&__inner {
			height: 100%;
			flex-grow: 1;
		}

		&__content {
			margin: 0;
			padding: 0;
		}
	}

	.l-navigation-panel {
		border: none;

		&::after {
			content: none;
		}
	}

	.content-area {
		height: 100%;
	}

	#noty_layout__default {
		z-index: 4;
	}

	#particles-js {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		pointer-events: none;
	}

	.wine-25 {
		display: flex;
		height: 100%;
		padding: 0 18px 0 80px;
		overflow: hidden;

		&__main-block {
			position: relative;
			flex-grow: 1;
			width: 58vw;
			padding: 140px 80px 80px 110px;
			overflow: hidden;
		}

		&__show-button {
			position: absolute;
			bottom: 0;
			left: 50%;
			display: none;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			transform: translate(-50%, 60%);
			@include webfont-icon($webfont-icon--wine-text-up);

			&::before {
				position: absolute;
				top: 10px;
				left: 50%;
				font-size: 10px;
				transform: translate(-50%);
			}
		}

		&__img {
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
			width: 100%;
			height: 100%;

			picture {
				display: block;
				width: 100%;
				height: 100%;
				line-height: 0;

				img {
					min-height: 100%;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: right bottom;
				}
			}
		}

		&__content {
			flex-grow: 1;
			width: 42vw;
			padding: 100px 0 80px 30px;

			&-inner {
				overflow: hidden;
			}
		}

		&__scroll-wrapper {
			height: 100%;
			padding-right: 90px;
			overflow: auto;
		}

		&__title {
			@include font(700, 'CormorantSC');
			font-size: 64px;
			line-height: 77px;
			letter-spacing: .02em;
			color: $color-white;

			span {
				display: block;
			}
		}

		&__text-block {
			position: relative;
			z-index: 2;
			display: flex;
			padding: 45px 20px 40px;
			border-bottom: 1px solid $color-gainsboro;
			background-color: $color-white;

			&--icon {
				flex-shrink: 0;
				width: 45px;
				margin: 0 18px 0 0;
			}
		}

		&__lead {
			@include font(500, 'FuturaNew');
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 0;
		}

		&__subtitle {
			@include font(500, 'FuturaNew');
			font-size: 20px;
			line-height: 28px;
			font-style: italic;

		}

		&__text {
			position: relative;
			z-index: 2;
			margin-bottom: 0;
			@include font(300, 'FuturaNew');
			font-size: 16px;
			line-height: 23px;
			color: $color-text-wine25;
			background-color: $color-white;

			&:has(+ .wine-25__about) {
				padding: 45px 0 40px;
			}
		}

		&__about {
			position: relative;
			z-index: 2;
			background-color: $color-white;

			&--block {
				display: flex;
				align-items: baseline;
				margin-bottom: 14px;
			}

			&--param {
				display: inline-block;
				margin-right: 5px;
				@include font(400, 'FuturaNew');
				font-size: 20px;
				line-height: 28px;
				font-style: italic;
			}

			&--value {
				display: inline-block;
				margin-left: 10px;
				@include font(400, 'FuturaNew');
				font-size: 16px;
				line-height: 23px;
				text-align: end;
			}

			&--decor {
				flex-grow: 1;
				height: 1px;
				background-color: $color-gainsboro;
			}
		}
		// adaptive
		@media (max-width: 1440px) {
			&__main-block {
				padding: 120px 80px 80px 60px;
			}

			&__content {
				padding: 80px 0 40px 40px;
			}
		}

		@media (max-width: 1366px) {
			&__main-block {
				padding: 80px 40px;
			}
		}

		@include breakpoint(lg) {
			&__main-block {
				padding: 60px 40px;
			}

			&__content {
				padding: 60px 0 40px 40px;
			}

			&__scroll-wrapper {
				padding-right: 40px;
			}

			&__text-block {
				flex-direction: column;
				align-items: center;
				text-align: center;

				&--icon {
					margin: 0 0 18px 0;
				}
			}

			&__about {
				&--value {
					max-width: 170px;
				}
			}
		}

		@media (max-width: 1280px) and (max-height: 600px) {
			&__main-block {
				padding: 40px;
			}

			&__content {
				padding: 20px 0 20px 20px;
			}
		}

		@include breakpoint(md){
			display: block;
			padding: 0 0 0 80px;
			overflow: auto;

			&__main-block {
				min-height: 580px;
				width: 100%;
				animation-name: scaleWrapper;
				animation-duration: 2s;
			}

			&__img {
				animation-name: scaleImage;
				animation-duration: 1s;
				picture {
					img {
						object-position: bottom center;
					}
				}
			}

			&__title {
				font-size: 50px;
				line-height: 60px;
			}

			&__content {
				width: 100%;
				padding: 30px 32px 40px;
			}

			&__scroll-wrapper {
				height: auto;
				overflow: visible;
				padding: 0;
			}

			&__text-block {
				flex-direction: row;
				align-items: flex-start;
				text-align: start;

				&--icon {
					margin: 0 18px 0 0;
				}
			}

			&__about {
				&--value {
					max-width: 340px;
				}
			}
		}

		@include breakpoint(sm) {
			padding: 60px 0 0;

			&__main-block {
				padding: 35px 40px;
			}

			&__content {
				width: 100%;
				padding: 10px 24px 40px;
			}

			&__text-block {
				flex-direction: column;
				align-items: center;
				text-align: center;
				padding: 30px 0 30px;

				&--icon {
					margin: 0 0 18px 0;
				}
			}

			&__text {
				&:has(+ .wine-25__about) {
					padding: 30px 0 30px;
				}
			}
		}

		@keyframes scaleWrapper {
			from {
				min-height: 100%;
			}

			to {
				min-height: 580px;
			}
		}

		@keyframes scaleImage {
			from {
				transform: scale(1.5);
			}

			to {
				transform: scale(1);
			}
		}

		@include breakpoint(xs) {

			&__title {
				font-size: 30px;
				line-height: 36px;
				text-align: center;
			}

			&__content {
				width: 100%;
				padding: 10px 16px 40px;
			}

			&__lead {
				font-size: 18px;
				line-height: 26px;
			}

			&__text {
				font-size: 14px;
				line-height: 20px;
			}

			&__about {
				&--value {
					max-width: 170px;
				}
			}
		}

		@media (max-width: 450px) and (max-height: 900px) {

			&__main-block {
				animation: none;
				height: 100%;
			}

			&__show-button {
				display: block;
			}
		}

		@media (max-width: 1054px) and (max-height: 640px) {

			&__main-block {
				min-height: 100%;
				animation: none;
				overflow: hidden;
			}

			&__show-button {
				display: block;
			}
		}
	}

	.js-fade-in-text-block {
		opacity: 0;
		transform: translateY(50%);
		overflow: hidden;
		transition-property: opacity, transform;
		transition-duration: 0.8s;

		&.fade-in {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
