$cookie-law--btn-color: $color-white;
$cookie-law--btn-color-hover: $color-white;
$cookie-law--btn-bg: #ed1846;
$cookie-law--btn-bg-hover: #c50730;
$cookie-law--btn-bg-active: #c50730;

@if ($contrast) {
	$cookie-law--btn-color: $color-ironside-gray;
	$cookie-law--btn-color-hover: $color-white;
	$cookie-law--btn-bg: $color-light-gray;
	$cookie-law--btn-bg-hover: $color-tundora;
	$cookie-law--btn-bg-active: $color-dark-jungle;
}

.b-noty--cookie-law {
	z-index: 10;

	.b-noty__buttons {
		display: block;
		@include rem(margin-top, 15px);
	}

	.b-cookie-law__btn {
		@include rem(width, 200px);
		@include rem(height, 50px);
		color: $cookie-law--btn-color;
		background-color: $cookie-law--btn-bg;

		&:hover,
		&:focus {
			color: $cookie-law--btn-color-hover;
			background-color: $cookie-law--btn-bg-hover;
		}

		&:active {
			color: $cookie-law--btn-color-hover;
			background-color: $cookie-law--btn-bg-active;
		}
	}

	.b-cookie-law__btn--also {
		background-color: transparent;
		z-index: 99999;
		width: auto;
		height: auto;
		font-weight: 600;
		color: #ed1846;

		&:hover,
		&:active,
		&:focus {
			color: #c50730;
			background-color: transparent;
		}
	}
}

@media (max-width: 798px) {
	#noty_layout__default {
		z-index: 210 !important;
	}
}
