.b-search {
	&.b-search--result {
		position: relative;
		width: 100%;
		height: 30px;
		display: flex;

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 5px;
			height: 100%;
			cursor: text;
			line-height: 30px;
		}

		.b-search__input {
			width: 100%;
			height: 100%;
		}

		.b-search__wrapper-input {
			display: block;
			height: 100%;
			width: 100%;
		}

		.b-search__button {
			display: block;
			height: 100%;
		}
	}
}
