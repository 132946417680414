$services--key-color: #16BECF;
$services--text-color: #ffffff;
$services--background-color: #000000;

$services--padding-left: 169px;
$services--padding-right: 314px;
$services--padding-left-1: 90px;
$services--padding-right-1: 220px;
$services--padding-left-2: 50px;
$services--padding-right-2: 50px;
$services--padding-left-3: 16px;
$services--padding-right-3: 16px;

$services--aside-title: 10vh;

.services {
	position: relative;
	padding-top: 162px;
	padding-left: $services--padding-left;
	padding-bottom: 1rem;
	padding-right: $services--padding-right;
	overflow: hidden;

	&__what-we-do {
		font-size: $services--aside-title;
		font-weight: 600;
		text-transform: uppercase;
		color: $services--text-color;
		opacity: 0.3;
		transform: rotate(-90deg);

		position: absolute;

		transform-origin: right top;
		right: 1.05em;
		top: 0;

		text-align: right;
		white-space: nowrap;
		padding-right: 4vh;

		@include breakpoint(md) {
			display: none;
		}
	}

	&__header {
		@include font-size(70px);
		font-weight: 600;
		line-height: 1;
		color: $services--key-color;
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 18px);

		@include breakpoint(md) {
			@include font-size(56px);
			line-height: 68px;
		}

		@include breakpoint(sm) {
			@include font-size(48px);
			line-height: 54px;
		}

		@include breakpoint(xs) {
			@include font-size(36px);
			line-height: 42px;
		}
	}

	&__scheme {
		margin-bottom: 60px;
		margin-top: 40px;
	}

	&__lead {
		@include font-size(40px);
		font-weight: 400;
		line-height: 61px;
		color: $services--text-color;
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 100px);
		max-width: 1092px;

		.services--int & {
			@include font-size(36px);
			line-height: 54px;
			max-width: 1086px;
		}

		.services--ixbrl & {
			@include font-size(36px);
			line-height: 54px;
			max-width: 1100px;
		}

		.services--esg & {
			@include font-size(33px);
			line-height: 46.2px;
			max-width: 100%;
		}

		.services--ar &, .services--int &, .services--ixbrl &, .services--esg &, .services--essential-topics &, .services--databook & {
			@media (max-width: 1270px) {
				@include font-size(32px);
				line-height: 48px;
			}

			@include breakpoint(md) {
				@include font-size(26px);
				line-height: 39px;
			}

			@include breakpoint(xs) {
				@include font-size(25px);
				line-height: 40px;
			}
		}
	}

	&__image {
		width: 100%;
		max-height: 350px;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;
		position: relative;
		z-index: -1;

		img {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__examples {
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 150px);
	}

	$services--advantages-color: #ffffff;
$services--advantages-color-shown: #828282;
$services--advantages-line-height: 31px;

&__advantages {
	@include rem(margin-top, 75px);
	@include rem(margin-bottom, 100px);
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	// height: 566px;

	.advantage {
		display: flex;
		flex-direction: column;
		// padding-left: 224px;
		margin-bottom: 30px;
		align-items: center;
		flex-basis: 30%;
		max-width: 30%;
		@include rem(margin, 35px 0 35px);

		.services--ixbrl &, .services--int & {
			@media (min-width: $breakpoint-sm) {
				&:nth-last-child(2) {
					margin-left: calc(40% / 3);
				}

				&:nth-last-child(1) {
					margin-right: calc(40% / 3);
				}
			}
		}

		&__icon-wrapper {
			padding: 27.5px;
			border: 1px solid white;
			border-radius: 50%;
			width: 147px;
			height: 147px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
		}

		&__icon {
			width: 60px;
			flex: 0 0 60px;
			// margin-right: 36px;
			transition: all 1s ease;
			// display: flex;
			// align-items: center;
			// justify-content: center;

			div[data-svg] {
				width: 100%;
			}
		}

		&__text {
			@include font-size(25px);
			font-weight: 400;
			line-height: 27px;
			color: $services--advantages-color;
			transition: all 1.5s ease;
			text-align: center;
			max-width: 100%;
		}

		@include breakpoint(md) {
			// padding-left: 100px;
		}

		@include breakpoint(sm) {
			padding-left: 0;
			max-width: 50%;
			flex-basis: 50%;
		}

		@include breakpoint(xs) {
			max-width: 100%;
			flex-basis: 100%;

			&__icon {
				flex-basis: 50px;
			}

			&__text {
				@include font-size(22px);
				line-height: 27px;
			}
		}
	}

	.advantage.js-animated {
		.advantage {
			&__icon {
				width: 0px;
				flex-basis: 0px;
				margin-right: 0px;
			}

			&__text {
				@include font-size(25px);
				font-weight: 400;
				line-height: 31px;
				color: $services--advantages-color-shown;
			}
		}
	}

	@include breakpoint(xs) {
		@include rem(margin-top, 25px);
		@include rem(margin-bottom, 50px);
	}
}

&__lines {
	margin-left: 224px;
	padding-top: 29px;
	@include rem(margin-bottom, 131px);

	.lines {
		&__section {
			// @include font-size(48px);
			@include font-size(23px);
			// line-height: 52px;
			line-height: 27px;
			color: $color-white;
			margin-top: 9px;
			padding-bottom: 29px;
			padding-left: 34px;
			position: relative;
			font-weight: 450;
			transition: all 1s ease;

			// Временное
			// @extend .lines__section.js-animated;

			&::before {
				content: '';
				display: block;
				background-color: $services--key-color;
				border-radius: 100%;
				position: absolute;
				width: 10px;
				height: 10px;
				left: -6px;
				top: 8px;
			}

			&.js-animated {
				@include font-size(25px);
				line-height: 27px;
				color: #828282;
			}
		}
	}

	border-left: 1px solid $color-white;

	@include breakpoint(md) {
		margin-left: 100px;
	}

	@include breakpoint(sm) {
		margin-left: 0;
		margin-bottom: 40px;
	}

	&--tabs {
		margin-left: 11px;
		@include rem(margin-bottom, 64px);

		&:last-child {
			margin-bottom: 0;
		}

		.lines__section {

			&::after {
				content: '';
				display: block;
				border: 1px solid;
				border-color: $color-white;
				border-radius: 100%;
				position: absolute;
				width: 22px;
				height: 22px;
				left: -12px;
				top: 2px;
			}
		}
	}
}

&--esg {
	.services__advantages {
		.advantage {
			flex-basis: 25%;
			max-width: 25%;

			&:nth-child(1) {
				.advantage__icon {
					width: 45px;
					flex-basis: 45px;
				}
			}

			&:nth-child(4) {
				.advantage__icon {
					width: 90px;
					flex-basis: 90px;
				}
			}

			&:nth-child(5) {
				.advantage__icon {
					width: 90px;
					flex-basis: 90px;
				}
			}

			&:nth-child(6) {
				.advantage__icon {
					width: 80px;
					flex-basis: 80px;
				}
			}

			&:nth-child(7) {
				.advantage__icon {
					width: 70px;
					flex-basis: 70px;
				}
			}

			@include breakpoint(md) {
				max-width: 32%;
				flex-basis: 32%;
			}

			@include breakpoint(sm) {
				max-width: 50%;
				flex-basis: 50%;
			}

			@include breakpoint(xs) {
				max-width: 100%;
				flex-basis: 100%;
			}
		}
	}
}

	$services--progress-color: #ffffff;
$services--progress-color-header: #ffffff;
$services--border-color: #ffffff;

&__progress {
	@include rem(margin-top, 62px);
	@include rem(margin-bottom, 152px);
	padding-left: 320px;

	.progress {
		&__section {
			display: flex;

			&:last-child {
				.progress__content {
					padding-bottom: 0px;
				}
			}
		}

		&__side {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 135px;
			min-width: 135px;
			margin-right: 60px;

			&-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $services--border-color;
				border-radius: 50%;
				width: 135px;
				height: 135px;
				min-height: 135px;
				color: $services--key-color;
				@include font-size(40px);

				i {
					@include font-size(55px);
				}
			}

			&-line {
				margin-top: 0;
				height: 100%;
				width: 1px;
				background: $services--border-color;
				flex-grow: 1;
			}
		}

		&__content {
			padding-bottom: 50px;
			padding-top: 40px;
			color: $services--progress-color;

			&-title {
				@include font-size(40px);
				color: $services--progress-color-header;
			}

			&-text {
				@include font-size(26px);
				font-weight: 300;
				line-height: 1.15;
				margin-top: 45px;

				ul {
					margin-bottom: 1em;
					list-style: none;

					li {
						@include webfont-icon($webfont-icon--list-arrow);
						position: relative;
						padding-left: 0.8em;
						margin: 0;

						&::before {
							@include font-size(9px);
							top: 1.1em;
							background: transparent;
							color: $services--key-color;
						}
					}

					li + li {
						margin-top: 0.3em;
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	&__progress {
		padding-left: 160px;
	}
}

@include breakpoint(md) {
	&__progress {
		padding-left: 0;

		.progress {
			&__side {
				margin-right: 5vw;
			}

			&__content {
				&-title {
					font-size: 7vw;
				}

				&-title {
					@include font-size(32px);
				}
			}
		}
	}
}

@include breakpoint(sm) {
	&__progress {
		.progress {
			&__side {
				width: 90px;
				min-width: 90px;
				margin-right: 7vw;

				&-icon {
					width: 90px;
					height: 90px;
					min-height: 90px;

					.webfont-icon {
						transform: scale(0.7);
					}
				}
			}

			&__content {
				padding-bottom: 45px;
				padding-top: 23px;

				&-title {
					@include font-size(32px);
				}

				&-text {
					@include font-size(24px);
					margin-top: 35px;
				}
			}
		}
	}
}

@include breakpoint(xs) {
	&__progress {
		margin-left: -10px;

		.progress {
			&__side {
				// width: 45px;
				// min-width: 45px;
				width: 60px;
				min-width: 60px;
				margin-right: 4vw;

				&-icon {
					// width: 45px;
					// height: 45px;
					// min-height: 45px;
					width: 60px;
					height: 60px;
					min-height: 60px;

					.webfont-icon {
						transform: scale(0.5);
					}
				}

				&-text {
					@include font-size(20px);
				}

				&-line {
					// margin-top: 30px;
				}
			}

			&__content {
				padding-bottom: 30px;
				padding-top: 5px;

				&-title {
					@include font-size(26px);
				}

				&-title {
					// @include font-size(18px);
					// margin-top: 22px;
					@include font-size(24px);
					margin-top:10px;
				}
			}
		}
	}
}

	$services--blocks-line-color: linear-gradient(90deg, #FFFFFF -1.61%, rgba(255, 255, 255, 0) 77.87%);

&__blocks {
	.blocks {

		&:last-child {
			.b-custom-inset {
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: #ffffff;
					background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 80%);
				}
			}
		}

		&__section {
			padding: 0;
			@include rem(margin-top, 60px);
			@include rem(margin-bottom, 20px);
			// margin-top: 80px;

			&-title {
				color: $services--key-color;
				@include font-size(40px);
				line-height: 103.6%;
				font-weight: 600;
				margin-top: 43px;
			}

			&-text {
				margin-top: 43px;
				@include font-size(20px);
				line-height: 160.6%;
				font-weight: 400;
				color: $services--text-color;

				a {
					color: $services--key-color;
				}
			}

			// bad
			// .row {
			// 	position: relative;

			// 	&::before {
			// 		content: '';
			// 		display: block;
			// 		background: $services--blocks-line-color;
			// 		position: absolute;
			// 		left: 0;
			// 		top: 0;
			// 		width: 100%;
			// 		height: 1px;
			// 	}
			// }
		}
	}
}

&__inset {
	@include font-size(30px);
	line-height: 45px;
	padding: 63px;
	position: relative;
	border-left: 1px solid $color-white;
	margin-top: 80px;
	margin-bottom: 80px;
	color: $color-white;

	&::before, &::after {
		content: '';
		display: block;
		background: $services--blocks-line-color;
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
	}

	@include breakpoint(md) {
		@include font-size(18px);
		line-height: 28px;
		margin-top: 40px;
		margin-bottom: 40px;
		padding: 42px;
	}

	@include breakpoint(sm) {
		
	}
}

@include breakpoint(md) {
	&__blocks {
		.blocks {
			&__section {
				margin-top: 20px;
			}
		}
	}
}

	$navigation--colors: (
1: #16BECF,
2: #FDB913,
3: #F04B54,
4: #6C3CE8,
5: #4DCCAD,
6: #FF9929
);

&__navigation {
	position: absolute;
	right: 136px;
	top: 49px;

	&-icon {
		display: block;
		width: 80px;
		height: 80px;
		border-radius: 100%;
		margin: auto;
		margin-bottom: 60px;
		position: relative;

		transition: all .4s ease;

		span {
			position: absolute;
			height: 28px;
			border-radius: 2px;
			background-color: #C4C4C4;
			color: #ffffff;
			left: -135px;
			top: calc(50% - 14px);
			padding: 6px;
			display: none;
			align-items: center;
			white-space: nowrap;

			@include font-size(18px);
			line-height: 17px;

			&::before {
				$block-size: 5.5px;
				content: '';
				position: absolute;
				right: -$block-size / 2;
				top: calc(50% - #{$block-size}/2);
				transform: rotate(45deg);
				transform-origin: $block-size / 2 $block-size / 2;
				background-color: #C4C4C4;
				width: $block-size;
				height: $block-size;
			}

			@include breakpoint(md) {
				top: auto;
				bottom: -43px;

				&::before {
					$block-size: 5.5px;
					top: -$block-size / 2;
					left: calc(50% - #{$block-size}/2);
				}
			}
		}

		@each $name, $color in $navigation--colors {
			&--#{$name} {
				border: 1px solid $color-white;

				span {
					background-color: $color;

					&::before {
						background-color: $color;
					}
				}

				&::before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-image: url('https://cdn.zebra-group.ru/images/icons/slide-0_icon-#{$name}.svg');
					background-size: 50% 50%;
					background-position: center center;
				}

				&.active {
					transform: scale(1.5);
					background-color: $color;
				}
			}
		}

		&.active {
			border: none;
			transform: scale(1.5);

			span {
				transform: scale(0.6666666666667);
				bottom: -33px;
			}
		}

		&:hover {
			transform: scale(1.5);

			span {
				display: block;
				transform: scale(0.6666666666667);
			}
		}

		&--1, &--2 {
			&:hover {
				span {
					left: -110px;
				}
			}
		}

		&--4 {
			&:hover {
				span {
					left: -122px;
				}
			}
		}

		&--5 {
			&:hover {
				span {
					left: -213px;
				}
			}
		}

		&--6 {
			&:hover {
				span {
					left: -74px;
				}
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: static;
		height: 126px;

		&-icon {
			margin: 0;
			margin-bottom: 0px;
			// margin-right: 60px;
			text-decoration: none;
			text-align: center;

			@each $name, $color in $navigation--colors {
				&--#{$name} {
					&.active {
						span {
							color: $color;
						}
					}
				}
			}

			&.active {
				transform: none;

				span {
					transform: none;
				}
			}

			&:hover {
				transform: none;

				span {
					transform: none;
				}
			}

			span {
				background-color: transparent;
				position: static;
				height: auto;
				color: rgba(255, 255, 255, 0.7);
				white-space: normal;
				text-align: center;
				padding: 0;
				margin-top: 10px;
				display: block;

				&::before {
					content: none;
				}
			}
		}
	}

	@include breakpoint(sm) {
		&-icon {
			width: 10vw;
			height: 10vw;

			span {
				font-size: 2.3vw;
			}
		}
	}

	@include breakpoint(xs) {
		height: 220px;
		flex-wrap: wrap;

		&-icon {
			width: 60px;
			height: 60px;
			margin: 0 15px;

			span {
				@include font-size(12px);
			}
		}
	}
}

	// @import "./styles/mouse.scss";

	&__text {
		@include font-size(22px);
		line-height: 32px;
		color: $services--text-color;
		padding-left: 320px;
		@include rem(margin-top, 65px);
		@include rem(margin-bottom, 20px);

		&--margin {
			margin-bottom: 150px;
		}

		@media (max-width: 1440px) {
			padding-left: 160px;
		}

		@media (max-width: 1366px) {
			padding-left: 80px;
		}

		@include breakpoint(md) {
			padding-left: 20px;
		}

		@include breakpoint(sm) {
			padding-left: 0;
		}

		@include breakpoint(xs) {
			@include rem(margin-top, 35px);
		}

		.services--int & {
			padding-left: 224px;

			@include breakpoint(md) {
				padding-left: 80px;
			}

			@include breakpoint(sm) {
				padding-left: 0;
			}
		}

		.services--databook & {
			padding-left: 224px;

			@include breakpoint(md) {
				padding-left: 80px;
			}

			@include breakpoint(sm) {
				padding-left: 0;
			}
		}

		.services--essential-topics & {
			padding-left: 224px;

			@include breakpoint(md) {
				padding-left: 80px;
			}

			@include breakpoint(sm) {
				padding-left: 0;
			}
		}
	}

	&__text--big {
		@include font-size(25px);
		line-height: 32px;
		padding-left: 150px;

		@include breakpoint(md) {
			padding-left: 100px;
		}

		@include breakpoint(sm) {
			padding-left: 0;
		}
	}

	&__contact-us {
		padding: 117px 88px 44px 28px;

		@include breakpoint(xs) {
			padding: 80px 16px 16px 16px;
		}
	}

	&-contacts {
	// background-color: #404041;
	color: $services--text-color;
	// bottom: -5px;
	// display: flex;
	// flex-wrap: wrap;
	padding-left: 26px;
	padding-right: 88px;

	@include breakpoint(xs) {
		padding-left: 16px;
		padding-right: 16px;
	}

	&__data {
		flex-basis: 100%;
	}

	&__map {
		flex-basis: 100%;
	}

	&__title {
		@include font-size(70px);
		@include font(700);
		margin-bottom: 4vh;
		line-height: 1;
	}

	&__info {
		@include font-size(20px);
		@include font(300);

		p {
			margin-bottom: 0;
			@include font-size(20px);
		}

		span {
			color: #6d6e70;
			@include font(400);
		}

		a {
			color: $color-white !important;
			text-decoration: none;
			line-height: 1;
			border-bottom: 1px solid #ec1847;
			transition: 0.3s ease;

			&:hover,
			&:focus {
				border-bottom-color: transparent;
			}
		}

		& + .services-contacts__info {
			margin-top: 25px;
		}
	}

	&__info-address {
		a {
			display: none;
			@include webfont-icon($webfont-icon--location);
			color: $color-white;
			transition: color 0.3s ease;
			border: none;

			&:before {
				font-size: 0.9em;
				vertical-align: baseline;
				margin-right: 0;
			}

			&:hover,
			&:focus {
				color: #ec1847 !important;
			}
		}
	}

	&__form-btn {
		display: none;
		@include font-size(25px);
		@include font(600);
		margin-top: 16px;
		border-bottom: 1px solid #ec1847;
		line-height: 1.15;
		transition: 0.3s ease;
		cursor: pointer;

		&:hover,
		&:focus {
			border-bottom-color: transparent;
		}
	}

	&__subtitle {
		@include font-size(20px);
		@include font(400);
		color: #ec1847;
	}

	&__data {
		padding-top: 6vh;
		padding-bottom: 4vh;
		max-width: 1450px;
		margin: 0 auto;
		width: 100%;
		justify-content: center;
		padding-left: 24px;
		padding-right: 24px;
	}

	&__map {
		position: relative;
		display: flex;
		width: 100vw;
		// margin-left: -40px;
		left: 50%;
		transform: translateX(-50%);
		// padding-left: 80px;
		flex: 1 auto;
	}

	// @include breakpoint(md) {
	// 	&__col {
	// 		padding-left: 100px;
	// 	}
	// }

	@media (max-height: 750px) {
		&__map {
			display: none;
		}
	}

	// @media (max-width: 980px) {
	@include breakpoint(md) {
		flex-direction: row;

		&__map {
			display: block;
			padding-left: 0;
			margin-left: 0;
			transform: none;
			left: auto;
			margin-right: -25px;
			// margin-top: -30px;
			// margin-bottom: -30px !important;
			height: 100vh;
		}

		&__data {
			.browser-ie & {
				flex-grow: 1.15;
			}
		}

		&__col {
			flex-basis: 100%;
			max-width: 100%;
		}

		&__form-btn {
			// display: inline-block;
		}

		.b-form--hire-us {
			// display: none;
		}
	}

	@include breakpoint(sm) {
		&__map {
			padding-left: 0;
			margin-left: 0;
			height: calc(100vh - 80px);
		}
	}

	@include breakpoint(xs) {
		&__map {
			display: none;
		}

		&__info-address {
			a {
				display: inline;
			}
		}
	}

}


	.full-width {
		width: calc(100% + #{$services--padding-left} + #{$services--padding-right});
		position: relative;
		// left: -$services--padding-left;
		left: -$services--padding-left;

		@media (max-width: 1270px) {
			width: calc(100% + #{$services--padding-left-1} + #{$services--padding-right-1});
			left: -$services--padding-left-1;
		}

		@include breakpoint(md) {
			width: calc(100% + #{$services--padding-left-2} + #{$services--padding-right-2});
			left: -$services--padding-left-2;
		}

		@include breakpoint(xs) {
			width: calc(100% + #{$services--padding-left-3} + #{$services--padding-right-3});
			left: -$services--padding-left-3;
		}
	}

	.color-main {
		color: $services--key-color;
	}

	// TODO
	// .js-animation {
	// 	opacity: 0;
	// }

	@media (max-width: 1270px) {
		padding-top: 90px;
		padding-left: $services--padding-left-1;
		padding-right: $services--padding-right-1;
	}

	@include breakpoint(md) {
		padding-top: 50px;
		padding-left: $services--padding-left-2;
		padding-right: $services--padding-right-2;
	}

	@include breakpoint(xs) {
		padding-left: $services--padding-left-3;
		padding-right: $services--padding-right-3;
	}
}

@mixin services-key-color($color) {
	.services {
		&__header {
			color: $color;
		}

		&__blocks {
			// .blocks__section {
			// 	&-title {
			// 		color: $color;
			// 	}

			// 	&-text {
			// 		a {
			// 			color: $color;
			// 		}
			// 	}
			// }
			.b-custom-inset__text {
				a {
					color: $color;
				}
			}
		}

		&__progress {
			.progress {
				&__side {
					&-icon {
						color: $color;
					}
				}

				&__content {
					&-text {
						ul {
							li {
								&::before {
									color: $color;
								}
							}
						}
					}
				}
			}
		}

		&__lines {
			.lines {
				&__section {
					&::before {
						background-color: $color;
					}
				}
			}

			&--tabs {
				border-color: $color;
			}
		}

		&__contact-us {
			.svg-text {
				fill: $color;
			}
		}
	}

	.contacts-col--form {
		color: $color;
		border-left: 1px solid currentColor;
	}

	.color-main {
		color: $color;
	}

	.b-tabs--services {
		.b-tabs__button {
			&.is-expand,
			&.is-expand:focus {
				color: $color;
				border-color: $color;

				i {
					border-color: $color;
				}

				&::after {
					background-color: $color;
				}

				&:visited {
					color: $color;
				}
			}
		}

		.b-tabs__body {
			background: linear-gradient(0deg, rgba($color, 0.60) -37.53%, rgba($color, 0.00) 62.75%);
		}

		.b-tabs__article {
			color: $color-white;
			border-color: $color;
		}

		.b-tabs__title {
			color: $color;
		}

		@include breakpoint(sm) {
			.b-tabs__button {
				border-color: $color;
			}
		}
	}
}

.services--ar {
	@include services-key-color(#16BECF);
}

.services--ixbrl {
	@include services-key-color(#6C3CE8);
}

.services--int {
	@include services-key-color(#F04B54);
}

.services--esg {
	@include services-key-color(#FDB913);
}

.services--databook {
	@include services-key-color(#FF9929);
}

.services--essential-topics {
	@include services-key-color(#4DCCAD);
}

.body--services {
	background-color: $services--background-color;

	.l-layout-wrapper {
		padding-top: 0;
		max-width: 100%;
	}

	.l-layout-wrapper--1 .l-page {
		padding-left: 80px;
		padding-right: 0;

		&__inner {
			width: 100%;
		}

		&__content {
			padding-bottom: 0;
		}

		@include breakpoint(sm) {
			padding-top: 80px;
			padding-left: 0;
		}
	}

	// .b-projects._main,
	// .news {
	// 	@include rem(margin-bottom, 150px);
	// }

	.b-circle-col {
		align-items: center;
		justify-content: center;

		@include breakpoint(xs) {
			padding-top: 20px;
		}
	}

	@include breakpoint(lg) {
		.l-page__content {
			width: 100%;
		}
	}

	@media screen and (max-width: 980px) {
		.contacts-col--form {
			border-left: none;
			border-top: 1px solid currentColor;
		}
	}
}

// Временно
// .services--ixbrl {
// 	.mouse {
// 		top: 900px;
// 	}
// }
