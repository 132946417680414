.b-tabs--vertical {
	.b-tabs {
		&__nav-item {
			@extend %collapse--top;
			width: 100%;
			border-top: 1px solid $color-white;
			border-left: none;

			&:first-child {
				border-top: none;
			}
		}

		&__article {
			border: 1px solid $color-celeste;
		}
	}
}
