$header-width-index: 15vw;
$bgi--y: 60%;

$mobile-header--h: 60px;
$wine-top--h: 50px;

.body--wine {
	background-color: #125045;
	@include font(400, 'FuturaNew');

	.l-layout-wrapper {
		// width: 100vw;
		// overflow-x: hidden;
		padding-top: $wine-top--h;
		overflow: hidden;

		.l-page {
			padding: 0;
			// max-width: calc(100vw - 80px);
			// margin-left: 80px;
			// width: 100%;
			max-width: 1200px + 32px + 32px;
			margin-left: 10.4vw;
			width: calc(100% - 10.4vw);
		}
	}

	.l-page__inner {
		padding: 0 32px;
	}

	.l-page__content {
		width: 100%;
		// position: static;
	}

	.wine-header {
		&__top-line {
			color: #fff;
			font-size: 20px;
			line-height: 1.2;
			margin-bottom: 0;
		}

		&__top-line--mb {
			margin-bottom: .5em;
		}

		&__title {
			color: #E0BF8D;
			@include font(400, 'RfKrabuler');
			font-size: 55px;
			line-height: 1.1;
			margin-bottom: 0;
		}

		margin-bottom: 40px;
	}

	.wine-content {
		display: flex;
		// flex-wrap: wrap;

		// position: relative;
		// z-index: 2;

		&__image {
			margin-right: 80px;

			// float: left;
			width: 264px;
			flex: 1 0 264px;
			max-width: 264px;
		}

		&__text {
			color: #fff;
			@include font(400, 'FuturaNew');
			font-size: 20px;
			line-height: 1.2;

			position: relative;

			.wine-gif {
				position: absolute;
				width: 170px;
				bottom: calc(100% + 40px);
				left: -2vw;

				&--second-page {
					left: 20vw;
				}
			}
		}
	}

	.highlight {
		color: #E0BF8D;
		@include font(700, 'FuturaNew');
		font-size: 20px;
		line-height: 1.2;
	}

	.emoji {
		display: inline-block;
		width: 1em;
	}

	.snow-holder {
		position: absolute;
		width: 100vw;
		height: 100vh;
		opacity: 0.4;
		top: 0;
		left: -10vw;

		@media (max-width: 680px) {
			left: -20px;
			top: -50px;
		}

		@include breakpoint(xs) {
			left: -32px;
		}
	}

	.wine-snow {
		position: absolute;
		top: 0;
		opacity: 0;

		@media (max-width: 680px) {
			transform: translateY(-200%);
		}
	}

	.wine-snow--1 { // md
		width: 72px;
		z-index: 3;

		left: 5vw;
		// top: -30vh;

		animation: snow-1-1 22s ease-in-out 2s infinite;
		[data-svg] {
			animation: snow-1-2 4s ease-in-out infinite alternate;
		}
		
		@media (max-width: 1550px) {
			transform: translateY(330%);
		}

		@media (max-width: 680px) {
			// transform: translateY(170px);
			transform: translateY(-200%);
		}
	}

	.wine-snow--2 {
		width: 44px;
		z-index: 1;
		left: 10vw;
		transform: translateY(300%);

		animation: snow-1-1 24s ease-in-out 4s infinite;
		[data-svg] {
			animation: snow-1-2 6s ease-in-out infinite alternate;
		}

		@media (max-width: 680px) {
			// transform: translateY(170px);
			transform: translateY(-200%);
		}
	}

	.wine-snow--3 { // md
		width: 54px;
		z-index: 1;
		left: 15vw;
		transform: translateY(300%);

		animation: snow-1-1 18s ease-in-out 1s infinite;
		[data-svg] {
			animation: snow-1-2 3s ease-in-out infinite alternate;
		}

		@media (max-width: 1550px) {
			transform: translateY(400%);
		}

		@media (max-width: 680px) {
			transform: translateY(170px);
			left: 10vw;
		}
	}

	.wine-snow--4 {
		width: 66px;
		z-index: 1;

		left: 41vw;
		transform: translateY(-100%);

		display: none;

		animation: snow-1-3 20s ease-in-out 4s infinite;
		[data-svg] {
			animation: snow-1-2 3s ease-in-out infinite alternate;
		}
		
		@include breakpoint(lg) {
			display: block;
			animation: snow-1-4 20s ease-in-out infinite;
		}

		@media (max-width: 680px) {
			left: 36vw;
			transform: translateY(170px);
			animation: snow-1-1 20s ease-in-out infinite;
		}
	}

	.wine-snow--5 { // md
		width: 72px;
		z-index: 1;

		left: 50vw;
		transform: translateY(-200%);

		animation: snow-1-3 22s ease-in-out 3s infinite;
		[data-svg] {
			animation: snow-1-2 5s ease-in-out infinite alternate;
		}

		@include breakpoint(lg) {
			animation: snow-1-4 20s ease-in-out infinite;
		}

		@media (max-width: 680px) {
			left: 50vw;
			transform: translateY(170px);
			animation: snow-1-1 20s ease-in-out infinite;
		}
	}

	.wine-snow--6 {
		width: 66px;
		z-index: 1;

		left: 76vw;
		// display: none;

		animation: snow-1-1 24s ease-in-out 6s infinite;
		[data-svg] {
			animation: snow-1-2 5s ease-in-out infinite alternate;
		}

		@media (max-width: 1550px) {
			animation: snow-1-3 24s ease-in-out 2s infinite;
		}

		@include breakpoint(lg) {
			animation: snow-1-4 20s ease-in-out 2s infinite;
		}

		@media (max-width: 680px) {
			animation: snow-1-1 24s ease-in-out 2s infinite;
			// display: block;
		}
	}

	.wine-snow--7 { // md
		width: 48px;
		z-index: 1;

		left: 84vw;
		// top: -20vh;

		animation: snow-1-1 18s ease-in-out infinite;
		[data-svg] {
			animation: snow-1-2 5s ease-in-out infinite alternate;
		}

		@media (max-width: 1550px) {
			left: 92vw;
		}
	}

	@media screen and (max-width: 1280px) {
		.wine-header__title {
			font-size: 50px;
		}

		.wine-content__text .wine-gif {
			left: auto;
			right: 0;

			&--second-page {
				bottom: calc(100% + 100px);
			}
		}

		.wine-snow--2,
		.wine-snow--4,
		.wine-snow--6 {
			// display: none;
			opacity: 0;
			animation-delay: 9s;
		}

		.wine-snow--1 {
			width: 36px;
		}

		.wine-snow--2 {
			width: 22px;
		}

		.wine-snow--3 {
			width: 27px;
		}

		.wine-snow--4 {
			width: 33px;
		}

		.wine-snow--5 {
			width: 36px;
			opacity: 0;
			animation-delay: 2s;
		}

		.wine-snow--6 {
			width: 24px;
		}
	}

	@media screen and (max-width: 1500px) {
		.l-page__inner {
			padding-right: 88px;
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper {
			.l-page {
				max-width: 100vw;
				margin: auto;
				width: 100%;
			}

			padding-top: $wine-top--h + $mobile-header--h;
		}

		.wine-header {
			&__top-line {
				font-size: 16px;
			}

			&__title {
				font-size: 38px;
			}
		}

		.wine-content {
			&__text {
				font-size: 16px;

				.wine-gif {
					position: static;
				}
			}
		}

		.l-page__inner {
			padding-right: 32px;
		}

		.wine-snow--2,
		.wine-snow--1,
		.wine-snow--7 {
			display: none;
		}
	}

	@include breakpoint(xs) {
		.l-page__inner {
			padding: 0 20px;
		}
	}
}

.body--wine--1 {
	@media screen and (max-width: 1280px) {
		.wine-content {
			display: block;

			&__image {
				flex: none;
				float: left;

				margin-right: 32px;
				// max-width: 246px;
				width: 246px;
			}
		}
	}

	@include breakpoint(sm) {
		.wine-content__image {
			margin-right: 0;
		}
	}

	@media screen and (max-width: 680px) {
		.wine-content {
			&__image {
				float: none;
				display: block;
				margin: 0 auto;
			}
		}

		.hide-wine-gif {
			display: none;
		}

		.show-wine-gif {
			display: block;
		}
	}

	@media screen and (max-width: 412px) {
		.wine-content__image {
			width: 100%;
			margin: 0 auto;
		}
	}
}

.body--wine--2 {
	@media screen and (max-width: 1280px) {
		.wine-content {
			&__image {
				// margin-right: 32px;
				max-width: 246px;
			}
		}
	}

	@media screen and (max-width: 940px) {
		.wine-content {
			display: block;

			&__image {
				flex: none;
				float: left;
			}
		}
	}

	@include breakpoint(sm) {
		.wine-content {
			&__image {
				float: none;
				display: block;
				margin: 0 auto;
			}
		}
	}
}

@keyframes snow-1-1 {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translateY(100vh);
		opacity: 0;
	}
}

@keyframes snow-1-2 {
	0% {
		transform: translateX(-60%);
	}

	100% {
		transform: translateX(60%);
	}
}

// Мелкое перемещение
@keyframes snow-1-3 {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translateY(220%);
		opacity: 0;
	}
}

// Среднее перемещение
@keyframes snow-1-4 {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translateY(470%);
		opacity: 0;
	}
}
