$extendable-input-width: 500px;
$extendable-button-width: 23px;
$extendable-button-height: 23px;
$extendable-input-border-color: #cccccc;

.b-search {
	&.b-search--extendable {
		position: relative;
		display: inline-block;
		width: 100%;

		.b-search__wrapper-input {
			@extend %d-none;
			@include rem(width, $extendable-input-width);
			z-index: 10;
			position: absolute;
			right: auto;
			left: 100%;
			padding: 15px;
			height: 80px;
			margin-top: -1px;
			border: 1px solid #fff;
			background: #000;
		}

		.b-search__message {
			left: 15px;
		}

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 20px);
			@include rem(left, 5px);
			cursor: text;
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			padding: 0 50px 0 15px;
			margin-top: 0;
			background: rgba(255, 255, 255, .25);
			border: none;
			color: #fff;
		}

		.browser-safari & {
			.b-search__input {
				border-radius: 0;
			}

			input[type="search"] {
				-webkit-appearance: none;
			}
		}

		.b-search__button {
			height: auto;
			background-color: transparent;
			@include webfont-icon($webfont-icon--search);

			&:before {
				@include font-size(24px);
				color: $search--btn-icon;
			}

			&:hover,
			&:focus {
				//text-shadow: -2px 2px 2px rgba(0,0,0,0.25);

				&:before {
					color: $search--btn-icon-hover;
				}
			}

			&:active,
			&.is-expand {
				//text-shadow: -2px 2px 2px rgba(0,0,0,0.25);

				&:before {
					color: $search--btn-icon-active;
				}
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
			right: $extendable-button-width;
		}
	}
}

@include breakpoint(sm) {
	.b-search.b-search--extendable .b-search__wrapper-input {
		left: auto;
		right: 0;
		top: 100%;
		margin-top: 10px;
		width: 300px;
	}
}

@media (max-width: 798px) {
	.b-search.b-search--extendable .b-search__wrapper-input {
		width: 240px;
		height: 100%;
		right: 100%;
		top: 0;
		margin-top: 0;
		padding: 12px;
	}
}

.search-page {
	.b-search__button-speech:before {
		content: "\";
		display: inline-block;
		font-family: "webfont";
		font-style: normal;
		line-height: 1;
		color: currentColor;
		-webkit-font-smoothing: antialiased;
		speak: none;
		color: $color-black;
	}

	.btn {
		padding: 0.25em 0.567em;
	}
}
