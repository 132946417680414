$slider-nav--color: $color-white;
$slider-nav--color-hover: $color-celeste;
$slider-nav--color-active: #ec1846;
$slider-nav--bg: transparent;
$slider-nav--bg-hover: transparent;
$slider-nav--bg-active: transparent;
$slider-pagination--color: $color-white;
$slider-pagination--color-hover: $color-white;
$slider-pagination--color-active: $color-white;
$slider-pagination--bg: $color-ironside-gray;
$slider-pagination--bg-hover: $color-black;
$slider-pagination--bg-active: #ec1846;

.b-slider-wrapper--news {
	position: relative;
	margin-top: 1.5em;

	&.preloader {
		&:after {
			background-color: #f6f6f8;
		}
	}

	img {
		margin-top: 0;
		margin-bottom: 0;
	}

	* {
		outline: 0;
	}

	.l-slider-button-wrapper {
		position: absolute;
		width: 100%;
		height: 0;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		max-width: $site-width;
		margin: auto;
	}

	.b-slider-button {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		cursor: pointer;
		background-color: $slider-nav--bg;
		color: $slider-nav--color;
		transition: color 0.2s ease, opacity 0.3s ease;

		&:before {
			@include font-size(45px);
			text-shadow: 1px 1px 4px $color-black;
		}

		&:not(.swiper-button-disabled) {
			&:hover,
			&:focus {
				background-color: $slider-nav--bg-hover;
				color: $slider-nav--color-hover;
			}

			&:active {
				background-color: $slider-nav--bg-active;
				color: $slider-nav--color-active;
			}
		}

		&.swiper-button-disabled {
			opacity: 0;
			cursor: default;
			pointer-events: none;
		}
	}

	.b-slider-button--prev {
		left: 0;
		@include webfont-icon($webfont-icon--prev);
	}

	.b-slider-button--next {
		right: 0;
		@include webfont-icon($webfont-icon--next);
	}

	.l-slider-pagination-wrapper {
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		z-index: 1;
		width: 100%;
		max-width: $site-width;
		margin: auto;
	}

	.b-slider-pagination {
		float: right;

		&__item {
			@include rem(width, 20px);
			@include rem(height, 20px);
			@include rem(line-height, 20px);
			color: $slider-pagination--color;
			background-color: $slider-pagination--bg;
			text-align: center;
			opacity: 1;
			border-radius: 100%;
			transition: color 0.2s ease, background-color 0.2s ease;

			&:hover,
			&:focus {
				color: $slider-pagination--color-hover;
				background-color: $slider-pagination--bg-hover;
			}

			&:active,
			&.swiper-pagination-bullet-active {
				color: $slider-pagination--color-active;
				background-color: $slider-pagination--bg-active;
			}

			& + .b-slider-pagination__item {
				margin-left: 10px;
			}
		}
	}

	@media (max-width: $site-width + $grid-gutter-width * 2) {
		.b-slider-pagination {
			padding-right: $grid-gutter-width;
		}
	}
}
