$custom-links--color: $color-ironside-gray;
$custom-links--color-hover: $color-mountain-mist;
$custom-links--color-active: $color-celeste;
$custom-links--tooltip-bg: $color-taupe-gray;
$custom-links--tooltip-color: $color-white;
$custom-links--menu-color: $color-white;
$custom-links--menu-color-hover: $color-mountain-mist;
$custom-links--menu-color-active: $color-tapa-gray;
$custom-links--menu-bg: $color-tundora;

@mixin link-icon-hover($font-size, $webfont) {
	&:not(.b-custom-links__link--static):hover {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont);
			@include font-size($font-size);
		}
	}
}

@mixin link-icon-active($font-size, $webfont) {
	&:not(.b-custom-links__link--static):hover {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont);
			@include font-size($font-size);
		}
	}
}

.b-custom-links {
	@include font-size(12px);

	.b-tooltip {
		border-bottom: none;
	}

	&__link {
		position: relative;
		color: $custom-links--color;
		text-decoration: none;

		&:focus {
			color: $custom-links--color;
		}

		&:hover,
		&:visited:hover {
			color: $custom-links--color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--color-active;
		}
	}

	&__link--static {
		color: $custom-links--color;
		text-decoration: none;
	}
}

.b-custom-links--icons {
	.b-custom-links__link-icon {
		position: relative;
		display: inline-block;
	}
}

.b-custom-links--columns {
	.b-custom-links__list {
		display: inline-block;
		vertical-align: top;
	}
}

.b-custom-links--pdf {
	.b-custom-links__list,
	.b-custom-links__link,
	.b-custom-links__item,
	& {
		width: 100%;
		height: 100%;
	}

	.b-custom-links__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-align: center;
		font-size: 0;
		color: #fff;
		@include webfont-icon($webfont-icon--pdf_3);
		transition: transform 0.2s ease;

		&:focus,
		&:focus:visited,
		&:hover,
		&:visited:hover,
		&:active,
		&:visited:active {
			color: #fff;
		}

		&:before {
			@include font-size(26px);
		}

		&:active {
			transform: translateY(1px);
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.b-custom-links {
			.b-custom-links__item {
				display: block;
			}
		}

		.b-custom-links--footer {
			.b-custom-links__link {
				line-height: 1;
			}
		}

		.b-custom-links--menu-horizontal-content {
			.b-custom-links__item {
				@include rem(padding-top, 5px);
			}
		}
	}
}
