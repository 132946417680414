.b-projects-filter-trigger {
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none !important;
	font-size: 16px;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 5em;
		height: 5em;
		min-width: 5em;
		min-height: 5em;
		color: $color-white;
		border: 1px solid currentColor;
		border-radius: 50%;
		transition: background-color 0.2s ease, border-color 0.2s ease;
	}

	&__link {
		font-size: 1em;
		margin-left: 0.625em;
		line-height: 1.3;
		color: $color-manatee !important;
		transition: color 0.2s ease;
	}

	// &.is-active &__link,
	&:hover &__link {
		color: #fff !important;
	}

	// &.is-active &__icon,
	&:hover &__icon {
		&:before {
			color: #fff !important;
		}
	}

	&__balls {
		display: flex;

		&:before,
		&:after {
			content: '';
			width: 1.688em;
			height: 1.688em;
			border: 1px solid $color-white;
			border-radius: 50%;
			transition: background-color 0.2s ease, border-color 0.2s ease;
		}

		&:after {
			margin-left: 0.375em;
		}
	}

	&__balls + &__balls {
		margin-top: 0.375em;
	}
}

.b-projects-filter-trigger--all {
	.b-projects-filter-trigger__icon {
		flex-wrap: wrap;
		border: none;
	}

	&:hover,
	&.is-active {
		.b-projects-filter-trigger__balls._top {
			&:before {
				background-color: $color-seagull;
				border-color: $color-seagull;
			}

			&:after {
				background-color: $color-blue-violet;
				border-color: $color-blue-violet;
			}
		}

		.b-projects-filter-trigger__balls._bot {
			&:before {
				background-color: $color-radical-red;
				border-color: $color-radical-red;
			}

			&:after {
				background-color: $color-dark-tangerine;
				border-color: $color-dark-tangerine;
			}
		}
	}
}

.b-projects-filter-trigger--annual-reports {
	.b-projects-filter-trigger__icon {
		@include webfont-icon($webfont-icon--icon-ar);

		&:before {
			font-size: 2.5em;
		}
	}

	&:hover,
	&.is-active {
		.b-projects-filter-trigger__icon {
			border-color: $color-seagull;
			background-color: $color-seagull;
		}
	}
}

.b-projects-filter-trigger--online-reports {
	.b-projects-filter-trigger__icon {
		@include webfont-icon($webfont-icon--icon-or);

		&:before {
			font-size: 2.188em;
		}
	}

	&:hover,
	&.is-active {
		.b-projects-filter-trigger__icon {
			border-color: $color-radical-red;
			background-color: $color-radical-red;
		}
	}
}

.b-projects-filter-trigger--esef-ixbrl {
	.b-projects-filter-trigger__icon {
		@include webfont-icon($webfont-icon--icon-esef);

		&:before {
			font-size: 2.5em;
		}
	}

	&:hover,
	&.is-active {
		.b-projects-filter-trigger__icon {
			border-color: $color-blue-violet;
			background-color: $color-blue-violet;
		}
	}
}

.b-projects-filter-trigger--esg-csr {
	.b-projects-filter-trigger__icon {
		@include webfont-icon($webfont-icon--icon-esg);

		&:before {
			font-size: 2.5em;
		}
	}

	&:hover,
	&.is-active {
		.b-projects-filter-trigger__icon {
			border-color: $color-dark-tangerine;
			background-color: $color-dark-tangerine;
		}
	}
}

.body--cases {
	background-color: $color-black;

	.l-page__content {
		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			margin-left: -32px;
			margin-top: -35px;
			background: black;
		}
	}

	.animated-bg {
		max-height: 50vh;
		overflow: hidden;
	}
}

@media (max-width: 1300px) {
	#projects {
		.b-projects__plates {
			padding-right: 30px;
		}
	}
}

@media (max-width: 1366px) {
	#projects {
		.b-project-list-item {
			flex-basis: 33.33%;
			max-width: 33.33%;
		}
	}
}

@media (max-width: 1054px) {
	#projects {
		flex-direction: column;

		.b-projects__filter-wrapper {
			order: -1;
			flex-wrap: wrap;
			width: 100%;
			display: flex;

			.b-projects__filter-trigger {
				&:first-child {
					order: 1;
				}
			}

			.b-projects__filter-trigger {
				margin-bottom: 16px;
				flex-basis: 33.333%;
				max-width: 33.333%;
				width: 33.333%;
				display: flex;
			}
		}

		.b-project-list-item {
			flex-basis: 50%;
			max-width: 50%;
		}
	}
}

@include breakpoint(sm) {
	.body--cases {
		.l-page__content:before {
			margin-top: -20px;
			margin-left: 0;
		}
	}

	#projects {
		.b-projects-filter-trigger__icon {
			font-size: 14px;
		}
	}
}

@media (max-width: 630px) {
	#projects {
		.b-projects__plates {
			padding-right: 0;
		}

		.b-project-list-item {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

@include breakpoint(xs) {
	#projects {
		.b-projects-filter-trigger__icon {
			font-size: 12px;
		}
	}
}

.b-projects._main {
	position: relative;
	height: 60vh;
	max-height: 540px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;

	.b-project-list-end {
		display: none;
	}

	.b-project-list-item {
		flex: 0 1 33.33%;
		max-width: 30%;
		max-height: 45%;

		&:nth-child(n+7) {
			display: none;
		}

		&.is-active {
			display: block;
		}

		&:hover {
			.b-project-list-item__title-wrapper {
				opacity: 1;
			}
		}
	}

	.browser-ie & {
		margin-right: calc(33.33% / 100 * -10);

		.b-project-list-item {
			max-width: 33.33%;
		}

		.b-project-list-item__link-wrapper {
			position: relative;
			width: 90%;
		}
	}

	@include breakpoint(md) {
		height: 50vh;
	}

	@include breakpoint(sm) {
		.b-project-list-item {
			flex-basis: 50%;
			max-width: 50%;
			max-height: 30%;
		}

		.browser-ie & {
			margin-right: calc(50% / 100 * -10);

			.b-project-list-item {
				max-width: 50%;
			}
		}
	}

	@include breakpoint(xs) {
		flex-basis: 100vh;
		max-height: initial;

		.b-project-list-item {
			flex-basis: 100%;
			max-width: 100%;
			// max-height: 30%;
			max-height: 15vh;
		}

		.browser-ie & {
			margin-right: 0;

			.b-project-list-item {
				max-width: 100%;
			}
		}
	}
}

@media (max-width: 1024px) {
	.browser-ie {
		.body--cases {
			.l-page__content {
				width: 100%;
			}
		}
	}
}

@include breakpoint(sm) {
	#projects {
		.b-projects__filter-wrapper .b-projects__filter-trigger {
			flex-basis: 50%;
			max-width: 50%;
			width: 50%;
		}
	}

	.body--cases {
		.l-page__content {
			margin-left: 0;
		}
	}
}

@include breakpoint(xs) {
	#projects {
		.b-projects__filter-wrapper .b-projects__filter-trigger {
			flex-basis: 100%;
			max-width: 100%;
			width: 100%;
		}
	}
}

.browser-ie {
	.body--cases {
		#projects {
			.b-project-list-item__cover-title {
				width: 100%;
			}
		}
	}
}
