.lazyload,
.lazyloading {
	opacity: 0;
	//min-height: 200px;
	position: relative;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}
//
//.lazyload,
//.lazyloading {
//	-webkit-filter: blur(5px);
//	filter: blur(5px);
//	transition: filter 400ms, -webkit-filter 400ms;
//	width: 100%;
//	max-width: 100%;
//}
//
//.lazyloaded {
//	-webkit-filter: blur(0);
//	filter: blur(0);
//}
