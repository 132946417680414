.b-up-button--side {
	color: $up-button--btn-color;
	background-color: $up-button--btn-bg;
	@include rem(width, 50px);
	@include rem(height, 50px);
	@include webfont-icon($webfont-icon--up_arrow);
	background-color: rgba(236, 24, 70, 1);
	font-size: 0;
	border-radius: 50%;
	transition: background-color 0.3s ease;
	right: 27px;
	bottom: 20px;

	&:before {
		display: block;
		color: $color-white;
		@include font-size(18px);
		line-height: 50px;
	}

	&:hover,
	&:focus {
		background-color: rgba(183, 15, 51, 1);
	}
}

@if ($responsive) {
	@media (max-width: $site-width) {
		.b-up-button--side {
			right: 32px;
		}
	}

	@include breakpoint(sm) {
		.b-up-button--side {
			right: 24px;
			bottom: 15px;
			background-color: rgba(236, 24, 70, 0.5);

			&:before {
				opacity: 0.5;
				transition: opacity 0.3s ease;
			}

			&:hover,
			&:focus {
				opacity: 1;
				background-color: rgba(183, 15, 51, 1);

				&:before {
					opacity: 1;
				}
			}
		}
	}
}
