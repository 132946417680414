.b-sitemap {
	&__list,
	&__item,
	&__link {
		margin-top: 0;
	}

	&__item {
		@include rem(padding-left, 20px);
	}

	&__link {
		display: inline;
		text-decoration: none;
		@include rem(margin-bottom, 10px);

		&:hover {
			text-decoration: underline;
		}
	}

	&__list--lvl1 {
		.b-sitemap__item {
			&:before {
				content: none;
			}
		}
	}

	&__list--lvl2 {
		.b-sitemap__item {
			&:before {
				content: '';
				top: 0.46em;
			}
		}
	}
	&__link--lvl1 {
		@include font-size(24px);
		font-weight: 600;
	}

	&__link--lvl2 {
		@include font-size(18px);
	}

	&__link--lvl3 {
		@include font-size(13px);
	}

	&__link--lvl4 {
		@include font-size(12px);
	}

	&__list--lvl1 {
		& > .b-sitemap__item {
			@include rem(margin-bottom, 25px);
		}
	}

	&__list--lvl2 {

	}

	&__list--lvl3 {

	}

	&__list--lvl4 {

	}
}
