$tabs--button-bg: $color-dark-gray;
$tabs--button-bg-active: $color-ironside-gray;
$tabs--button-bg-hover: $color-taupe-gray;
$tabs--button-text-color: $color-white;
$tabs--button-text-color-hover: $color-white;
$tabs--body-bg: $color-white;

.b-tabs {
	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		@include rem(padding, 5px);
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font-size(18px);
		transition: color .2s ease-in-out, background-color .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:focus {
			color: $tabs--button-text-color;
			background-color: $tabs--button-bg;

			&:visited {
				color: $tabs--button-text-color;
				background-color: $tabs--button-bg;
			}
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-hover;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-hover;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-active;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;
			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		@include rem(padding, 15px 15px 16px 15px);
		border-left: 1px solid $color-celeste;
		border-right: 1px solid $color-celeste;
		border-bottom: 1px solid $color-celeste;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}

.b-tabs--career {
	.b-tabs__article {
		padding-left: 0;
		color: $color-white;

		p {
			color: $color-white;
		}
	}

	.b-tabs__button {
		background: transparent;
		text-align: left;
		font-size: 30px;
		font-weight: 600;
		color: #939597;

		&.is-expand {
			background: transparent;
			color: $color-white;
		}
	}

	.b-tabs__nav-item {
		border-left: 1px solid #ed1846;
		padding-left: 8px;

		&:first-child {
			border-left: none;
		}
	}

	.b-tabs__nav {
		border-bottom: 1px solid #ed1846;
		padding-bottom: 8px;
	}

	.b-tabs__article {
		border-left: none;
		border-right: none;
		border-bottom: none;
	}

	.b-tabs__body {
		background: transparent;
	}
}

.b-tabs--services {
	.b-tabs__nav-item {
		border-color: transparent;

		&:first-child {
			.b-tabs__button::before {
				right: -43px;
			}

			.b-tabs__button::after {
				right: -50px;
			}

			.b-tabs__button.is-expand i {
				border-left: 2px solid;
			}
		}

		&:last-child {
			.b-tabs__button::before {
				left: -43px;
			}

			.b-tabs__button::after {
				transform: scaleX(-1);
				left: -50px;
			}

			.b-tabs__button.is-expand i {
				border-right: 2px solid;
			}
		}
	}

	.b-tabs__button {
		color: #666666;
		background: transparent;

		@include rem(padding, 40px 40px 10px 40px);
		font-size: 32px;
		font-weight: 600;

		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
		border: 2px solid transparent;
		border-bottom: none;
		transition: none;

		position: relative;

		&::before {
			content: '';
			position: absolute;
			// right: -43px;
			bottom: -3px;
			width: 45px;
			height: 44px;
			background-color: transparent;
			z-index: 1;
			pointer-events: none;
		}

		&::after {
			content: '';
			position: absolute;
			// right: -50px;
			bottom: -2px;
			height: 50px;
			width: 50px;
			background-color: transparent;
			z-index: 2;
			pointer-events: none;

			-webkit-mask-image: url(/images/components/tabs/images/tabs-services-border.svg);
			mask-image: url(/images/components/tabs/images/tabs-services-border.svg);
		}

		i {
			content: '';
			display: block;
			position: absolute;
			left: -2px;
			right: -2px;
			bottom: -48px;
			width: calc(100% + 4px);
			height: 50px;
			background-color: transparent;
			// border: 1px solid transparent;
			z-index: 1;
			pointer-events: none;
		}

		&.is-expand {
			&::before {
				background-color: $color-black;
			}

			i {
				background-color: $color-black;
			}
		}

		&:visited {
			color: #666666;
		}

		&:focus {
			color: #666666;
			background: transparent;

			&:visited {
				color: #666666;
				background: transparent;
			}
		}

		&:hover {
			color: #666666;
			background: transparent;

			&:visited {
				color: #666666;
				background: transparent;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			border-color: $color-white;
			color: $color-white;
			background-color: transparent;

			&:visited {
				color: $color-white;
				background-color: transparent;
			}
		}
	}

	.b-tabs__body {
		background: transparent;
		border-radius: 50px;
	}

	.b-tabs__article {
		padding: 60px 30px;
		border-radius: 50px;
		border: 2px solid $color-white;
	}

	.b-tabs__article-row {
		display: flex;
	}

	.b-tabs__article-col {
		width: 50%;

		&:first-child {
			padding-right: 30px;
		}

		&:last-child {
			padding-left: 30px;
			border-left: 2px solid rgba($color-white, 0.5);
		}
	}

	.b-tabs__divider {
		width: 100%;
		height: 1px;
		margin-bottom: 64px;
		background-color: rgba($color-white, 0.5);
	}

	.b-tabs__title {
		font-size: 22px;
		font-weight: 500;
		line-height: 140%;
		color: $color-white;
	}

	.b-tabs__header {
		font-size: 28px;
		font-weight: 500;
		line-height: 140%;
		color: $color-white;
		text-align: center;
		margin-bottom: 10px;
	}

	.b-tabs__lead {
		font-size: 28px;
		font-weight: 400;
		line-height: 1;
		color: $color-white;
		text-align: center;
		margin-bottom: 38px;
	}

	@include breakpoint(md) {
		.b-tabs__button {
			font-size: 28px;
		}
	}

	@include breakpoint(sm) {
		.b-tabs__nav-item {
			border-color: transparent;

			&:first-child {
				.b-tabs__button {
					border-top-left-radius: 50px;
					border-top-right-radius: 50px;
					margin-bottom: -2px;
				}
			}
		}

		.b-tabs__button {
			font-size: 22px;
			border-radius: 0;
			border: 2px solid;
			border-bottom: none;
			padding-top: 10px;

			i,
			&::before,
			&::after {
				display: none;
			}
		}

		.b-tabs__article {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.b-tabs__article-row {
			flex-direction: column;
		}

		.b-tabs__article-col {
			width: 100%;

			&:first-child {
				padding-right: 0;
				padding-bottom: 30px;
			}

			&:last-child {
				padding-left: 0;
				padding-top: 30px;
				border-left: none;
				border-top: 2px solid rgba($color-white, 0.5);
			}
		}
	}

	@include breakpoint(xs) {
		.b-tabs__button {
			font-size: 20px;
			padding: 10px;
		}
	}
}
