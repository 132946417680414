//text
$base-text-color: $color-content-black;
// $base-text-color: $color-ironside-gray;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

//links
$link-default-color: #ec1847;
$link-hover-color: #ec1847;
$link-visited-color: #563b97;

//quote
$quote-bg: $color-light-gray;

//buttons
$btn--bg: #ed1846;
$btn--bg-hover: #c50730;
$btn--bg-active: #c50730;
$btn--color: $color-white;
$btn--color-hover: $color-white;
$btn--color-active: $color-white;

@if ($contrast) {
	$base-text-color: $color-white;
	$error-text-color: #ff0000;
	$error-form-bg: #fff;
	$link-default-color: $base-text-color;
	$link-hover-color: $carrot-orange;
	$link-visited-color: #252525;
	$quote-bg: #252525;
	$btn--bg: #ededed;
	$btn--bg-hover: #484848;
	$btn--bg-active: #252525;
	$btn--color: #656565;
	$btn--color-hover: $color-white;
	$btn--color-active: $color-white;
}
