.multiselect-container {
	margin: 0 !important;
	position: absolute;
	margin: 0;
	padding: 0;
	z-index: 2;
	background: #404041;
	display: none;
	padding: 10px 8px 11px 16px;

	label {
		color: $color-white;
		font-size: 13px;
	}

	li {
		list-style-type: none;
		margin-top: 1em;

		&:before {
			content: none;
		}
	}

	.b-checkbox {
		width: 100%;
		display: inline-block;
	}

	.b-checkbox__label {
		display: block;
		background-color: transparent;
		margin-top: 0;
		position: relative;
		padding-left: 22px;
		width: 100%;

		&:before {
			content: '';
			background: #3b3a3c;
			width: 13px;
			position: absolute;
			height: 13px;
			left: 0;
			top: 2px;
			cursor: pointer;
			left: 0;
			border: 1px solid $color-white;
		}

		&:after {
			top: 2px;
		}

		&:hover {
			&:before {
				border: 1px solid #ed1846;
			}
		}
	}

	.b-checkbox__input:checked + .b-checkbox__label:after,
	.b-checkbox__input.is-checked + .b-checkbox__label:after {
		@include pseudo-svg(13px, 13px, '/images/icons/checkbox-checkmark.svg', $color-white, 'fill');
		left: 0;
	}

	.b-checkbox__input:checked + .b-checkbox__label,
	.b-checkbox__input.is-checked + .b-checkbox__label {
		background: transparent;
	}

	.b-checkbox__label:active {
		background: transparent !important;
	}

	.b-checkbox__input:checked + .b-checkbox__label {
		&:before {
			background: #ed1846;
			border: 1px solid #ed1846;
		}
	}
}

.content-area .multiselect-container li:before {
	content: none;
}

.dropdown-toggle {
	width: 100%;
	overflow: hidden;
	height: 40px;
	white-space: nowrap;
	text-align: left;
	align-items: flex-start;
	justify-content: flex-start;
	text-overflow: ellipsis;
	background: transparent;
	color: #a7a9ab;
	border-bottom: 1px solid #ed1846;
	padding-left: 0;
	padding-right: 40px;
	position: relative;

	&:hover,
	&:focus {
		background: transparent;
	}

	&:focus {
		color: #a7a9ab;
	}

	.multiselect-selected-text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
	}

	&:active {
		transform: none;
	}

	.caret {
		height: 28px;
		width: 28px;
		background: #ed1846;
		position: absolute;
		right: 0px;
		bottom: 0;

		&:before,
		&:after {
			content: '';
			position: absolute;
			height: 10px;
			width: 2px;
			background: $color-white;
			top: 10px;
			transition: all 0.3s ease;
		}

		&:before {
			transform: rotate(-45deg);
			left: 10px;
		}

		&:after {
			transform: rotate(45deg);
			right: 10px;
		}
	}

	&.is-active {
		.caret {
			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}

.multiselect-container .input-group {
	margin: 5px;
}

.multiselect-container > li {
	padding: 0;
}

.multiselect-container > li > a.multiselect-all label {
	font-weight: 700;
}

.multiselect-container > li.multiselect-group label {
	margin: 0;
	//padding: 3px 20px 3px 20px;
	height: 100%;
	font-weight: 700;
}

.multiselect-container > li.multiselect-group-clickable label {
	cursor: pointer;
}

.multiselect-container > li > a {
	padding: 0;
}

.multiselect-container > li > a > label {
	margin: 0;
	height: 100%;
	cursor: pointer;
	font-weight: 400;
	//padding: 3px 20px 3px 40px;
}

.multiselect-container > li > a > label.radio,
.multiselect-container > li > a > label.checkbox {
	margin: 0;
}

.multiselect-container>li>a>label>input[type=checkbox] {
	margin-bottom: 5px;
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.form-inline .multiselect-container label.checkbox,
.form-inline .multiselect-container label.radio {
	padding: 3px 20px 3px 40px;
}

.form-inline .multiselect-container li a label.checkbox input[type=checkbox],
.form-inline .multiselect-container li a label.radio input[type=radio] {
	margin-left: -20px;
	margin-right: 0;
}

.btn-group {
	position: relative;

	.multiselect-container {
		width: 100%;
	}
}
