.figure-buttons {
	position: relative;
	text-align: right;
	margin-top: 10px;

	.button + .button {
		margin-left: 12px;
	}

	.button {
		padding: 0;
		text-decoration: none;
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(sm) {
		.figure-buttons {
			margin-top: 10px;
		}

		.figure-buttons .button {
			@include font-size(14px);
			text-align: left;
			padding: 15px;
			background: #DADADA;
			position: relative;
			width: 100%;
			text-decoration: none;

			&:before {
				@include font-size(22px);
				position: absolute;
				font-size: 22px;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
}
