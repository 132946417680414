$header-bg: $color-white;
$body-bg: $color-white;
$footer-bg: $color-white;

@if ($contrast) {
	$header-bg: #656565;
	$body-bg: #656565;
	$footer-bg: #656565;
}

body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.l-layout-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;

	&__header {
		background-color: $header-bg;
		flex: none;
		@include rem(padding-bottom, 10px);
	}

	&__page {
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: $right-col-width;
		width: $right-col-width;
		min-width: $right-col-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	&__content--with-left {
		padding-left: 0;
		padding-right: 65px;
		//width: $left-col-width + $center-col-width;
	}

	&__content--with-right {
		padding-right: 0;
		padding-left: 65px;
		//width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 10px);
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.content-area {
	min-height: 1px;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	margin-right: -($right-col-width + 15px);
}

.l-header {
}

.l-header-top-line {
}

.l-footer {
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
	width: $right-col-width;
	right: -$right-col-width;
	padding-left: $grid-gutter-width;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		max-width: $site-width;
		min-width: $site-min-width;
	}

	@include breakpoint(md) {
		.l-layout-wrapper__header {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__footer {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-page__content {
			width: auto;
			//overflow: hidden;
		}
	}

	@include breakpoint(sm) {
		//.l-layout-wrapper__page {
		//	flex-wrap: wrap;
		//}

		.l-page__content {
			padding-left: 0;
			padding-right: 0;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
