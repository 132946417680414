%button,
button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	color: $btn--color;
	border: 0;
	background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease;

	&:hover {
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
	}

	&:focus {
		outline: 0;
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
	}

	&:active {
		background-color: $btn--bg-active;
		color: $btn--color-active;
		transform: translateY(1px);
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	color: $btn--color;
	background-color: $btn--bg;
	opacity: 0.3;
	cursor: default;

	&:hover {
		color: $btn--color;
		background-color: $btn--bg;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
