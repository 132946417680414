$inset-test-color: $color-white;
$color-AR: #16BECF;
$color-IXBRL: #6C3CE8;
$color-INT: #F04B54;
$color-ESG: #FDB913;

.b-custom-inset {
	@include rem(padding-top, 40px);
	@include rem(padding-bottom, 40px);
	padding-left: 0;
	padding-right: 0;

	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $color-white;
		background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
	}

	&__left-side,
	&__right-side {
		flex: 1 1 50%;
		max-width: 50%;

		// Временно
		@include breakpoint(sm) {
			.services & {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}

	&__left-side {
		padding-right: 14%;
	}

	&__right-side {

	}

	&__title {
		@include font-size(40px);
		// line-height: 1.1;
		// line-height: 1.5;

		h4 {
			font-size: inherit;
			font-weight: 600;
			line-height: 1;
		}
	}

	&__text {
		@include font-size(22px);
		// @include rem(line-height, 32px);
		line-height: 1.5;
		color: $inset-test-color;
	}

	// margin-bottom: 1rem;
	@include breakpoint(sm) {
		&__title {
			@include font-size(30px);
		}

		// &__text {
		// 	@include font-size(15px);
		// }
	}

	@include breakpoint(xs) {
		&__left-side,
		&__right-side {
			max-width: 100%;
		}
	}
}

.b-custom-inset--ar {
	.b-custom-inset__title {
		color: $color-AR;
	}
}

.b-custom-inset--ixbrl {
	.b-custom-inset__title {
		color: $color-IXBRL;
	}
}

.b-custom-inset--int {
	.b-custom-inset__title {
		color: $color-INT;
	}
}

.b-custom-inset--esg {
	.b-custom-inset__title {
		color: $color-ESG;
	}
}
