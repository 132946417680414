.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		@include rem(width, $buttons-panel--trigger-width);
		@include rem(height, $buttons-panel--trigger-height);
		background-color: transparent;
		color: $buttons-panel--trigger-color;

		&:before {
			@include font-size($buttons-panel--trigger-icon-size);
		}

		&:hover,
		&:focus {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		// @include webfont-icon($webfont-icon--tools_4);
	}

	.b-buttons-panel__trigger--materials {
		// @include webfont-icon($webfont-icon--books);
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: 0;
		margin: 0;
		z-index: 20;
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__link {
		white-space: nowrap;
		@include rem(padding, 5px 15px 5px 45px);

		&:before {
			@include rem(left, 25px);
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

// Панель с файлом пдф и ссылкой
.b-buttons-panel--in-column.b-buttons-panel--pdf-list {
	width: 100%;
    height: 100%;

	.b-buttons-panel__trigger--pdf-list {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-align: center;
		font-size: 0;
		color: $color-white;
		@include webfont-icon($webfont-icon--pdf_3);
		transition: transform 0.2s ease;

		&:focus,
		&:focus:visited,
		&:hover,
		&:visited:hover,
		&:active,
		&:visited:active {
			color: $color-white;
		}

		&:before {
			@include font-size(26px);
		}

		&:active {
			transform: translateY(1px);
		}
	}

	.b-buttons-panel__list {
		position: relative;
		top: 0;
		right: auto;
		left: 79px;
		background-color: #212121 !important;

		@include breakpoint(sm) {
			left: auto;
			right: 60px;
		}
	}

	.b-buttons-panel__item-inner {
		display: flex;
	}

	.b-buttons-panel__item-icon {
		display: none;
	}

	.b-buttons-panel__link {
		@include rem(padding, 11px 15px 10px 68px);
		@include font-size(16px);
		@include rem(line-height, 19px);
		color: $color-white;
		background-color: transparent;

		&:focus,
		&:focus:visited,
		&:hover,
		&:visited:hover,
		&:active,
		&:visited:active {
			color: $color-white;
		}

		&--file {
			@include webfont-icon($webfont-icon--pdf-file);
		}

		&--website {
			@include webfont-icon($webfont-icon--pdf-website);
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include font-size(26px);
			@include rem(left, 30px);
			color: currentColor;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}


.b-buttons-panel--in-column.b-buttons-panel--compact.b-buttons-panel--tools {
	//counter-reset: buttons-panel-item;

	.b-buttons-panel__title {
		color: #fff;
		@include font-size(32px);
		margin-bottom: 50px;
	}

	.b-buttons-panel__list {
		position: static;
		display: flex;
		opacity: 1;
		flex-wrap: wrap;
		background: transparent;
	}

	.b-buttons-panel__item {
		counter-increment: buttons-panel-item;
		width: 20vw;
		height: 20vw;
		min-width: 300px;
		min-height: 300px;
		max-width: 385px;
		max-height: 385px;
		margin-right: 15px;
		margin-bottom: 15px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		//outline: 1px solid green;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: opacity 0.1s linear;
			@extend .bg-gradient--2;
			z-index:1;
		}


		&:hover {
			.b-buttons-panel__item-icon {
				font-size: 60px;
			}

			.b-buttons-panel__item-inner:before {
				color: #fff;
			}

			.b-buttons-panel__item-description {
				color: #fff;
			}

			.b-buttons-panel__sublist-link {
				color: #fff;
			}

			.b-my-report-counter__button {
				color: #fff;
			}
		}

		&:hover:after{
			opacity: 1;
		}
	}

	.b-buttons-panel__sublist-link {
		margin-right: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: auto;
		float: none;
		color: $color-light-sea-green;

		&:before {
			position: static;
			transform: none;
		}
	}

	.b-buttons-panel__sublist--compare .b-buttons-panel__sublist-link {
		margin-right: 10px;
		text-decoration: underline;
		font-size: 14px;
		padding: 0;
	}

	.b-buttons-panel__sublist {
		display: flex !important;
		opacity: 1 !important;
		padding: 0;
		background: transparent;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: calc(2em * 1.3);
	}

	//.b-buttons-panel__sublist-link {
	//
	//	&:before {
	//		left: 0;
	//		transform: none;
	//	}
	//}

	.b-buttons-panel__item-description {
		font-size: 14px;
		color: $color-light-sea-green;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: calc(2em * 1.3);
	}

	.b-buttons-panel__item-inner {
		width: 230px;
		min-height: 230px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		z-index: 3;

		//&:before {
		//	content: counter(buttons-panel-item, decimal-leading-zero);
		//	position: absolute;
		//	top: 0;
		//	right: 0;
		//	font-size: 16px;
		//	font-weight: bold;
		//	transition: opacity 0.1s linear;
		//	color: $color-light-sea-green;
		//}
	}

	.b-buttons-panel__item-icon {
		position: absolute;
		color: #fff;
		top: 0;
		left: 0;
		font-size: 50px;
		z-index: 2;
	}

	.b-buttons-panel__link {
		position: absolute;
		z-index: 2;
		background: transparent;
		color: #fff;
		padding: 0;
		@include font-size(24px);
		min-height: 2em;
		bottom: 40px;
		white-space: normal;
		width: 100%;
		padding-right: 20px;
		// @include webfont-icon($webfont-icon--arrow-right);

		&:before {
			position: absolute;
			right: 0;
			text-align: right;
			left: auto;
			font-size: 16px;
			top: 0.4em;
		}

		&:hover,
		&:focus,
		&:active {
			color: #fff !important;
		}
	}

	.b-buttons-panel__link.is-list {
		cursor: default;

		&:before {
			display: none;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
		background-color: $buttons-panel--list-bg-color;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 23px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 14px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		width: 100%;

		&:before {
			position: absolute;
			left: 16px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		width: 100%;
		text-align: center;
		font-size: 0;
		color: #fff;
		// @include webfont-icon($webfont-icon--pdf_3);

		&:before {
			@include font-size(26px);
		}
	}

	.b-buttons-panel__list {
		width: 260px;
		top: 0;
		left: 100%;
		border: 1px solid #fff;
	}

	.b-buttons-panel__link-wrapper {
		text-align: left;
	}

	.b-buttons-panel__link {
		color: #fff;
		background-color: transparent;
		white-space: normal;
		text-align: left;
		padding: 0;
		font-size: 18px;
		display: inline;
		@include font(300);

		&:before {
			@include font-size($buttons-panel--icon-size);
		}

		&:hover {
			background-color: transparent;
		}

		&:active {
			background-color: transparent;
		}
	}

	.b-buttons-panel__item:not(:last-child){
		border-bottom: 1px solid #fff;
	}

	.b-buttons-panel__item {
		width: 260px;
		height: 80px;
		background: #000;
	}

	.b-buttons-panel__item-inner {
		display: flex;
		height: 100%;
		align-items: center;
	}

	.b-buttons-panel__item-icon {
		min-width: 95px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		color: #fff;

		&--pdf-spreads {
			// @include webfont-icon($webfont-icon--pdf-double-page);
		}

		&--pdf-pages {
			// @include webfont-icon($webfont-icon--pdf-page);
		}
	}

}

.l-buttons-panel-wrapper {
	&__tigger {

	}

	&__panel {
		position: absolute;
		top: 0;
		left: 70px;
		width: calc(100vw - 70px);
		height: 100vh;
		display: none;
		background-color: $color-arsenic;
	}

	&__panel-inner {
		height: 100%;
		padding: 50px 5vw;
		text-align: left;
	}
}

.b-my-report-counter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	&__desc {}

	&__controls {
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-basis: 115px;
		width: 115px;
		text-align: center;
	}

	&__counter {
		background: transparent;
		font-size: 24px;
		color: #fff;
	}

	&__button {
		background: transparent;

		&:hover,
		&:focus,
		&:active {
			background: transparent;
		}

		&[disabled] {
			opacity: 0.3;
			background: transparent;
			color: $color-light-sea-green;
		}

		&[disabled]:hover {
			background-color: transparent;
		}

		&.is-off {
			opacity: 0 !important;
			cursor: default;
		}
	}

	// &__button--inc {
	// 	@include webfont-icon($webfont-icon--plus);
	// }
	// &__button--dec {
	// 	@include webfont-icon($webfont-icon--minus);
	// }
}

@include breakpoint(sm) {
	.l-buttons-panel-wrapper__panel {
		left: 0px;
		width: 100%;
	}

	// Панель скачать пдф
	.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
		.b-buttons-panel__list {
			right: 0;
			left: auto;
			top: 100%;
			margin-top: 6px;
			width: auto;
		}

		.b-buttons-panel__item {
			width: 225px;
		}
	}
}

@media (max-width: 798px) {
	.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
		.b-buttons-panel__list {
			right: 100%;
			top: 0;
			margin-top: 0;
		}
	}
}
