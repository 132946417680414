// Миксин breakpoint
//
// Позволяет применять различные правила в зависимости от ширины окна
//
// $point - определяет, какую media query использовать
// возможные значения: big, medium, small.
//
// ###Использование:
// <pre>
// @include breakpoint(small) {
//   font-size: .7em;
// }
// </pre>

// Styleguide 1.1.
$breakpoint-xl: 1240px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-md: 768px !default;
$breakpoint-sm: 480px !default;
$breakpoint-xs: 0 !default;

$breakpoints: xl, lg, md, sm, xs;

@mixin breakpoint($point) {
	@if $responsive == true and $point == xl {
		@media (min-width: $breakpoint-xl) { @content; }
	}
	@else if $responsive == true and $point == lg {
		@media (max-width: $breakpoint-lg) { @content; }
	}
	@else if $responsive == true and $point == md {
		@media (max-width: $breakpoint-md) { @content; }
	}
	@else if $responsive == true and $point == sm {
		@media (max-width: $breakpoint-sm) { @content; }
	}
	@else if $responsive == true and $point == xs {
		@media (max-width: $breakpoint-xs) { @content; }
	}
}

@mixin breakpoint-width($max-width) {
	@media (max-width: $max-width) { @content; }
}

@mixin breakpoint-height($max-height) {
	@media (max-height: $max-height) { @content; }
}

@mixin breakpoint-width-height($max-width, $max-height) {
	@media (max-width: $max-width), (max-height: $max-height) { @content; }
}
