.body--wine-24 {
	--page-padding: 45px;
	$color-wine-24: #B22E37;

	overflow: hidden;

	background: linear-gradient(to right, lightgrey, #749E2A);

	&-andryus {
		--wrap-padding: 80px;

		.wine-24-img__img {
			background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/andryus.jpg");
		}
	}

	&-markoth {
		--wrap-padding: 60px;

		.wine-24-img__img {
			background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/markoth.jpg");
		}
	}

	#noty_layout__default {
		z-index: 2;
	}

	.l-layout-wrapper {
		max-width: none;
		min-width: auto;
		padding: 0;
		margin: 0;

		height: 100%;
		min-height: auto;
	}

	.l-page {
		max-height: 100%;

		padding: 0;
		margin: 0;
		margin-left: 80px;

		padding: var(--page-padding);
		padding-left: 0;

		&__inner {
			height: 100%;
			flex-grow: 1;
		}

		&__content {
			margin: 0;
			padding: 0;
		}
	}

	.l-navigation-panel {
		border: none;

		&::after {
			content: none;
		}
	}

	.content-area {
		display: flex;
		justify-content: flex-end;
		height: 100%;
	}

	#particles-js {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		z-index: 1;
	}

	.wine-24-img {
		margin-bottom: 0;

		&__img {
			position: fixed;
			top: 0;
			left: 0;

			width: 100%;
			height: 100%;

			background-repeat: no-repeat;
			background-position: 80px 50%;
			background-size: cover;
			z-index: -1;

			transform-origin: left;
		}
	}

	.wine-24 {
		width: 44vw;
		margin-bottom: 0;
		padding: var(--wrap-padding);
		padding-right: 12px;

		text-align: center;
		border-radius: 24px;
		background-color: $color-white;

		max-height: calc(100vh - (var(--page-padding) * 2));

		scale: 1 0;

		position: relative;
		z-index: 2;

		animation: scalePanel 0.8s ease-in-out forwards;

		@keyframes scalePanel {
			from { scale: 1 0; }

			to { scale: 1 1; }
		}

		&__wrapper {
			height: 100%;
			overflow: auto;

			padding-right: calc(var(--wrap-padding) - 12px);

			scrollbar-width: thin;
			scrollbar-color: $color-wine-24 $color-white;

			&::-webkit-scrollbar {
				width: 2px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-wine-24;
			}

			&::-webkit-scrollbar-track {
				background-color: $color-white;
			}
		}

		&__title {
			display: block;

			@include font(700, 'CormorantUnicase');

			width: 90%;
			text-align: center;
			text-wrap: balance;
			margin: 0 auto 4vh;

			font-size: calc(16px + 1.5vw);
		}

		&__content {
			position: relative;

			display: flex;
			flex-direction: column;
			gap: 16px;

			padding-top: 56px;

			&::before {
				content: "";
				position: absolute;

				width: 50px;
				height: 32px;

				top: 0;
				left: 50%;
				translate: -50% 0;

				background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/icon.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				opacity: 0;
				animation: slideIn 0.8s 0.6s ease-in-out forwards;
			}

		}

		&__slogan {
			@include font(500, 'FuturaNew');
			font-style: italic;

			@include rem(margin-bottom, 10px);
		}

		&__text {
			@include font(400, 'FuturaNew');
			margin-bottom: 0;
		}

		&__block {
			&:has(.wine-24__text:nth-child(2)) .wine-24__text {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&__subtitle {
			@include font(500, 'FuturaNew');
			color: $color-wine-24;
			@include rem(margin-bottom, 5px);
		}

		&__slogan,
		&__text,
		&__subtitle {
			font-size: calc(11px + 0.5vw);
		}
	}

	.text-wrap {
		overflow: hidden;

		span {
			display: block;
			opacity: 0;
			animation: slideIn 0.8s 0.6s ease-in-out forwards;
		}
	}

	@keyframes slideIn {
		from {
			opacity: 0;
			transform: translateY(100%);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@media screen and (max-width: 1600px) {
		--wrap-padding: 60px;

		.wine-24-img__img {
			background-position: 40px 50%;
		}
	}

	@media screen and (max-width: 1400px) {
		--page-padding: 60px;
		--wrap-padding: 40px;

		.wine-24-img__img {
			background-position: 20px 50%;
		}
	}

	@media screen and (max-width: 1280px) {
		--page-padding: 30px;

		.wine-24-img__img {
			background-position: -20px 50%;
		}
	}

	@media screen and (max-width: 1154px) {
		--wrap-padding: 30px;
		background-position: -60px 50%;

		.wine-24-img__img {
			background-position: -60px 50%;
		}

		.wine-24 {
			width: 50vw;
		}
	}

	@media screen and (max-width: 1023px) {
		--page-padding: 20px;
		--wrap-padding: 20px;

		.wine-24-img__img {
			background-position: -100px 50%;
		}

		.wine-24 {
			width: 45vw;
		}
	}

	// adaptive
	@media screen and (max-width: 840px),
		screen and (min-height: 900px) and (max-width: 1024px) {
		overflow-x: hidden;
		overflow-y: auto;
		background: none;
		background-color: #99b23c;

		&-andryus {
			.wine-24-img__img {
				background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/andryus-mob.jpg");
			}
		}

		&-markoth {
			.wine-24-img__img {
				background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/markoth-mob.jpg");
			}
		}

		.l-page {
			max-height: none;
			margin: 0;
			padding: 0;
			// margin-top: 60px;

			margin-top: 0;
			padding-left: 80px;
		}

		.content-area {
			flex-direction: column;
			// overflow-x: hidden;
		}

		.wine-24-img {
			overflow: hidden;

			&__img {
				position: static;
				// background-position: 10%;
				background-position: 45% -80px;
				height: 100svh;

				transform-origin: top;
				scale: 1.2;

				margin-top: -50px;
				margin-bottom: 50px;

				animation: imgScale 1s 1s ease-in-out forwards;

				@keyframes imgScale {
					from { scale: 1.2; }

					to { scale: 1; }
				}
			}
		}

		.wine-24 {
			width: 100%;
			border-radius: 24px 24px 0 0;
			padding: 70px var(--wrap-padding) 45px;
			max-height: none;
			scale: 1;

			margin-bottom: -21vh;

			animation: translatePanel 1s 1.5s ease-in-out forwards;

			margin-top: auto;
			translate: 0 0;

			@keyframes translatePanel {
				from { translate: 0 0; }

				to { translate: 0 -21vh; }
			}

			background-color: transparent;

			position: relative;

			&::before {
				content: "";
				position: absolute;

				top: 0;
				left: 0;
				width: 100%;
				height: 100px;

				background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/back-mob.svg");
				background-repeat: no-repeat;
				background-position: top;
				background-size: cover;
			}

			&::after {
				content: "";
				position: absolute;

				top: 80px;
				left: 0;
				width: 100%;
				height: calc(100% - 80px);

				background-color: $color-white;
				z-index: -1;
			}

			&__wrapper {
				padding-right: 0;
				overflow: unset;
				position: relative;

				&::before {
					content: "";
					position: absolute;

					width: 7vw;
					height: 7vw;

					top: -100px;
					left: 50%;
					translate: -50% 0;

					background-image: url("https://cdn.zebra-group.ru/images/components/wine-24/images/arrow.svg");
					background-repeat: no-repeat;
					background-position: top;
					background-size: cover;
				}
			}

			&__title {
				font-size: 28px;
				margin-bottom: 16px;
			}

			&__content::before,
			.text-wrap span {
				animation: slideIn 0.8s 2s ease-in-out forwards;
			}

			&__slogan,
			&__text,
			&__subtitle {
				font-size: 16px;
			}
		}
	}

	@include breakpoint(sm) {
		.l-page {
			padding: 0;
			margin-top: 60px;
		}

		.wine-24-img__img {
			background-position: 10%;
		}

		.wine-24 {
			&__wrapper::before {
				width: 8vw;
				height: 8vw;
				top: -95px;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.wine-24 {
			&__wrapper::before {
				top: -90px;
			}
		}
	}

	@include breakpoint(xs) {
		&-andryus {
			.wine-24-img__img {
				background-position: 50% -15px;
			}
		}

		&-markoth {
			.wine-24-img__img {
				background-position: 40% -15px;
			}
		}

		.wine-24 {
			&__wrapper::before {
				top: -85px;
			}
		}
	}
}
