.b-logo {
	display: block;
	@extend .user-select--no;
	line-height: 0;
	width: 95px;

	&__image {
		width: 100%;
		height: 100%;
	}
}
