$up-button--btn-bg: $color-eallery;
$up-button--btn-bg-hover: $color-celeste;
$up-button--btn-bg-active: $color-white;
$up-button--btn-color: $color-celeste;
$up-button--btn-color-hover: $color-white;
$up-button--btn-icon: $color-celeste;
$up-button--btn-icon-hover: $color-eallery;

.b-up-button {
	display: none;
	position: fixed;
	text-align: center;
	cursor: pointer;
	z-index: 10;
}
