$checkbox--size: 13px;

.b-checkbox {
	position: relative;
	width: $checkbox--size;
	height: $checkbox--size;

	&__input {
		display: none;
	}

	&__label {
		display: block;
		width: $checkbox--size;
		height: $checkbox--size;
		cursor: pointer;
		background-color: transparent;
		margin-top: 0;
		position: relative;

		&:before {
			content: '';
			width: $checkbox--size;
			position: absolute;
			height: $checkbox--size;
			left: 0;
			cursor: pointer;
			left: 0;
			border: 1px solid $color-white;
		}

		&:hover {
			&:before {
				border-color: #ED1846 !important;
			}
		}
	}

	&__input:checked + &__label,
	&__input.is-checked + &__label {
		position: relative;

		&:before {
			background: #ED1846;
			border-color: #ED1846;
		}

		&:after {
			@include pseudo-svg($checkbox--size, $checkbox--size, '/images/icons/checkbox-checkmark.svg', $color-white, 'fill');
			left: 0;
		}
	}

	// &__label:active {
	// 	background-color: $color-celeste !important;
	// }

	// состояние для состояния неполного выделения, т.к. когда чекбокс родительский для группы и не все элементы из группы отмечены
	// &.is-partly-selected &__label {
	// 	background-color: $color-dark-gray;

	// 	&:after {
	// 		@include pseudo-svg(20px, 20px, '/images/icons/checkbox-checkmark.svg', $color-gray, 'fill');
	// 		width: 25px;
	// 		height: 25px;
	// 	}
	// }
}

.feature-no-checked .b-checkbox {
	.b-checkbox__label {
		display: none;
	}

	.b-checkbox__input {
		display: inline;
	}
}
