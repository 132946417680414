@import "mixins/**/*.scss";

@mixin hide-me($point) {
	@include breakpoint($point) {
		display: none !important;
	}
}

@mixin show-me($point, $display: inherit) {
	@include breakpoint($point) {
		display: $display !important;
	}
}

// Миксины animation и keyframes
//
// Позволяют использовать CSS3 анимации без префиксов браузеров
//
// Источник: http:joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/
//
// ###Использование
// <pre>
// @include keyframes(move-the-object) {
//   0%   { left: 100px; }
//   100% { left: 200px; }
// }
// .object-to-animate {
//   @include animation('move-the-object .5s 1', 'move-the-object-again .5s 1 .5s');
// }
// </pre>
//
// ###Поддержка браузеров:
//
// Styleguide 1.2.

@mixin animation($animate...) {
	$max: length($animate);
	$animations: '';

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	animation: $animations;
}

@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}

@mixin corner($position, $color, $size) {
	@if ($position == top) {
		&:before {
			content: "";
			display: block;
			width: $size * 2;
			height: $size * 2;
			border-style: solid;
			border-width: $size;
			border-color: transparent transparent $color transparent;
			position: absolute;
			top: -$size * 2;
			cursor: default;
		}
	}

	@if ($position == bottom) {
		&:after {
			content: "";
			display: block;
			width: $size * 2;
			height: $size * 2;
			border-style: solid;
			border-width: $size;
			border-color: $color transparent transparent transparent;
			position: absolute;
			bottom: -$size * 2;
			left: $size * 2;
			cursor: default;
		}
	}

	@if ($position == right) {
		&:after {
			content: "";
			display: block;
			width: $size * 2;
			height: $size * 2;
			border-style: solid;
			border-width: $size;
			border-color: transparent transparent transparent $color;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -$size * 2;
			margin: auto;
			cursor: default;
		}
	}
	@if ($position == left) {
		&:before {
			content: "";
			display: block;
			width: $size * 2;
			height: $size * 2;
			border-style: solid;
			border-width: $size;
			border-color: transparent $color transparent transparent;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -$size * 2;
			margin: auto;
			cursor: default;
		}
	}
}

@mixin corner-with-image($position) {
	&:before, &:after {
		content: "";
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		background: url(../images/corner-image-top.jpg) 0 0 no-repeat;
	}

	@if ($position == top) {
		&:before {
			top: -1px;
			left: -1px;
		}
	}

	@if ($position == bottom) {
		&:after {
			bottom: -1px;
			right: -1px;
		}
	}
}

@mixin corner-with-image-contrast($position) {
	&:before, &:after {
		content: "";
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		background: url(../images/corner-image-top-contrast.png) 0 0 no-repeat;
	}

	@if ($position == top) {
		&:before {
			top: -1px;
			left: -1px;
		}
	}

	@if ($position == bottom) {
		&:after {
			bottom: -1px;
			right: -1px;
		}
	}
}

@mixin triangle-left($color) {
	&:before {
		content: "";
		display: block;
		border: 4px solid transparent;
		border-left: 4px solid $color;
		position: absolute;
		top: 12px;
		left: 14px;
	}
}

$one: 1;
$double: 2;
$triple: 3;
$quad: 4;
$half: 0.5;
$one-and-half: 1.5;
$no: 0;

$sizes:
	$one,
	$double,
	$triple,
	$quad,
	$half,
	$one-and-half,
	$no;

$sizeMargins:
	"one",
	"double",
	"triple",
	"quad",
	"half",
	"one-and-half",
	"no";

$base-lh: (18 / 13);

@mixin margin($position) {
	$n: 1;
	@each $size in $sizes {
		.#{($position)}-margin--#{nth($sizeMargins, $n)} {
			margin-#{($position)}: ($base-lh*$size) + em;
		}

		.#{($position)}-margin--#{nth($sizeMargins, $n)}-important {
			margin-#{($position)}: ($base-lh*$size) + em !important;
		}
		$n: $n + 1;
	}
}

@mixin padding($position) {
	$n: 1;
	@each $size in $sizes {
		.#{($position)}-padding--#{nth($sizeMargins, $n)} {
			padding-#{($position)}: ($base-lh*$size) + em;
		}

		.#{($position)}-padding--#{nth($sizeMargins, $n)}-important {
			padding-#{($position)}: ($base-lh*$size) + em !important;
		}
		$n: $n + 1;
	}
}

$cursorProperty:
	default,
	crosshair,
	help,
	move,
	pointer,
	progress,
	text,
	wait,
	n-resize,
	ne-resize,
	e-resize,
	se-resize,
	s-resize,
	sw-resize,
	w-resize,
	nw-resize;

@mixin cursors($cursorProperty) {
	$i: 1;
	@each $property in $cursorProperty {
		.cursor-#{nth($cursorProperty, $i)} {
			cursor: $property;
		}
		$i: $i + 1;
	}
}

@mixin bg-rgba($r, $g, $b, $a) {
	#{background-color}: rgb($r, $g, $b);
	#{background-color}: rgba($r, $g, $b, $a);
}

@mixin hover {
	.browser-ie & {
		&:hover,
		&:visited:hover {
			@content;
		}
	}

	@media (hover:hover) {
		&:hover,
		&:visited:hover {
			@content;
		}
	}
}
