$bod_bod-animated-blocks--content-bg: $color-eallery;
$bod_bod-animated-blocks--content-arrow: $color-white;
$bod_bod-animated-blocks--name-color: $color-white;

@if ($contrast) {
	$bod_bod-animated-blocks--content-bg: $color-white;
	$bod_bod-animated-blocks--content-arrow: $color-ironside-gray;
	$bod_bod-animated-blocks--name-color: $color-white;
}

.body--bod {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #231f20;

	&:before {
		content: '';
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-image: url('https://cdn.zebra-group.ru/images/components/bod/images/bg--0.svg');
		background-size: cover;
		background-repeat: no-repeat;
		z-index: -2;
	}

	&:after {
		content: '';
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-image: url('https://cdn.zebra-group.ru/images/components/bod/images/bg-overlay-part.png');
		background-size: 3px 3px;
		z-index: -1;
	}

	h1 {
		color: $color-white;
	}

	.l-layout-wrapper--1 {
		padding-top: 20px;

		@include breakpoint(sm) {
			padding-top: 80px;
		}
	}
}

.b-bod--animated-blocks {
	p {
		margin-top: 0;
	}

	%content-arrow,
	.content-arrow {
		content: '';
		background: url("https://cdn.zebra-group.ru/images/components/bod/images/decor.svg") no-repeat;
		background-size: 100% 100%;
		height: 10px;
		position: absolute;
		top: 0;
	}

	.b-bod-main {
		position: relative;
		text-align: left;
		cursor: pointer;
		display: block;
		@include rem(width, 350px);

		&__photo {
			@include rem(width, 350px);
			@include rem(height, 420px);
			position: relative;
			right: 0;
			left: 0;
			// margin: auto;
			margin-bottom: 16px;

			img {
				@include rem(width, 350px);
				@include rem(height, 420px);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.photo_hover {
				opacity: 0;
				transition: opacity 0.5s;
			}
		}

		&.is-arrowed {
			&:before {
				@extend .content-arrow;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: calc(100% + 11px);
				z-index: 1;
			}
		}

		&.is-static, &.no-content {
			cursor: default;
		}

		&:hover {
			background-color: transparent;

			@media (min-width: 799px) {
				.b-bod-main__photo {
					.photo_hover {
						opacity: 1;
					}
				}
			}
		}

		&.is-expand,
		&.is-static {
			background-color: transparent;

			@media (max-width: 799px) {
				.b-bod-main__photo {
					.photo_hover {
						opacity: 1;
						transition: opacity 0.5s;
					}
				}
			}

			&:focus {
				outline: 0;
			}
		}

		&:focus {
			background-color: transparent;
		}

		&:active {
			background-color: transparent;
			transform: none;
		}

		&__name {
			@include font-size(28px);
			font-weight: 600;
			color: $bod_bod-animated-blocks--name-color;
			margin-bottom: 5px;
			// padding: 0 10px;
		}

		&__post {
			@include font-size(24px);
			margin-top: 0;
			color: $color-white;
			// padding: 0 10px;
			min-height: 60px;
		}
	}

	.b-bod-content {
		position: relative;
		display: none;
		width: 100%;
		@include rem(padding, 40px 50px);
		margin-top: 10px;
		margin-bottom: 60px;
		border-top: 2px solid $color-white;
		background-color: $color-black;
		@include font-size(22px);
		color: $color-white;

		&.is-static {
			display: block;
		}

		.b-bod__subtitle {
			font-weight: 700;
		}
	}
}

.no-transform {
	transform: unset !important;
}

@media (min-width: 1055px) {
	.body--bod {
		.l-layout-wrapper--1 .l-page {
			max-width: 1370px;
			// padding-left: 170px;
			padding-left: 8.9vw;
		}
	}
}

@include breakpoint(lg) {
	.b-bod--animated-blocks {
		.b-bod-main {
			@include rem(width, 300px);

			&__photo {
				@include rem(width, 300px);
				@include rem(height, 360px);

				img {
					@include rem(width, 300px);
					@include rem(height, 360px);
				}
			}
		}
	}
}

@media (max-height: 768px) {
	.b-bod--animated-blocks {
		.b-bod-main {
			@include rem(width, 300px);

			&__photo {
				@include rem(width, 300px);
				@include rem(height, 360px);

				img {
					@include rem(width, 300px);
					@include rem(height, 360px);
				}
			}
		}
	}
}

@include breakpoint(sm) {
	.b-bod--animated-blocks {
		.b-bod-main {
			@include rem(width, 350px);

			&__photo {
				@include rem(width, 350px);
				@include rem(height, 420px);

				img {
					@include rem(width, 350px);
					@include rem(height, 420px);
				}
			}
		}
	}
}

@include breakpoint(sm) {
	.b-bod--animated-blocks {
		.b-bod-main {
			margin: 0 auto;
			@include rem(width, 290px);

			&__photo {
				@include rem(width, 290px);
				@include rem(height, 348px);

				img {
					@include rem(width, 290px);
					@include rem(height, 348px);
				}
			}
		}
	}
}
