.graphic {
	&__img {
		@extend %collapse--top;
	}

	figcaption {
		@include font-size(16px);
		font-weight: bold;
		color: $base-text-color;
		border-bottom: 2px solid $base-text-color;
		margin-bottom: $grid-gutter-width;

		.caption__units {
			font-weight: normal;
		}
	}
}


.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
