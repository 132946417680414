.body--career {
	background-color: black;

	.b-up-button {
		display: none !important;
	}
}

.career {
	.row-career {
		justify-content: space-between;

		.col-vacancy {
			flex-basis: 60%;
			max-width: 60%;
			flex-direction: column;
			display: flex;
			flex-grow: 0;
			flex-shrink: 1;
			padding-left: 15px;
			padding-right: 140px;
			position: relative;
		}

		.col-contact {
			flex-basis: 30.33333%;
			max-width: 30.33333%;
			flex-direction: column;
			display: flex;
			flex-grow: 0;
			flex-shrink: 1;
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	h1 {
		color: #E21144;
	}

	h2 {
		color: #E21144;
		font-weight: 900;
		font-size: 44px;
		margin-top: 112px;
		margin-bottom: 48px;
	}

	.b-indicator {
		&__value {
			font-weight: 600;
			font-size: 56px;
		}

		&__value-crimson {
			color: #E21144;
		}

		&__unit {
			font-weight: 600;
			font-size: 24px;
		}

		&__text {
			font-weight: 900;
		}
	}

	&__svg-leader {
		display: none;
		width: 100px;
		height: 100px;
		margin-top: 32px;
	}

	&__text {
		color: $color-white;
		padding-left: 84px;

		.text-highlight {
			background-color: #E21144;
			padding: 0 2px;
		}

		p {
			margin-bottom: 32px;
		}
	}

	&__key-row {
		gap: 12px 0;
	}

	&__pros {
		display: grid;
		grid-template-columns: repeat(2, 2fr);
		grid-template-rows: auto;
		gap: 32px 80px;
		padding-left: 84px;
		color: $color-white;
	}

	&__pros-icon {
		width: 48px;
		height: 48px;
		margin-bottom: 12px;
	}

	&__pros-title {
		font-weight: 900;
		font-size: 24px;
		margin-bottom: 12px;
	}

	&__list {
		position: relative;
		color: $color-white;

		&::before {
			content: '';
			position: absolute;
			left: 28px;
			top: 0;
			bottom: 0;
			width: 1px;
			background-color: $color-white;
		}

		li.career__list-item {
			padding-left: 84px;

			&:not(:last-child) {
				margin-bottom: 32px;
			}

			&::before {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 48px;
				height: 48px;
				background-color: black;
				border: 1px solid white;
				font-size: 24px;
				font-weight: 600;
				color: #E21144;
			}
		}

		@for $i from 1 through 9 {
			li:nth-child(#{$i}):before {
				content: '0' + $i;
			}
		}
	}

	&__list-title {
		display: block;
		font-size: 24px;
		font-weight: 900;
		margin-bottom: 12px;
	}

	&__contact {
		position: fixed;
		max-width: 490px;
		width: 25.521vw;
		height: calc(100svh - 40px);
		top: 20px;
		bottom: 20px;

		&::before,
		&::after {
			content: '';
			position: absolute;
			max-width: 400px;
			max-height: 400px;
			width: 20.833vw;
			height: 20.833vw;
			border-radius: 100%;
			background-color: #E21144;
			filter: blur(120px);
			z-index: -1;
		}

		&::before {
			top: calc(var(--layout-pt) * -1);
			right: calc(var(--page-pr) * -1);
		}

		&::after {
			bottom: calc(var(--layout-pt) * -1);
			left: -100px;
		}
	}

	&__contact-icon {
		position: absolute;
		top: 80px;
		left: -160px;
		width: 140px;
		height: 140px;
	}

	&__contact-scroll {
		height: 100%;
	}

	&__contact-connection {
		background-color: #050506;
		padding: 25px;
		padding-bottom: 0;

		figcaption {
			position: relative;
			font-size: 30px;
			font-weight: 700;
			color: #ffffff;
			padding-bottom: 18px;
			margin-bottom: 32px;
			border-bottom: 1px solid #E21144;
		}

		p {
			color: #ffffff;
		}

		.name {
			font-size: 20px;
			font-weight: 700;
		}

		.position {
			font-size: 16px;
			font-weight: 400;
		}

		.connection {
			text-decoration: none;
			font-weight: 450;
			color: #E21144;
		}
	}

	&__contact-form {
		padding: 25px;
		background: #050506;
	}
}

@if ($responsive) {
	@media (max-width: 1660px) {
		.career .row-career .col-contact {
			flex-basis: 35.3%;
			max-width: 35.3%;
		}

		.career__contact {
			max-width: 515px;
			width: 31.024vw;
		}
	}

	@media (max-width: 1460px) {
		.career .row-career .col-vacancy {
			padding-right: 100px;
		}

		.career .row-career .col-contact {
			flex-basis: 39.3%;
			max-width: 39.3%;
		}

		.career__contact {
			max-width: 498px;
			width: 34.11vw;
		}

		.career__contact-icon {
			left: -110px;
			width: 100px;
			height: 100px;
		}

		.career__text,
		.career__pros {
			padding-left: 0;
		}
	}

	@include breakpoint(md) {
		.body--career .l-page__content {
			width: 100%;
		}

		.career .row-career .col-vacancy {
			flex-basis: 100%;
			max-width: 100%;
			padding-right: 15px;
		}

		.career .row-career .col-contact {
			flex-basis: 100%;
			max-width: 100%;
			margin-top: 2em;
		}

		.career__contact {
			position: relative;
			top: unset;
			bottom: unset;
			max-width: none;
			width: auto;
			height: auto;
		}

		.career__contact-scroll {
			height: auto;
		}

		.career__contact-icon {
			display: none;
		}

		.career__svg-leader {
			display: block;
		}
	}

	@include breakpoint(sm) {
		.career h2 {
			margin-top: 80px;
			margin-bottom: 40px;
		}
	}

	@include breakpoint(xs) {
		.career__pros {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
