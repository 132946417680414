$form--max-width: 700px;
$form--height: 535px;
$smart-placeholder--bg: rgba(103, 98, 98, 0.8);

@mixin captchaAdaptive() {
	flex-wrap: wrap;
	justify-content: flex-start !important;

	.b-form__field-input {
		padding-bottom: 20px !important;
	}

	.b-captcha__reload-btn {
		padding-left: 0 !important;
	}
}

.b-form {
	display: block;
	width: 100%;
	// max-width: $form--max-width;

	input::-ms-clear {
		display: none;
	}

	&__title {
		margin-bottom: 1em;
		margin-top: 0;
		padding: 0;
	}

	&__fields {
	}

	&__field {
	}

	&__field:last-child {
		margin-bottom: 0;
	}

	&__field-title {
		margin-bottom: 0.4em;
	}

	&__field-placeholder--smart,
	&__field-placeholder--smart-top {
		cursor: text;
		position: absolute;
		transition: all 0.2s ease;
		background-color: transparent;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 18px;
		left: 0;

		span {
			color: #ec1847;
		}
	}

	&__field-placeholder--smart,
	&__field-placeholder--smart-top {
		top: 0.4em;
	}

	&__field-input {
		position: relative;
		width: 100%;
		padding-bottom: 14px;
		display: block;

		input[type="tel"],
		input[type="email"],
		input[type="text"] {
			background-color: transparent;
			border-top: none;
			border-right: none;
			border-left: none;
			border-bottom: 1px solid #9b9b9b;
			height: 35px;
			@include font-size(16px);
			padding-left: 0;

			&.is-error {
				border-bottom-color: #ed1847;
			}
		}

		textarea {
			border: 1px solid #9b9b9b;
			background-color: transparent;
			@include font-size(16px);
		}

		.btn[type="submit"] {
			background-color: #ec1847;
			color: $color-white;
			@include font-size(20px);
			width: 100%;
			transition: 0.3s ease;
			text-decoration: none;

			&:hover,
			&:focus,
			&:active {
				background-color: #b70f33;
			}
		}
	}

	//Текст ошибки для невалидного поля
	.parsley-errors-list {
		margin: 0;

		& > li {
			display: block;
			color: $error-text-color;
			position: absolute;
			padding-left: 0;
			@include font-size(15px);
			line-height: 1;
			margin-top: 1px;
			margin-bottom: 0;

			&:before {
				content: none;
			}
		}
	}

	&__field-input input,
	&__field-input textarea {
		width: 100%;
		transform: translateZ(0);
	}

	&__field-input textarea {
		display: block;
		resize: none;
		padding: 10px 12px;
	}

	&__field-input--smart-placeholder {
		// padding: 0;

		&.is-focused .b-form__field-placeholder--smart,
		&.is-focused .b-form__field-placeholder--smart-top {
			// display: none;
			// top: auto;
			// transform: none;
			cursor: default;
			//
			opacity: 1;
			top: 9px;
			transform: translateY(-150%);
			font-size: 16px;
			color: $color-white;
			background-color: $smart-placeholder--bg;
			cursor: default;
			@include corner(bottom, $smart-placeholder--bg, 6px);
			@include rem(padding, 5px 7px);
		}

		&.is-fill .b-form__field-placeholder--smart,
		&.is-fill .b-form__field-placeholder--smart-top {
			opacity: 0;
		}
	}

	&__field-title {
		@include font-size(16px);
	}

	.b-captcha {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 14px;

		.b-captcha__image-wrapper {
			min-width: 138px;

			img {
				display: flex;
			}
		}

		.b-captcha__reload-btn {
			padding: 0 20px 0 20px;
		}

		.b-form__field-input {
			padding-bottom: 0;
		}
	}

	&__field-input--submit {
		padding-bottom: 0;
	}

	&__field-input--policy {
		display: flex;
		color: #bcbdbf;
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 1.75em;
		padding: 0;

		.b-checkbox {
			margin-top: 0.05em;
			margin-right: 9px;
		}

		.b-checkbox__label {
			&:before {
				border-color: #9b9b9b;
			}
		}

		.parsley-errors-list li {
			top: 100%;
		}

		a {
			color: #ec1847;
		}
	}
}

@if ($responsive) {
	@media (max-width: 1366px) {
		.b-form {
			&__title {
				&.header-2 {
					margin-bottom: 0;
				}
			}

			&__field-input {
				// padding-bottom: 8px;
				input[type="tel"],
				input[type="email"],
				input[type="text"] {
					height: 30px;
				}
			}

			&__field-input--policy {
				margin-top: 15px;
				margin-bottom: 1.5em;
			}
		}
	}

	@include breakpoint(sm) {
		//.b-form {
		//	&__title {
		//		margin-bottom: 0.4em;
		//	}
		//}
	}
}

.browser-ie-11 {
	.b-captcha {
		.b-captcha__reload-btn {
			margin-right: 27px;
		}
	}

	.b-form__field-input--policy .b-checkbox {
		flex: 1 0 13px;
	}
}
