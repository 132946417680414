$captcha_base--icon-color: $color-ironside-gray;
// $captcha_base--reload-button-padding: $btn--padding-vertical;

.b-captcha {
	//Кнопка перезагрузки капчи
	&__reload-btn {
		// display: inline-block;
		// vertical-align: top;
		// padding: $captcha_base--reload-button-padding;
		font-size: 23px;
		background-color: transparent !important;
		color: $captcha_base--icon-color;

		//Иконка кнопки перезагрузки капчи
		.webfont-icon {
			margin: 0;
			transform: rotate(0deg);
			transition: transform 0.5s ease;
		}

		&:hover .webfont-icon {
			transform: rotate(360deg);
		}
	}
}
